import _ from "lodash";
import { Keyboard } from "react-native";
import { useEffect, useState } from "react";
import {
  getHashId,
  checkProperty,
  getCurrencySymbol,
  parseJwt,
} from "../../helpers/src";
import { Linking } from "react-native";

import { ContactInfoApi } from "../../api/src/contact-info";
import { BankAccountAPI } from "../../api/src/create-bank-account";

const useNewAccount = ({ addAccount, navigation }) => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const [bankAccounts, setBankAccount] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [urlValidation, setUrlValidation] = useState("");

  const validate = () => {
    Keyboard.dismiss();

    let valid = true;
    if (accountInfo.idNumber < 0) {
      handleError("Escribe un número válido", "idNumber");
      valid = false;
    }
    if (!accountInfo.bankAccount) {
      handleError("Por favor elige un tipo de cuenta", "bankAccount");
      valid = false;
    }
    if (accountInfo.bankAccount !== "nequi") {
      if (!accountInfo.accountType) {
        handleError("Por favor elige un tipo de cuenta", "accountType");
        valid = false;
      }
      if (accountInfo.bankAccount === "otro") {
        if (!accountInfo.bankName) {
          handleError("Este campo es requerido", "bankName");
          valid = false;
        }
      }
    }
    if (!accountInfo.accountNumber) {
      handleError("Este campo es requerido", "accountNumber");
      valid = false;
    } else if (
      accountInfo.accountNumber < 0 ||
      accountInfo.accountNumber.length > 11
    ) {
      handleError("Escribe un número válido", "accountNumber");
      valid = false;
    } else if (
      bankAccounts?.some((b) => b.number === accountInfo.accountNumber)
    ) {
      handleError(
        "Ya existe una cuenta registrada con este número",
        "accountNumber"
      );
      valid = false;
    }
    valid && generateValidation();
  };

  const handleChange = (field, value) => {
    setAccountInfo((prev) => ({ ...prev, [field]: value }));
  };
  const handleError = (message, field) => {
    setErrors((prev) => ({ ...prev, [field]: message }));
  };
  const generateValidation = () => {
    setLoading(true);
    Linking.openURL(urlValidation);

    BankAccountAPI.createBankAccount({
      name_bank:
        accountInfo.bankAccount === "otro"
          ? String(accountInfo.bankName).toUpperCase()
          : String(accountInfo.bankAccount).toUpperCase(),
      number: accountInfo.accountNumber,
      contact: merchantData?.phone,
      business: 48,
    });

    setTimeout(() => {
      addAccount(accountInfo);
      setLoading(false);
      navigation.navigate({
        name: "Realizar retiro",
        params: { addAccount },
        merge: true,
      });
      //   setIsValidated(true);
    }, 3000);
  };
  const encryptId = (text) => {
    let txt = "";
    typeof text === "string" &&
      (txt = text.slice(0, 2) + "*******" + text.slice(8));
    return txt;
  };
  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      const phone = checkProperty(x?.contact?.attributes?.phone);
      const user_id_number = checkProperty(
        x?.contact?.attributes?.user_id_number
      );

      const url_metamap = `https://signup.getmati.com/?merchantToken=649b1d2341899f001a9f066f&flowId=649b1d2341899f001a9f066e&redirect=https://reporte.qentaz.com&target=_blank&metadata=%7B"fixedLanguage":"es","phone":"${phone}”,"client_id":"${user_id_number}"%7D&color=30CE88&textColor=0A291B`;

      // setUrlValidation(url_metamap);

      setMerchantData({
        user_country_symbol: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        user_country_code: checkProperty(
          x?.contact?.attributes?.user_country_code
        ),
        user_id_number,
        // countryState: checkProperty(x?.contact?.attributes?.countryState),
        phone,
      });
    });
  }, []);
  useEffect(() => {
    if (merchantData?.phone) {
      BankAccountAPI.getBankAccount({ id: merchantData?.phone }).then((x) => {
        const accounts = x?.data?.data;

        setBankAccount(accounts);
      });
    }
  }, [merchantData?.phone, bankAccounts]);

  return {
    accountInfo,
    errors,
    loading,
    handleChange,
    validate,
    merchantData,
    encryptId,
    urlValidation,
  };
};

export default useNewAccount;
