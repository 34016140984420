import { StyleSheet, Text, View, Pressable, ScrollView } from "react-native";
import React, { useState } from "react";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";

import Icon from "react-native-vector-icons/Ionicons";
import Divider from "../../Components/Divider";
import { TextInput } from "react-native";

const PaymentShecule = () => {
  const [periodOption, setPeriodOption] = useState("");
  const [periodCount, setPeriodCount] = useState(0);
  const counts = [1, 2, 3, 4, 5, 6];
  const options = ["Único", "Semanal", "Quincenal", "Mensual"];
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={txt.lg_strong}>Valor</Text>
          <TextInput style={styles.input} value="$12.345" editable={false} />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={txt.lg_strong}>Descripción</Text>
          <TextInput
            style={styles.input}
            value="Camiseta negra"
            editable={false}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={txt.lg_strong}>Celular del cliente</Text>
          <TextInput
            style={styles.input}
            value="3111 222 3333"
            editable={false}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={txt.lg_strong}>Tipo de cobro</Text>
          <Text style={[txt.sm_normal, { color: c.textIcons.description }]}>
            Puedes elegir entre Único para un único cobro o Semanal para un
            cobro que se realiza una vez por semana
          </Text>
        </View>
        <View style={styles.buttonsContainer}>
          {options.map((op, i) => (
            <Pressable key={i} onPress={() => setPeriodOption(op)}>
              <View
                style={[
                  styles.periodButton,
                  op === periodOption && {
                    backgroundColor: c.backgrounds.bgContainer,
                  },
                ]}
              >
                <Text style={txt.base_strong}>{op}</Text>
              </View>
            </Pressable>
          ))}
        </View>
        {periodOption !== "" && (
          <>
            {periodOption !== "Único" ? (
              <>
                <View style={styles.limitDateContainer}>
                  <View style={{ width: "48%" }}>
                    <Text style={txt.lg_strong}>Fecha recordatorio</Text>
                    <View style={styles.input}>
                      <Text>5 de cada mes</Text>
                    </View>
                  </View>
                  <View style={{ width: "48%" }}>
                    <Text style={txt.lg_strong}>Fecha limite</Text>
                    <View style={styles.input}>
                      <Text>10 de cada mes</Text>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: c.spacing.spacing_md }}>
                  <Text style={txt.lg_strong}>Número de cobros</Text>
                  <View style={styles.buttonsContainer}>
                    {counts.map((count, i) => (
                      <Pressable key={i} onPress={() => setPeriodCount(count)}>
                        <View
                          style={[
                            styles.periodButton,
                            count === periodCount && {
                              backgroundColor: c.backgrounds.bgContainer,
                            },
                          ]}
                        >
                          <Text style={[txt.base_strong, { width: 35 }]}>
                            {count}
                          </Text>
                        </View>
                      </Pressable>
                    ))}
                  </View>
                </View>
              </>
            ) : (
              <>
                <View style={{ marginTop: c.spacing.spacing_md }}>
                  <Text style={txt.lg_strong}>Fecha Limite</Text>
                  <Pressable>
                    <View style={[styles.input, styles.dateInput]}>
                      <Text style={{ color: c.textIcons.placeholder }}>
                        Seleccione una fecha
                      </Text>
                      <Icon
                        name="calendar-outline"
                        size={24}
                        color={c.textIcons.icon}
                      />
                    </View>
                  </Pressable>
                </View>
              </>
            )}
            <Pressable>
              <View style={styles.mainButton}>
                <Text style={styles.buttonTitle}>Guardar cambios</Text>
              </View>
            </Pressable>
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default PaymentShecule;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    marginTop: c.spacing.spacing_xx_sm,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  buttonsContainer: {
    padding: 5,
    flexDirection: "row",
    marginTop: c.spacing.spacing_xx_sm,
    borderRadius: c.borderRadius.br_sm,
    backgroundColor: c.backgrounds.bgLayout,
    width: "100%",
    justifyContent: "center",
  },
  periodButton: {
    textAlign: "center",
    paddingVertical: 6,
    borderRadius: c.borderRadius.br_xs,
    paddingHorizontal: c.spacing.spacing_sm,
  },
  limitDateContainer: {
    flexDirection: "row",
    marginTop: c.spacing.spacing_md,
    justifyContent: "space-between",
  },
  mainButton: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_sm,
    backgroundColor: c.themeColors.primaryBase,
  },
  buttonTitle: {
    color: c.themeColors.primaryText,
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
  },
  dateInput: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
