import {
  StyleSheet,
  Text,
  View,
  Pressable,
  useWindowDimensions,
} from "react-native";
import React from "react";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import InputComponent from "../../Components/InputComponent";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import useRegister from "./useRegister";
const Register = ({ navigation, route }) => {
  const { isDesktop } = route.params;
  const { error, setPhone, validate, createOtp } = useRegister({
    navigation,
  });
  const { height, width } = useWindowDimensions();

  return (
    <View>
      <View
        style={{
          maxWidth: 500,
          alignSelf: "center",
          textAlign: "center",
          height: height - "20%",
          paddingVertical: c.spacing.spacing_x_lg,
          paddingHorizontal: c.spacing.spacing_big,
        }}
      >
        <Text style={txt.heading3}>Inicia sesión con tu teléfono</Text>
        <Text style={{ marginVertical: c.spacing.spacing_x_big }}>
          Ingresa el número celular al que deseas recibir el código de
          autenticación. Recuerda que debes estar registrado.
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <View style={styles.countryCodeButton}>
            <Text>+57</Text>
          </View>
          <View style={{ width: "80%", minWidth: 220 }}>
            <InputComponent
              placeholder="311 222 3333"
              isNumeric
              onChangeText={(val) => setPhone(val)}
            />
          </View>
        </View>
        {error !== "" && (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>{error}</Text>
          </View>
        )}
        <Pressable
          onPress={() => {
            validate();
          }}
        >
          <View
            style={[
              styles.mainButton,
              isDesktop && { backgroundColor: c.desktopColors.desktopBase },
            ]}
          >
            <Text
              style={[
                txt.lg_strong,
                isDesktop && { color: c.backgrounds.bgContainer },
              ]}
            >
              Continuar
            </Text>
          </View>
        </Pressable>
      </View>
      <View style={styles.problemButtonContainer}>
        <MCI
          name="message-question-outline"
          size={14}
          color={c.textIcons.icon}
        />
        <Text
          style={[
            txt.sm_normal,
            {
              color: c.textIcons.icon,
              marginLeft: c.spacing.spacing_xx_sm,
            },
          ]}
        >
          Reportar un problema
        </Text>
      </View>
    </View>
  );
};

export default Register;

const styles = StyleSheet.create({
  countryCodeButton: {
    borderWidth: 1,
    backgroundColor: "#EFEDF1",
    marginRight: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderColor: c.bordersDividers.borderSecondary,
  },
  errorContainer: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_x_big,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xx_sm,
  },
  errorText: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
    color: c.error.errorText,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
    marginTop: c.spacing.spacing_xx_big,
  },
  problemButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: c.spacing.spacing_sm,
  },
});
