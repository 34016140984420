import { postData } from "../api-call";
import {
  CREATE_INDIVIDUAL_DISPERSION_HOOK,
  CREATE_MULTIPLE_DISPERSION_HOOK,
} from "../config";

const createIndividualDispersion = async ({
  bank,
  email,
  amount,
  idType,
  idNumber,
  description,
  accountType,
  receiverName,
  receiverPhone,
  accountNumber,
  merchantId,
  merchantName,
  merchantMember,
  merchantPhone,
}) => {
  const data = {
    bank,
    email,
    amount,
    idType,
    idNumber,
    description,
    accountType,
    receiverName,
    merchantId,
    merchantName,
    merchantMember,
    merchantPhone,
    receiverPhone,
    accountNumber,
    countryCode: "COP",
    countryCurrency: "$",
  };
  const requestUrl = CREATE_INDIVIDUAL_DISPERSION_HOOK;
  return postData(requestUrl, data);
};

const createMultipleDispersion = ({
  csvFile,
  contactId,
  merchantId,
  contactName,
  merchantName,
  merchantPaymentId,
}) => {
  const requestUrl = `${CREATE_MULTIPLE_DISPERSION_HOOK}`;

  return postData(requestUrl, {
    csv: csvFile,
    contact_id: contactId,
    merchant_id: merchantId,
    contact_name: contactName,
    merchant_name: merchantName,
    merchant_payment_id: merchantPaymentId,
  });
};
export const DispersionsInfoApi = {
  createIndividualDispersion,
  createMultipleDispersion,
};
