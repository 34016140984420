import React from 'react'
import { StyleSheet,ScrollView, Text, View } from 'react-native'

import { c } from '../../styles/constants'
import EmptyContent from '../../Components/EmptyContent'

const Assistant = () => {
    return (
        <ScrollView>
        <View style={{ padding: c.spacing.spacing_md }}>
          <EmptyContent/>
        </View>
      </ScrollView>
    )
}

export default Assistant

const styles = StyleSheet.create({})