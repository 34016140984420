import React, { useState, useEffect } from "react";
import { checkProperty, parseJwt } from "../../helpers/src";
import { ContactInfoApi } from "../../api/src/contact-info";

export const useDrawerInfo = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");

  const [merchantLogo, setMerchantLogo] = useState("");

  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      setMerchantLogo(
        checkProperty(
          x?.contact?.attributes?.businesses?.data[0]?.attributes?.logo
        ) || require("../../assets/qz-pay-logo.jpg")
      );
    });
  }, []);
  return {
    merchantLogo,
  };
};
