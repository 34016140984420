import {
  Text,
  View,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";

import useCharge from "./useCharge";
import { c } from "../../styles/constants";
import Loader from "../../Components/Loader";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";
import ChargeDetails from "../../Components/Modal/ChargeDetails";
import InputComponent from "../../Components/InputComponent";
import ScheduleCharge from "../../Components/ScheduleCharge";
import FrequencyButtons from "../../Components/FrequencyButtons";
import ChargeNow from "../../Components/ChargeNow";
import MultipleChargeSent from "../../Components/Modal/MultipleChargeSent";

const Charge = ({ navigation }) => {
  const screenWidth = useWindowDimensions().width;
  const isDesktop = screenWidth > 500;
  const {
    tab,
    file,
    setTab,
    errors,
    loading,
    validate,
    loadFile,
    fileError,
    chargeSent,
    setLoading,
    clearCharge,
    generateCsv,
    pickDocument,
    handleChange,
    validateInfo,
    setChargeSent,
    newChargeInfo,
    clearDocument,
    currencyFormat,
    scheduleCharge,
    generateCharge,
    multipleModal,
    setMultipleModal,
    scheduleErrors,
    handleChangeSchedule,
    merchantData: { countryCurrency },
  } = useCharge();
  const excelUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vQy1wgw5TPmcF2MPMBQoQidJHGO1wMMXr-FwHUL81hSP__dwNomtLvGHIM4DaikH4VcfqC4BBiUM472/pub?output=xlsx";
  const sheetsUrl =
    "https://docs.google.com/spreadsheets/d/10gzO9uEBB2hiywKvI7dnOU1Fy_XNM2gzDmxT94WKl4E/edit?usp=sharing";
  const tabs = [
    {
      id: "ahora",
      title: "Cobrar ahora",
      description: "Genera un cobro inmediato a tus clientes",
      icon: "add-circle",
    },
    {
      id: "programado",
      title: "Cobro programado",
      description: "Programa un cobro para ser cobrado en la fecha que desees.",
      icon: "calendar",
    },
    {
      id: "multiple",
      title: "Cobro múltiple",
      description:
        "Cobra al instante o programa más de dos cobros al mismo tiempo.",
      icon: "duplicate",
    },
  ];

  return (
    <>
      {isDesktop ? (
        <>
          <View
            style={{
              paddingVertical: c.spacing.spacing_x_big,
              paddingHorizontal: c.spacing.spacing_lg,
            }}
          >
            <Text style={{ fontSize: c.fontSize.fs_huge }}>Cobrar</Text>
            <View
              style={{
                marginTop: c.spacing.spacing_xxx_big,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "35%" }}>
                {tabs.map((t) => (
                  <Pressable
                    key={t.id}
                    onPress={() => {
                      setTab(t.id);
                    }}
                  >
                    <View
                      style={[
                        {
                          width: "100%",
                          borderWidth: 1,
                          borderRadius: c.borderRadius.br_lg,
                          marginBottom: c.spacing.spacing_big,
                          borderColor: c.bordersDividers.border,
                          paddingHorizontal: c.spacing.spacing_big,
                          paddingVertical: c.spacing.spacing_xx_big,
                          backgroundColor: c.backgrounds.bgContainer,
                        },
                        tab === t.id && {
                          backgroundColor: c.desktopColors.desktopBase,
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: c.fontWeight.fw_bold,
                          marginBottom: 5,
                          color:
                            tab === t.id
                              ? c.backgrounds.bgContainer
                              : c.desktopColors.desktopTitle,
                        }}
                      >
                        {t.title}
                      </Text>
                      <Text
                        style={{
                          color:
                            tab === t.id
                              ? c.backgrounds.bgContainer
                              : c.textIcons.description,
                        }}
                      >
                        {t.description}
                      </Text>
                    </View>
                  </Pressable>
                ))}
              </View>
              {tab !== "" && (
                <View
                  style={{
                    width: "60%",
                    borderWidth: 1,
                    borderRadius: c.borderRadius.br_lg,
                    paddingVertical: c.spacing.spacing_md,
                    borderColor: c.bordersDividers.border,
                    backgroundColor: c.backgrounds.bgContainer,
                    paddingHorizontal: c.spacing.spacing_xx_big,
                  }}
                >
                  {tabs.map(
                    (t) =>
                      tab === t.id && (
                        <Text
                          key={t.id}
                          style={{
                            fontSize: 18,
                            marginBottom: 5,
                            fontWeight: c.fontWeight.fw_bold,
                            color: c.desktopColors.desktopTitle,
                          }}
                        >
                          {t.title}
                        </Text>
                      )
                  )}
                  {(tab === "ahora" || tab === "programado") && (
                    <>
                      {tab === "ahora" && (
                        <>
                          <ChargeNow
                            errors={errors}
                            handleChange={handleChange}
                          />
                        </>
                      )}
                      {tab === "programado" && (
                        <>
                          <ScheduleCharge
                            errors={errors}
                            isDesktop={isDesktop}
                            txInfo={scheduleCharge}
                            handleChange={handleChange}
                            scheduleErrors={scheduleErrors}
                            scheduleFrequency={
                              scheduleCharge?.scheduleFrequency
                            }
                            handleChangeSchedule={handleChangeSchedule}
                          />
                        </>
                      )}
                      <Pressable onPress={validate}>
                        <View
                          style={[
                            styles.mainButton,
                            { backgroundColor: c.desktopColors.desktopBase },
                          ]}
                        >
                          <Text
                            style={[
                              txt.lg_strong,
                              { color: c.backgrounds.bgContainer },
                            ]}
                          >
                            Generar cobro
                          </Text>
                        </View>
                      </Pressable>
                    </>
                  )}
                  {tab === "multiple" && (
                    <>
                      <Text
                        style={{
                          marginVertical: c.spacing.spacing_md,
                          textAlign: "justify",
                        }}
                      >
                        Al realizar un cobro múltiple, envías dos o más cobros
                        independientes a la vez, a través de una hoja de
                        cálculo. Descarga un ejemplo en{" "}
                        <Pressable onPress={() => Linking.openURL(excelUrl)}>
                          <Text
                            style={{
                              color: c.desktopColors.desktopBase,
                              textDecorationLine: c.textDecotation.td_underline,
                              fontWeight: c.fontWeight.fw_bold,
                            }}
                          >
                            Excel
                          </Text>
                        </Pressable>{" "}
                        o{" "}
                        <Pressable onPress={() => Linking.openURL(sheetsUrl)}>
                          <Text
                            style={{
                              color: c.desktopColors.desktopBase,
                              textDecorationLine: c.textDecotation.td_underline,
                              fontWeight: c.fontWeight.fw_bold,
                            }}
                          >
                            Google Sheets
                          </Text>
                        </Pressable>
                        , rellénalo con los datos de cada cobro y cárgalo abajo
                        en formato .csv. Luego, toca el botón para enviar tus
                        cobros automáticamente
                      </Text>
                      <View style={styles.fileInput}>
                        <Text numberOfLines={1} style={{ maxWidth: 200 }}>
                          {file && file[0].name}
                        </Text>
                        <Pressable
                          onPress={file ? clearDocument : pickDocument}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              name={
                                file ? "trash-outline" : "cloud-upload-outline"
                              }
                              size={20}
                              color={
                                file
                                  ? c.error.errorBase
                                  : c.desktopColors.desktopBase
                              }
                            />
                            <Text
                              style={{
                                marginLeft: c.spacing.spacing_xx_sm,
                                color: file
                                  ? c.error.errorBase
                                  : c.desktopColors.desktopBase,
                              }}
                            >
                              {file ? "Eliminar" : "Subir"}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                      {fileError && (
                        <Text
                          style={{
                            marginTop: c.spacing.spacing_sm,
                            fontWeight: c.fontWeight.fw_medium,
                            color: c.error.errorText,
                          }}
                        >
                          {fileError}
                        </Text>
                      )}
                      <Text
                        style={[
                          txt.sm_normal,
                          {
                            marginTop: c.spacing.spacing_md,
                            color: c.textIcons.description,
                          },
                        ]}
                      >
                        Solo se aceptan archivos de tipo .csv. Tamaño máximo
                        permitido 10 MB.
                      </Text>
                      {loadFile && (
                        <View style={styles.fileLoader}>
                          <ActivityIndicator
                            size="large"
                            color={c.desktopColors.desktopBase}
                          />
                          <Text
                            style={{
                              color: c.desktopColors.desktopBase,
                              marginTop: c.spacing.spacing_sm,
                            }}
                          >
                            Estamos validando el archivo, un momento por favor
                          </Text>
                        </View>
                      )}
                      {file && (
                        <>
                          <Pressable onPress={generateCsv}>
                            <View
                              style={[
                                styles.mainButton,
                                {
                                  backgroundColor: c.desktopColors.desktopBase,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  txt.lg_strong,
                                  { color: c.backgrounds.bgContainer },
                                ]}
                              >
                                Generar cobro múltiple
                              </Text>
                            </View>
                          </Pressable>
                          <Loader
                            visible={loading}
                            closeAction={() => setLoading(false)}
                          />
                          <MultipleChargeSent
                            visible={multipleModal}
                            navigation={navigation}
                            clearDocument={clearDocument}
                            closeModal={() => setMultipleModal(false)}
                          />
                        </>
                      )}
                    </>
                  )}
                </View>
              )}
            </View>
          </View>
        </>
      ) : (
        <>
          <ScrollView>
            <View style={{ padding: c.spacing.spacing_md }}>
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                <View style={styles.screenButtonsContainer}>
                  {tabs.map((t) => (
                    <Pressable
                      key={t.id}
                      onPress={() => {
                        setTab(t.id);
                      }}
                    >
                      <View
                        style={[
                          styles.screenButton,
                          tab === t.id && styles.screenButton_selected,
                        ]}
                      >
                        <Icon
                          name={tab === t.id ? t.icon : `${t.icon}-outline`}
                          size={35}
                          color={
                            tab === t.id
                              ? c.themeColors.primaryBase
                              : c.textIcons.icon
                          }
                        />
                        <Text
                          style={[
                            txt.base_normal,
                            {
                              color:
                                tab === t.id
                                  ? c.themeColors.primaryBase
                                  : c.textIcons.icon,
                            },
                          ]}
                        >
                          {t.title}
                        </Text>
                      </View>
                    </Pressable>
                  ))}
                </View>
              </ScrollView>
              <Divider />
              {tab !== "" && (
                <>
                  {(tab === "ahora" || tab === "programado") && (
                    <>
                      {tab === "ahora" && (
                        <ChargeNow
                          errors={errors}
                          handleChange={handleChange}
                        />
                      )}
                      {tab === "programado" && (
                        <ScheduleCharge
                          errors={errors}
                          handleChange={handleChange}
                          scheduleErrors={scheduleErrors}
                          handleChangeSchedule={handleChangeSchedule}
                          scheduleFrequency={scheduleCharge?.scheduleFrequency}
                        />
                      )}
                      <Pressable onPress={validate}>
                        <View style={styles.mainButton}>
                          <Text style={txt.lg_strong}>Generar cobro</Text>
                        </View>
                      </Pressable>
                    </>
                  )}
                  {tab === "multiple" && (
                    <>
                      <Text style={{ marginVertical: c.spacing.spacing_md }}>
                        Al realizar un cobro múltiple, envías dos o más cobros
                        independientes a la vez, a través de una hoja de
                        cálculo. Descarga un ejemplo en{" "}
                        <Pressable onPress={() => Linking.openURL(excelUrl)}>
                          <Text style={{ color: c.themeColors.primaryBase }}>
                            Excel
                          </Text>
                        </Pressable>{" "}
                        o{" "}
                        <Pressable onPress={() => Linking.openURL(sheetsUrl)}>
                          <Text style={{ color: c.themeColors.primaryBase }}>
                            Google Sheets
                          </Text>
                        </Pressable>
                        , rellénalo con los datos de cada cobro y cárgalo abajo
                        en formato .csv. Luego, toca el botón para enviar tus
                        cobros automáticamente
                      </Text>
                      <View style={styles.fileInput}>
                        <Text numberOfLines={1} style={{ maxWidth: 200 }}>
                          {file && file[0].name}
                        </Text>
                        <Pressable
                          onPress={file ? clearDocument : pickDocument}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              name={
                                file ? "trash-outline" : "cloud-upload-outline"
                              }
                              size={20}
                              color={
                                file
                                  ? c.error.errorBase
                                  : c.themeColors.primaryBase
                              }
                            />
                            <Text
                              style={{
                                marginLeft: c.spacing.spacing_xx_sm,
                                color: file
                                  ? c.error.errorBase
                                  : c.themeColors.primaryBase,
                              }}
                            >
                              {file ? "Eliminar" : "Subir"}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                      {fileError && (
                        <Text
                          style={{
                            marginTop: c.spacing.spacing_sm,
                            fontWeight: c.fontWeight.fw_medium,
                            color: c.error.errorText,
                          }}
                        >
                          {fileError}
                        </Text>
                      )}
                      <Text
                        style={[
                          txt.sm_normal,
                          {
                            marginTop: c.spacing.spacing_md,
                            color: c.textIcons.description,
                          },
                        ]}
                      >
                        Solo se aceptan archivos de tipo .csv. Tamaño máximo
                        permitido 10 MB.
                      </Text>
                      {loadFile && (
                        <View style={styles.fileLoader}>
                          <ActivityIndicator
                            size="large"
                            color={c.themeColors.primaryBase}
                          />
                          <Text
                            style={{
                              color: c.themeColors.primaryBase,
                              marginTop: c.spacing.spacing_sm,
                            }}
                          >
                            Estamos validando el archivo, un momento por favor
                          </Text>
                        </View>
                      )}
                      {file && (
                        <Pressable onPress={generateCsv}>
                          <View style={styles.mainButton}>
                            <Text style={txt.lg_strong}>
                              Generar cobro múltiple
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  )}
                </>
              )}
            </View>
          </ScrollView>
        </>
      )}
      <>
        <ChargeDetails
          visible={chargeSent}
          isDesktop={isDesktop}
          navigation={navigation}
          clearCharge={clearCharge}
          isValidated={validateInfo}
          value={newChargeInfo.value}
          scheduleCharge={scheduleCharge}
          currencyFormat={currencyFormat}
          countryCurrency={countryCurrency}
          validateInfoAction={generateCharge}
          clientName={newChargeInfo.clientName}
          closeModal={() => setChargeSent(false)}
          clientPhone={newChargeInfo.clientPhone}
          description={newChargeInfo.description}
        />
        <Loader visible={loading} closeAction={() => setLoading(false)} />
      </>
    </>
  );
};

export default Charge;

const styles = StyleSheet.create({
  screenButtonsContainer: {
    marginTop: c.spacing.spacing_md,
    flexDirection: "row",
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  screenButton: {
    borderWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    borderRadius: c.borderRadius.br_sm,
    padding: c.spacing.spacing_xx_sm,
    marginRight: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  screenButton_selected: {
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.themeColors.primaryBg,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  fileLoader: {
    marginTop: c.spacing.spacing_md,
    justifyContent: "center",
    textAlign: "center",
  },
  buttonsContainer: {
    padding: 5,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderRadius: c.borderRadius.br_sm,
    backgroundColor: c.backgrounds.bgLayout,
  },

  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  fileInput: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.backgrounds.bgContainer,
  },
});
