import moment from "moment";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { StyleSheet, Text, View, Pressable } from "react-native";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
import ShowComprobant from "./Modal/ShowComprobant";
import TransactionDetails from "./Modal/TransactionDetails";

const TransactionsCard = ({
  txType,
  txDate,
  txCode,
  txTitle,
  txValue,
  txStatus,
  bankName,
  limitDate,
  serviceFee,
  bankNumber,
  txClientName,
  receiptImage,
  txClientPhone,
  paymentMethod,
  currencyFormat,
  countryCurrency,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showComprobant, setShowComprobant] = useState(false);

  const txLimitDate = moment(limitDate).format("DD/MM/YYYY");

  const dateFormat = new Date(txDate);
  dateFormat.setDate(dateFormat.getDate() + 1);

  const formatedBankNumber = "*******" + bankNumber?.slice(-3);
  const status =
    txStatus.toLowerCase() === "success"
      ? "Exitosa"
      : txStatus.toLowerCase() === "pending"
      ? "Pendiente"
      : txStatus.toLowerCase() === "declined"
      ? "Rechazada"
      : txStatus.toLowerCase() === "scheduled"
      ? "Programada"
      : "Vencida";

  return (
    <>
      <Pressable onPress={() => setShowModal(true)}>
        <View style={[styles.movementsCard]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {bankName ? (
              <Text style={[txt.base_strong, { maxWidth: "70%" }]}>
                {`Retiro de ${bankName} (${formatedBankNumber})`}
              </Text>
            ) : (
              <Text style={[txt.base_strong, { maxWidth: "70%" }]}>
                {txTitle}
              </Text>
            )}
            <Text style={[txt.lg_strong, { textAlign: "center" }]}>
              {bankName && "-"}
              {`${countryCurrency} ${currencyFormat.format(txValue)}`}
            </Text>
          </View>
          <View style={styles.statusContainer}>
            {status === "Programada" ? (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text> Fecha límite: </Text>
                <Text
                  style={[txt.sm_normal, { color: c.textIcons.description }]}
                >
                  {txLimitDate}
                </Text>
              </View>
            ) : (
              <Text style={[txt.sm_normal, { color: c.textIcons.description }]}>
                {bankName ? moment(txDate).format("DD/MM/YYYY") : txDate}
              </Text>
            )}
            <View
              style={[
                styles.statusBadge,
                status === "Pendiente"
                  ? styles.statusBadge_warning
                  : status === "Exitosa"
                  ? styles.statusBadge_succcess
                  : status === "Rechazada"
                  ? styles.statusBadge_rejected
                  : status === "Programada"
                  ? styles.statusBadge_scheduled
                  : styles.statusBadge_created,
              ]}
            >
              <Text
                style={[
                  styles.statusCaption,
                  {
                    color:
                      status === "Pendiente"
                        ? c.warning.warningBase
                        : status === "Exitosa"
                        ? c.themeColors.primaryBase
                        : status === "Rechazada"
                        ? c.error.errorBase
                        : status === "Programada"
                        ? c.info.infoBase
                        : c.textIcons.icon,
                  },
                ]}
              >
                {status}
              </Text>
              <Icon
                name={
                  status === "Pendiente"
                    ? "alert-circle"
                    : status === "Exitosa"
                    ? "checkmark-circle"
                    : status === "Rechazada"
                    ? "close-circle"
                    : status === "Programada"
                    ? "time"
                    : "remove-circle"
                }
                size={14}
                color={
                  status === "Pendiente"
                    ? c.warning.warningBase
                    : status === "Exitosa"
                    ? c.themeColors.primaryBase
                    : status === "Rechazada"
                    ? c.error.errorBase
                    : status === "Programada"
                    ? c.info.infoBase
                    : c.textIcons.icon
                }
              />
            </View>
          </View>
        </View>
      </Pressable>
      <TransactionDetails
        txCode={txCode}
        txTitle={txTitle}
        txValue={txValue}
        txStatus={status}
        visible={showModal}
        bankName={bankName}
        serviceFee={serviceFee}
        txLimitDate={txLimitDate}
        receiptImage={receiptImage}
        txClientName={txClientName}
        paymentMethod={paymentMethod}
        txClientPhone={txClientPhone}
        bankNumber={formatedBankNumber}
        currencyFormat={currencyFormat}
        countryCurrency={countryCurrency}
        close={() => setShowModal(false)}
        comprobantAction={() => setShowComprobant(true)}
        txDate={bankName ? moment(txDate).format("DD/MM/YYYY") : txDate}
      />
      <ShowComprobant
        visible={showComprobant}
        receiptImage={receiptImage}
        close={() => setShowComprobant(false)}
      />
    </>
  );
};

export default TransactionsCard;

const styles = StyleSheet.create({
  movementsCard: {
    borderWidth: 1,
    shadowRadius: 5,
    marginTop: c.spacing.spacing_md,
    shadowColor: "rgba(0,0,0,0.25)",
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_lg,
    backgroundColor: c.backgrounds.bgContainer,
    shadowOffset: { width: 0, height: 0 },
  },
  statusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_xxx_sm,
  },
  statusBadge: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
  },
  statusBadge_warning: {
    borderColor: c.warning.warningBase,
    backgroundColor: c.warning.warningBg,
  },
  statusBadge_succcess: {
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.success.successBg,
  },
  statusBadge_rejected: {
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
  },
  statusBadge_scheduled: {
    borderColor: c.info.infoBorder,
    backgroundColor: c.info.infoBg,
  },
  statusBadge_created: {
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  statusCaption: {
    fontWeight: c.fontWeight.fw_medium,
    fontSize: c.fontSize.fs_sm,
    marginRight: c.spacing.spacing_xxx_sm,
    marginTop: -3,
  },
});
