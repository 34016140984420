import { StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import React from "react";
import { c } from "../styles/constants";

const StatusBadge = ({ txStatus }) => {
  const status =
    txStatus?.toLowerCase() === "success"
      ? "Aprobado"
      : txStatus?.toLowerCase() === "pending"
      ? "En proceso"
      : txStatus?.toLowerCase() === "declined"
      ? "Cancelado"
      : txStatus?.toLowerCase() === "scheduled"
      ? "Programada"
      : "Vencida";
  return (
    <View
      style={[
        styles.statusContainer,
        {
          backgroundColor:
            status === "En proceso"
              ? c.warning.warningBg
              : status === "Aprobado"
              ? c.desktopColors.desktopBg
              : status === "Cancelado"
              ? c.error.errorBg
              : status === "Programada"
              ? c.info.infoBg
              : c.backgrounds.bgContainerDisabled,
        },
      ]}
    >
      <Icon
        name={
          status === "En proceso"
            ? "alert-circle-outline"
            : status === "Aprobado"
            ? "checkmark-circle-outline"
            : status === "Cancelado"
            ? "close-circle-outline"
            : status === "Programada"
            ? "time-outline"
            : "remove-circle-outline"
        }
        size={16}
        color={
          status === "En proceso"
            ? c.warning.warningBase
            : status === "Aprobado"
            ? c.desktopColors.desktopTxt
            : status === "Cancelado"
            ? c.error.errorBase
            : status === "Programada"
            ? c.info.infoBase
            : c.textIcons.icon
        }
      />
      <Text
        style={[
          {
            color:
              status === "En proceso"
                ? c.warning.warningBase
                : status === "Aprobado"
                ? c.desktopColors.desktopTxt
                : status === "Cancelado"
                ? c.error.errorBase
                : status === "Programada"
                ? c.info.infoBase
                : c.textIcons.icon,
            marginLeft: c.spacing.spacing_xxx_sm,
          },
        ]}
      >
        {status}
      </Text>
    </View>
  );
};

export default StatusBadge;

const styles = StyleSheet.create({
  statusContainer: {
    width: 115,
    borderRadius: 50,
    paddingVertical: 2,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingHorizontal: 6,
  },
});
