import _ from "lodash";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  ScrollView,
  Modal,
  useWindowDimensions,
} from "react-native";

import Divider from "../Divider";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import Icon from "react-native-vector-icons/Ionicons";

const ChargeDetails = ({
  value,
  closeModal,
  visible,
  isDesktop,
  clientName,
  navigation,
  description,
  clientPhone,
  isValidated,
  clearCharge,
  currencyFormat,
  scheduleCharge,
  countryCurrency,
  validateInfoAction,
}) => {
  const { height } = useWindowDimensions();

  return (
    <Modal
      animationType="slide"
      transparent={isDesktop}
      visible={visible}
      onRequestClose={closeModal}
    >
      <View style={{ backgroundColor: "rgba(0,0,0,0.25)", height }}>
        <View
          style={[
            styles.modalCard,
            isDesktop && { alignSelf: "flex-end", height },
          ]}
        >
          <ScrollView>
            <View
              style={{
                paddingHorizontal: c.spacing.spacing_md,
                paddingTop: c.spacing.spacing_big,
              }}
            >
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Icon
                  name={
                    !isValidated
                      ? "time"
                      : !_.isEmpty(scheduleCharge)
                      ? "calendar"
                      : "checkmark-circle"
                  }
                  size={48}
                  color={
                    !isValidated
                      ? c.warning.warningBase
                      : isDesktop
                      ? c.desktopColors.desktopBase
                      : c.themeColors.primaryBase
                  }
                />
                <Text
                  style={[txt.xl_strong, { marginTop: c.spacing.spacing_md }]}
                >
                  {!isValidated ? "Verifica tu Información" : "¡Cobro enviado!"}
                </Text>
              </View>
              <Divider />
              <View style={{ marginTop: c.spacing.spacing_big }}>
                <Text
                  style={{
                    fontSize: c.fontSize.fs_big,
                    fontWeight: c.fontWeight.fw_medium,
                  }}
                >
                  Resumen del cobro
                </Text>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Valor:</Text>
                  <Text style={txt.lg_normal}>
                    {`${countryCurrency} ${currencyFormat.format(value)}`}
                  </Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Descripción:</Text>
                  <Text style={txt.lg_normal}>{description}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Cliente:</Text>
                  <Text style={txt.lg_normal}>{clientName}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Celular:</Text>
                  <Text style={txt.lg_normal}>{clientPhone}</Text>
                </View>
                <Divider />
                {!_.isEmpty(scheduleCharge) && (
                  <>
                    <View style={styles.summaryRow}>
                      <Text style={txt.lg_strong}>Frecuencia:</Text>
                      <Text style={txt.lg_normal}>
                        {scheduleCharge.scheduleFrequency}
                      </Text>
                    </View>
                    {scheduleCharge.scheduleFrequency === "Mensual" ? (
                      <>
                        <View style={styles.summaryRow}>
                          <Text style={txt.lg_strong}>Número de cobros:</Text>
                          <Text style={txt.lg_normal}>
                            {scheduleCharge.scheduleAmount}
                          </Text>
                        </View>
                        <View style={styles.summaryRow}>
                          <Text style={txt.lg_strong}>Recordatorio:</Text>
                          <Text style={txt.lg_normal}>
                            {scheduleCharge.scheduleReminder}
                          </Text>
                        </View>
                        <View style={styles.summaryRow}>
                          <Text style={txt.lg_strong}>Fecha límite:</Text>
                          <Text style={txt.lg_normal}>
                            {scheduleCharge.scheduleLimit}
                          </Text>
                        </View>
                        <View style={styles.summaryRow}>
                          <Text style={txt.lg_strong}>Inicio del cobro:</Text>
                          <Text style={txt.lg_normal}>
                            {scheduleCharge.scheduleStart}
                          </Text>
                        </View>
                      </>
                    ) : (
                      <>
                        <View style={styles.summaryRow}>
                          <Text style={txt.lg_strong}>Fecha del cobro:</Text>
                          <Text style={txt.lg_normal}>
                            {scheduleCharge.scheduleDate}
                          </Text>
                        </View>
                      </>
                    )}
                  </>
                )}
                <Pressable
                  onPress={!isValidated ? validateInfoAction : () => {
                    closeModal();
                    clearCharge();
                  }}
                >
                  <View
                    style={[
                      styles.mainButton,
                      isDesktop && {
                        backgroundColor: c.desktopColors.desktopBase,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        txt.lg_strong,
                        isDesktop && { color: c.backgrounds.bgContainer },
                      ]}
                    >
                      {!isValidated ? "Enviar cobro" : "Realizar otro cobro"}
                    </Text>
                  </View>
                </Pressable>
                <Pressable
                  onPress={
                    !isValidated
                      ? closeModal
                      : () => {
                          navigation.navigate("Programados");
                          closeModal();
                          clearCharge();
                        }
                  }
                >
                  <View
                    style={[
                      styles.mainButton,
                      {
                        backgroundColor: isDesktop
                          ? c.desktopColors.desktopBg
                          : c.themeColors.primaryBg,
                      },
                    ]}
                  >
                    <Text style={txt.lg_strong}>
                      {!isValidated
                        ? "Corregir información"
                        : "Ver programados"}
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default ChargeDetails;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "center",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
