//
import { StyleSheet, Text, View, Image, Pressable } from "react-native";
import React from "react";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import { useHeaderInfo } from "./useAppHeader";

const AppHeader = ({ navigation, title }) => {
  const {
    merchantInfo: { nickname, image },
  } = useHeaderInfo();

  return (
    <View style={styles.appHeaderContainer}>
      {title === "Actividad" || title === "Mas" ? (
        <View>
          <Text style={txt.lg_normal}>Hola 👋 </Text>
          <Text style={txt.heading4}>{nickname}</Text>
        </View>
      ) : (
        <Text style={txt.heading3}>
          {title === "Cobro" ? "Realizar cobro" : title}
        </Text>
      )}
      <Pressable onPress={() => navigation.navigate("Mas")}>
        <View style={styles.imageContainer}>
          <Image
            source={image}
            resizeMode="contain"
            style={{ width: 35, height: 30 }}
          />
        </View>
      </Pressable>
    </View>
  );
};

export default AppHeader;

const styles = StyleSheet.create({
  appHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    justifyContent: "space-between",
    paddingVertical: c.spacing.spacing_x_big,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.themeColors.primaryBg,
  },
  imageContainer: {
    width: 45,
    height: 45,
    shadowRadius: 5,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "rgba(0,0,0,0.25)",
    shadowOffset: { width: 0, height: 0 },
    backgroundColor: c.backgrounds.bgContainer,
  },
});
