import { Keyboard } from "react-native";
import React, { useState } from "react";
import { OtpAPI } from "../../api/src/create-otp";
import _ from "lodash";

const useOTP = ({ navigation, phone }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const validCode = "000000";
  const validate = () => {
    OtpAPI.checkOtp({ id: phone, otp: code }).then((x) => {
      const code = _.get(x, "code");
      const token = _.get(x, "token");
      Keyboard.dismiss();
      let valid = true;
      // if (!code) {
      //   setError("Este campo es requerido");
      //   valid = false;
      // } else if (code.length !== 10) {
      //   setError("Ingresa un número telefónico válido");
      //   valid = false;
      // }

      if (code === "granted") {
        sessionStorage.setItem("_auth_code_", token);

        window.location.reload();
      } else {
        setError(
          "¡Lo sentimos! El código ingresado no corresponde al envíado a tu Whatsapp. Intenta de nuevo."
        );
        valid = false;
      }

      valid && loading();
    });
  };
  const loading = () => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  };
  return { setCode, error, validate, load };
};

export default useOTP;
