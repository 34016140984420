import _ from "lodash";
import { Keyboard } from "react-native";
import { useEffect, useState, useRef } from "react";
import * as DocumentPicker from "expo-document-picker";
import { DashboardApi } from "../../api/src/dashboard";
import { BusinessInfoAPI } from "../../api/src/business";
import { ContactInfoApi } from "../../api/src/contact-info";
import { checkProperty, parseJwt } from "../../helpers/src";
import { TransactionsInfoAPI } from "../../api/src/transactions";
import csvToJson from "csvtojson";
import axios from "axios";

const useCharge = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");
  const inputRef = useRef();

  const [tab, setTab] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [business, setBussiness] = useState({});
  const [fileError, setFileError] = useState("");
  const [loadFile, setLoadFile] = useState(false);
  const [convertFile, setConvertFile] = useState("");
  const [chargeSent, setChargeSent] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const [newChargeInfo, setNewChargeInfo] = useState({});
  const [validateInfo, setValidateInfo] = useState(false);
  const [scheduleCharge, setScheduleCharge] = useState({});
  const [scheduleErrors, setScheduleErrors] = useState({});
  const [multipleModal, setMultipleModal] = useState(false);

  const currencyFormat = Intl.NumberFormat("es-CO");

  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      BusinessInfoAPI.getBusinessByPhone({
        phone: checkProperty(x?.contact?.attributes?.phone),
      }).then((x) => {
        setBussiness({
          payment_id: checkProperty(x?.data?.payment_id),
          name: checkProperty(x?.data?.business_name),
        });
      });
      setMerchantData({
        countryCurrency: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        id: checkProperty(x?.contact?.id),
        name: checkProperty(x?.contact?.attributes?.first_name),

        phone: checkProperty(x?.contact?.attributes?.phone),
        nickname: checkProperty(x?.contact?.attributes?.nickname),
      });
    });
  }, []);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});

    setLoadFile(true);
    setTimeout(async () => {
      if (result?.output[0]?.type === "text/csv") {
        const parseBase = result.assets[0]?.uri?.replace(
          "data:text/csv;base64,",
          ""
        );

        setFile(result?.assets);

        const convertbase = atob(parseBase);

        const json = await csvToJson().fromString(
          `${convertbase}`
            ?.replaceAll("Ã³", "ó")
            .replaceAll("Ãº", "ú")
            .replaceAll("Ã­", "í")
            .replaceAll("Ã­a", "e")
            .replaceAll("Descripción", "descripcion")
            .replaceAll("Referencia 1", "referencia_1")
            .replaceAll("Referencia 2", "referencia_2")
            .replaceAll("Moneda", "moneda")
            .replaceAll("Valor", "valor")
            .replaceAll("Número cobros", "numero_cobros")
            .replaceAll("Cliente", "cliente")
            .replaceAll("País", "pais")
            .replaceAll("Celular", "celular")
            .replaceAll("Día recordatorio", "dia_recordatorio")
            .replaceAll("Día vencimiento", "dia_vencimiento")
            .replaceAll("Inicio del cobro", "inicio_del_cobro")
        );

        const jsonString = JSON.stringify(json, null, 2);

        const parseJson = JSON.parse(jsonString);

        setConvertFile(parseJson);

        setFileError("");
        setLoadFile(false);
      } else {
        const parseBase = result.assets[0]?.uri;

        setFile(result?.assets);

        const { data: convertFile } = await axios.post(
          "https://vento-report-server-2dyszabn2a-uc.a.run.app/convert-to-csv",
          {
            file: parseBase,
          }
        );

        setConvertFile(convertFile?.data?.data);

        setFileError("");
        setLoadFile(false);
      }
    }, 2000);
  };
  const clearDocument = () => setFile(null);
  const clearCharge = () => {
    Object.keys(newChargeInfo).map((x) => handleChange(x, ""));
    Object.keys(scheduleCharge).map((x) => handleChangeSchedule(x, ""));
  };
  const validate = () => {
    Keyboard.dismiss();
    let valid = true;
    if (!newChargeInfo.description) {
      handleError("Este campo es requerido", "description");
      valid = false;
    }
    if (!newChargeInfo.value) {
      handleError("Este campo es requerido", "value");
      valid = false;
    } else if (newChargeInfo.value < 0) {
      handleError("Escribe un valor válido", "value");
      valid = false;
    }
    if (!newChargeInfo.clientName) {
      handleError("Escribe un nombre válido", "clientName");
      valid = false;
    }
    if (!newChargeInfo.clientPhone) {
      handleError("Este campo es requerido", "clientPhone");
      valid = false;
    } else if (newChargeInfo.clientPhone.length !== 10) {
      handleError("El número debe tener 10 digitos", "clientPhone");
    }
    if (tab === "programado") {
      if (scheduleCharge.scheduleFrequency === "Mensual") {
        if (!scheduleCharge.scheduleReminder) {
          handleErrorSchedule("Este campo es requerido", "scheduleReminder");
          valid = false;
        } else if (
          Number(scheduleCharge.scheduleReminder.replace(/\D/g, "")) > 32 ||
          Number(scheduleCharge.scheduleReminder.replace(/\D/g, "")) < 0
        ) {
          handleErrorSchedule("Ingresa una fecha válida", "scheduleReminder");
          valid = false;
        }
        if (!scheduleCharge.scheduleLimit) {
          handleErrorSchedule("Este campo es requerido", "scheduleLimit");
          valid = false;
        } else if (
          Number(scheduleCharge.scheduleLimit.replace(/\D/g, "")) > 32 ||
          Number(scheduleCharge.scheduleLimit.replace(/\D/g, "")) < 0
        ) {
          handleErrorSchedule("Ingresa una fecha válida", "scheduleLimit");
          valid = false;
        }
        if (!scheduleCharge.scheduleAmount) {
          handleErrorSchedule("Este campo es requerido", "scheduleAmount");
          valid = false;
        } else if (Number(scheduleCharge.scheduleAmount) < 0) {
          handleErrorSchedule("Escribe un valor válido", "scheduleAmount");
          valid = false;
        } else if (Number(scheduleCharge.scheduleAmount) > 12) {
          handleErrorSchedule("La cantidad máxima es 12", "scheduleAmount");
          valid = false;
        }
        if (!scheduleCharge.scheduleStart) {
          handleErrorSchedule("Escribe un nombre válido", "scheduleStart");
          valid = false;
        }
      } else if (scheduleCharge.scheduleFrequency === "Único") {
        if (!scheduleCharge.scheduleDate) {
          handleErrorSchedule("Este campo es requerido", "scheduleDate");
          valid = false;
        }
      }
    }

    valid && setChargeSent(true);
  };

  const handleChange = (field, value) => {

    setNewChargeInfo((prev) => ({ ...prev, [field]: value }));
  };
  const handleError = (message, field) => {
    setErrors((prev) => ({ ...prev, [field]: message }));
  };
  const generateCharge = () => {
    setLoading(true);
    setTimeout(() => {
      setValidateInfo(true);

      const clientName = newChargeInfo.clientName;
      const contactName = merchantData?.name;
      try {
        if (tab === "programado") {
          const contactId = merchantData?.phone;
          const merchantName = business?.name;
          const merchantPaymentId = business?.payment_id;
          const merchantId = merchantData?.id;

          TransactionsInfoAPI.createScheduledTransaction({
            clientName,
            contactName,
            contactId,
            merchantName,
            merchantPaymentId,
            merchantId,
            client_id: newChargeInfo.clientPhone,
            description: newChargeInfo.description,
            payment_method: "",
            reference_one: scheduleCharge.reference,
            value: newChargeInfo.value,
            chargeAmount: scheduleCharge.scheduleAmount || 1,
            frequency: scheduleCharge.scheduleFrequency,
            limitDate: scheduleCharge.scheduleLimit,
            reminderDate: scheduleCharge.scheduleReminder,
            startDate:
              scheduleCharge.scheduleFrequency === "Mensual"
                ? scheduleCharge.scheduleStart
                : scheduleCharge.scheduleDate,
          });
        } else {
          TransactionsInfoAPI.createTransaction({
            clientName,
            merchant_name: contactName,
            nickname: merchantData?.nickname,
            client_id: `57${newChargeInfo.clientPhone}`,
            description: newChargeInfo.description,
            merchant_id: business.payment_id,
            merchant_phone: merchantData.phone,
            payment_method: "",
            reference_one: "",
            value: newChargeInfo.value,
          });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }, 3000);
  };
  const generateCsv = async () => {
    setLoading(true);
    setTimeout(() => {
      try {
        const contactName = merchantData?.name;
        const contactId = merchantData?.phone;
        const merchantName = business?.name;
        const merchantPaymentId = business?.payment_id;
        const merchantId = merchantData?.id;

        TransactionsInfoAPI.createMultipleTransaction({
          csvFile: convertFile,
          contactName,
          contactId,
          merchantName,
          merchantPaymentId,
          merchantId,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setMultipleModal(true);
    }, 2000);
  };

  const handleChangeSchedule = (field, value) =>
    setScheduleCharge((prev) => ({ ...prev, [field]: value }));

  const handleErrorSchedule = (message, field) =>
    setScheduleErrors((prev) => ({ ...prev, [field]: message }));

  return {
    tab,
    file,
    setTab,
    errors,
    setFile,
    loading,
    validate,
    loadFile,
    fileError,
    chargeSent,
    setLoading,
    generateCsv,
    clearCharge,
    pickDocument,
    handleChange,
    validateInfo,
    merchantData,
    multipleModal,
    clearDocument,
    setChargeSent,
    newChargeInfo,
    currencyFormat,
    generateCharge,
    scheduleCharge,
    scheduleErrors,
    setMultipleModal,
    handleChangeSchedule,
  };
};

export default useCharge;
