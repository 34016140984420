export const CHECK_OTP = "/check-otp";
export const CONTACTS_QUERY = "/user/";
export const ACCOUNTS_QUERY = "/accounts";
export const BUSINESS_QUERY = "/businesses";
export const SECURITY_OTP_QUERY = "/security-otp";
export const CREATE_CASHOUT_QUERY = "/create-cash-out";
export const TRANSACTIONS_QUERY = "/user-financial-data";
export const CONTACTS_PHONE_QUERY = "/user-info-by-phone/";
export const CHECK_BANK_ACCOUNT_QUERY = "/check-bank-account/";
export const CREATE_BANK_ACCOUNT_QUERY = "/create-bank-account";
export const STRAPI_URL_VENTO = "https://services.qentaz.com/api";
export const GET_BUSINESS_BY_PHONE_QUERY = "/get-business-by-phone";
export const BASE_URL = "https://vento-report-server-2dyszabn2a-uc.a.run.app";

export const CREATE_TRANSACTION_HOOK =
  "https://hook.us1.make.com/rgrilu9lb43vm7om3zcc0ytb39q4f3my";
export const DOWNLOAD_REPORT_HOOK =
  "https://hook.us1.make.com/1vxm8o3talvusot84cebam8fkljyjqit";
export const CREATE_MULTIPLE_TRANSACTION_HOOK =
  "https://hook.us1.make.com/8lnbggeu8mldghxhoku3n3abdvlbxk5b";
export const CREATE_SCHEDULED_TRANSACTION_HOOK =
  "https://hook.us1.make.com/h5skfrapklxjd684xbdwc42j8igpe976";

export const CREATE_INDIVIDUAL_DISPERSION_HOOK =
  "https://hook.us1.make.com/ww636tupjc8n0sbus21u0182iatnqdei";

export const CREATE_MULTIPLE_DISPERSION_HOOK =
  "https://hook.us1.make.com/yw4nert3kc4k995ev11cb3sgjl02mzif";

export const VALIDATE_ID_HOOK =
  "https://hook.us1.make.com/nt4epr1m0jluiss921y2kqhydzwiuk8r";

export const INDIVIDUAL_DISPERSION_CASHOUT =
  "https://hook.us1.make.com/uejht2rpv2po3wrggvaqohfomb97c94l";
