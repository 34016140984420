import { getData, postData } from "../api-call";
import { BASE_URL, CREATE_CASHOUT_QUERY } from "../config";

const createCashout = ({
  amount,
  provider,
  status,
  bank_account,
  contact,
  type,
}) => {
  const data = {
    amount,
    provider,
    status,
    bank_account,
    contact,
    type,
  };
  const requestUrl = `${BASE_URL}${CREATE_CASHOUT_QUERY}`;
  console.log("data", data);

  return postData(requestUrl, data);
};

export const CashoutAPI = {
  createCashout,
};
