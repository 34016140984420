import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { ScrollView } from "react-native";
import { c } from "../../styles/constants";
import EmptyContent from "../../Components/EmptyContent";
const Referrals = () => {
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <EmptyContent />
      </View>
    </ScrollView>
  );
};

export default Referrals;

const styles = StyleSheet.create({});
