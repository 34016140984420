import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import _ from "lodash";
import React, { useState } from "react";
import { c } from "../styles/constants";
import Pagination from "./Pagination";
import ShowMoreDispersion from "./Modal/ShowMoreDispersion";

const DispersionTable = ({
  headers,
  userCountrySymbol,
  currencyFormat,
  transactions,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState("");
  const activeModal = (id) => {
    setSelected(id);
    setShowModal(true);
  };
  const { height } = useWindowDimensions();
  return _.isEmpty(transactions) ? (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
        No tienes dispersiones en estos momentos.
      </Text>
      <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
        ¡Es un buen momento para crear una!
      </Text>
    </View>
  ) : (
    <ScrollView horizontal>
      <View>
        <View style={{ flexDirection: "row", marginTop: c.spacing.spacing_md }}>
          {headers.map((header, i) => (
            <Pressable key={i}>
              <View
                style={[
                  styles.cell,
                  {
                    backgroundColor: c.backgrounds.bgLayout,
                  },
                  i === 0 && {
                    borderTopLeftRadius: c.borderRadius.br_base,
                    borderLeftColor: c.bordersDividers.border,
                  },
                  i === headers.length - 1 && {
                    borderTopRightRadius: c.borderRadius.br_base,
                    borderRightColor: c.bordersDividers.border,
                  },
                  header === "Valor" && { width: 120 },
                  header === "Detalle" && { width: 210 },
                  header === "" && { width: 140 },
                ]}
              >
                <Text style={{ color: c.textIcons.icon }}>{header}</Text>
                {header !== "" && (
                  <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                    <Icon
                      name="chevron-down"
                      color={c.textIcons.icon}
                      size={16}
                    />
                  </View>
                )}
              </View>
            </Pressable>
          ))}
        </View>
        <View style={{ maxHeight: 550 }}>
          <ScrollView>
            {transactions?.map((tx, i) => (
              <>
                <View key={i} style={{ flexDirection: "row" }}>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderLeftColor: c.bordersDividers.border,
                      },
                    ]}
                  >
                    <Text numberOfLines={1}>{tx.full_name}</Text>
                  </View>

                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 210,
                      },
                    ]}
                  >
                    <Text numberOfLines={1}>{tx.description}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 120,
                      },
                    ]}
                  >
                    <Text>{`${userCountrySymbol} ${currencyFormat.format(
                      tx.amount
                    )}`}</Text>
                  </View>

                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                      },
                    ]}
                  >
                    <Text>
                      {new Date(tx.created_at).toLocaleDateString("es-CO", {
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderRightColor: c.bordersDividers.border,
                        width: 140,
                      },
                    ]}
                  >
                    <Pressable onPress={() => activeModal(tx.id)}>
                      <View style={styles.moreButton}>
                        <Text
                          style={{
                            color: c.backgrounds.bgContainer,
                            fontWeight: c.fontWeight.fw_medium,
                          }}
                        >
                          Ver más
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                </View>
                {selected === tx.id && (
                  <ShowMoreDispersion
                    visible={showModal}
                    close={() => setShowModal(false)}
                    tx={tx}
                    countryCurrency={userCountrySymbol}
                    currencyFormat={currencyFormat}
                  />
                )}
              </>
            ))}
          </ScrollView>
        </View>
      </View>
    </ScrollView>
  );
};

export default DispersionTable;

const styles = StyleSheet.create({
  cell: {
    height: 40,
    width: 180,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    paddingVertical: c.spacing.spacing_md,
    borderTopColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    borderBottomColor: c.bordersDividers.border,
  },
  moreButton: {
    width: 95,
    paddingHorizontal: 17,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.desktopColors.desktopBase,
    textAlign: "center",
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableButton: {
    paddingVertical: 6,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_base,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
