import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { Pressable, StyleSheet, Text, View, ScrollView } from "react-native";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";
const More = ({ navigation }) => {
  // const longScreens=[{screen:"Mi perfil", icon:""}]
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <View style={styles.largeButtonContainer}>
          <Pressable onPress={() => navigation.push("Mi perfil")}>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="person-circle-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>Mi perfil</Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable>
          <Pressable onPress={() => navigation.push("¿Cómo funciona Qentaz?")}>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="school-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>
                    ¿Cómo funciona Qentaz?
                  </Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable>
          <Pressable onPress={() => navigation.navigate("Refiere a alguien")}>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="heart-circle-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>
                    Referir a alguien
                  </Text>
                  <Text style={styles.largeButton_caption}>
                    ¡Si! Ambos ganarán
                  </Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable>
          <Pressable>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="help-circle-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>Necesito ayuda</Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
          </Pressable>
        </View>
        {/* <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.shortButtonContainer}>            
            <View style={styles.shortButton}>
              <Pressable>
                <Icon
                  name="arrow-down-circle"
                  size={35}
                  color={c.themeColors.primaryBase}
                />
                <Text
                  style={[
                    txt.base_normal,
                    { color: c.themeColors.primaryBase },
                  ]}
                >
                  Pagar
                </Text>
              </Pressable>
            </View>

            <View style={styles.shortButton}>
              <Pressable>
                <Icon
                  name="add-circle"
                  size={35}
                  color={c.themeColors.primaryBase}
                />
                <Text
                  style={[
                    txt.base_normal,
                    { color: c.themeColors.primaryBase },
                  ]}
                >
                  Retirar
                </Text>
              </Pressable>
            </View>           
          </View>
        </ScrollView> */}
        <Text
          style={[txt.xl_strong, { marginVertical: c.spacing.spacing_big }]}
        >
          Funciones exclusivas
        </Text>
        <View style={styles.largeButtonContainer}>
          {/* <Pressable onPress={() => navigation.navigate("Adquirir Plan Pro")}>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="rocket-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>
                    Adquirir Plan Pro
                  </Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable> */}
          {/* <Pressable
            onPress={() => navigation.navigate("Personalizar mis cobros")}
          >
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="color-palette-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>
                    Personalizar mis cobros
                  </Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable> */}
          <Pressable>
            <View style={styles.spacedRow}>
              <View style={styles.titleRow}>
                <Icon
                  name="cloud-download-outline"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Text style={styles.largeButton_title}>
                    Descargar mi reporte
                  </Text>
                </View>
              </View>
              <Icon name="chevron-forward" size={20} color={c.textIcons.icon} />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

export default More;

const styles = StyleSheet.create({
  largeButtonContainer: {
    padding: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    backgroundColor: c.backgrounds.bgContainer,
  },
  spacedRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  largeButton_title: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
  },
  largeButton_caption: {
    fontSize: c.fontSize.fs_sm,
    color: c.textIcons.description,
  },
  titleRow: { flexDirection: "row", alignItems: "center" },
  shortButtonContainer: {
    marginTop: c.spacing.spacing_md,
    flexDirection: "row",
  },
  shortButton: {
    width: 90,
    borderWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    marginRight: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_xx_sm,
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.themeColors.primaryBg,
  },
});
