import { NavigationContainer } from "@react-navigation/native";
import BottomTabs from "./src/Navigation/BottomTabs";
import RegisterNavigation from "./src/Navigation/RegisterNavigation";
import { useState, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import DrawerNavigator from "./src/Navigation/DrawerNavigator";
export default function App() {
  const [isRegistered, setIsRegistered] = useState(false);
  const { width } = useWindowDimensions();
  const isDesktop = width > 500;
  const token = sessionStorage.getItem("_auth_code_");

  return (
    <NavigationContainer>
      {token ? (
        isDesktop ? (
          <DrawerNavigator />
        ) : (
          <BottomTabs />
        )
      ) : (
        <RegisterNavigation isDesktop={isDesktop} />
      )}
    </NavigationContainer>
  );
}
