import { getData, postData } from "../api-call";
import {
  BASE_URL,
  CREATE_BANK_ACCOUNT_QUERY,
  CHECK_BANK_ACCOUNT_QUERY,
  VALIDATE_ID_HOOK,
} from "../config";

const createBankAccount = ({ name_bank, number, business, contact }) => {
  const data = {
    name_bank,
    number,
    business,
    contact,
  };
  const requestUrl = `${BASE_URL}${CREATE_BANK_ACCOUNT_QUERY}`;
  return postData(requestUrl, data);
};

const getBankAccount = async ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${CHECK_BANK_ACCOUNT_QUERY}${id}`;

  return getData(requestUrl);
};
const validateId = ({ user_id_type, phone }) => {
  const data = { user_id_type, phone };
  const requestUrl = `${VALIDATE_ID_HOOK}`;
  return postData(requestUrl, data);
};
export const BankAccountAPI = {
  createBankAccount,
  getBankAccount,
  validateId,
};
