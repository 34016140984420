import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { ScrollView } from "react-native-gesture-handler";
import { Calendar, LocaleConfig } from "react-native-calendars";
import { StyleSheet, Text, View, Pressable } from "react-native";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const week = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
const dateOptions = {
  month: "long",
  day: "numeric",
};
LocaleConfig.locales["es"] = {
  monthNames: months,
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Agos",
    "Sept",
    "Oct",
    "Nov",
    "Dic",
  ],
  dayNames: week,
  dayNamesShort: ["Dom", "Lun", "Mar", "Mier", "Jue", "Vie", "Sab"],
  today: "Hoy",
};
LocaleConfig.defaultLocale = "es";
const DateFilter = ({ weeks_days }) => {
  const today = new Date(new Date()).toLocaleDateString("es-ES", dateOptions);
  const [showCalendar, setShowCalendar] = useState(false);
  const [period, setPeriod] = useState("");
  const [customDate, setCustomDate] = useState("");
  const firstDay = new Date(weeks_days?.firstDay);
  firstDay.setDate(firstDay.getDate() + 1);

  const lastDay = new Date(weeks_days?.lastDay);

  lastDay.setDate(lastDay.getDate() + 1);
  const formatedDate = new Date(customDate);
  formatedDate.setDate(formatedDate.getDate() + 1);
  return (
    <View>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={styles.screenButtonsContainer}>
          <Pressable onPress={() => setPeriod("hoy")}>
            <View
              style={[
                styles.dateButton,
                period === "hoy" && styles.dateButton_selected,
              ]}
            >
              <Text
                style={[
                  txt.sm_strong,
                  {
                    color:
                      period === "hoy"
                        ? c.themeColors.primaryBase
                        : c.textIcons.disabled,
                  },
                ]}
              >
                {period === "hoy" ? today : "Hoy"}
              </Text>
            </View>
          </Pressable>
          <Pressable onPress={() => setPeriod("semana")}>
            <View
              style={[
                styles.dateButton,
                period === "semana" && styles.dateButton_selected,
              ]}
            >
              <Text
                style={[
                  txt.sm_strong,
                  {
                    color:
                      period === "semana"
                        ? c.themeColors.primaryBase
                        : c.textIcons.disabled,
                  },
                ]}
              >
                {period === "semana"
                  ? `${firstDay?.toLocaleDateString(
                      "es-ES",
                      dateOptions
                    )} - ${lastDay?.toLocaleDateString("es-ES", dateOptions)}`
                  : "Esta semana"}
              </Text>
            </View>
          </Pressable>
          <Pressable onPress={() => setPeriod("mes")}>
            <View
              style={[
                styles.dateButton,
                period === "mes" && styles.dateButton_selected,
              ]}
            >
              <Text
                style={[
                  txt.sm_strong,
                  {
                    color:
                      period === "mes"
                        ? c.themeColors.primaryBase
                        : c.textIcons.disabled,
                  },
                ]}
              >
                {period === "mes" ? months[new Date().getMonth()] : "Este mes"}
              </Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => {
              setPeriod("personalizada");
              setShowCalendar(true);
            }}
          >
            <View
              style={[
                styles.dateButton,
                period === "personalizada" && styles.dateButton_selected,
              ]}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Icon
                  name="calendar-outline"
                  size={18}
                  color={
                    period === "personalizada"
                      ? c.themeColors.primaryBase
                      : c.textIcons.disabled
                  }
                />
                <Text
                  style={[
                    txt.sm_strong,
                    {
                      color:
                        period === "personalizada"
                          ? c.themeColors.primaryBase
                          : c.textIcons.disabled,
                      marginLeft: c.spacing.spacing_xx_sm,
                    },
                  ]}
                >
                  {period === "personalizada"
                    ? customDate === ""
                      ? today
                      : formatedDate.toLocaleDateString("es-ES", dateOptions)
                    : "Elegir fecha"}
                </Text>
              </View>
            </View>
          </Pressable>
        </View>
      </ScrollView>
      {showCalendar && (
        <View style={styles.calendarCard}>
          <Calendar
            style={{ borderRadius: 10, marginTop: 15 }}
            onDayPress={(day) => setCustomDate(day.dateString)}
            minDate="2023-01-01"
            renderArrow={(direction) =>
              direction === "left" ? (
                <Icon
                  name="chevron-back-circle"
                  size={25}
                  color={c.themeColors.primaryBase}
                />
              ) : (
                <Icon
                  name="chevron-forward-circle"
                  size={25}
                  color={c.themeColors.primaryBase}
                />
              )
            }
          />
        </View>
      )}
    </View>
  );
};

export default DateFilter;

const styles = StyleSheet.create({
  screenButtonsContainer: {
    marginTop: c.spacing.spacing_md,
    flexDirection: "row",
  },
  dateButton: {
    borderWidth: 1,
    height: 30,
    borderRadius: c.borderRadius.br_sm,
    marginRight: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  dateButton_selected: {
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.themeColors.primaryBg,
  },
  calendarCard: {
    borderWidth: 1,
    padding: c.spacing.spacing_md,
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
});
