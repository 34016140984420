import React, { useCallback } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { c } from "../../styles/constants";
import useDispersion from "./useDispersion";
import { txt } from "../../styles/typestyles";
import DispersionTable from "../../Components/DispersionTable";
import MultipleDispersion from "../../Components/MultipleDispersion";
import IndividualDispersion from "../../Components/IndividualDispersion";
import DispersionDetails from "../../Components/Modal/DispersionDetails";
import PayoutLoader from "../../Components/Modal/PayoutLoader";
import ConfirmCashout from "../../Components/Modal/ConfirmCashout";
import StatusDispersion from "../../Components/Modal/StatusDispersion";
const Dispersion = () => {
  const {
    tab,
    file,
    setTab,
    payNow,
    response,
    loadFile,
    fileError,
    checkInfo,
    isMultiple,
    showStatus,
    generateCsv,
    showDetails,
    controlForm,
    showConfirm,
    validateCash,
    transactions,
    pickDocument,
    setCheckInfo,
    setShowStatus,
    createCashout,
    clearDocument,
    notEnoughCash,
    checkoutModal,
    setShowConfirm,
    cashoutLoading,
    dispersionInfo,
    currencyFormat,
    showMessageDispersion,
    setShowDetails,
    handleDispersion,
    setCheckoutModal,
    dispersionErrors,
    setCashoutLoading,
    generateIndividualDispersion,
    merchantData: { countryCurrency },
  } = useDispersion();
  // console.log(transactions);
  const tabs = [
    {
      id: "tabla",
      title: "Mis dispersiones",
      description: "Visualiza todas tus dispersiones",
    },
    {
      id: "unico",
      title: "Crear dispersión individual",
      description: "Genera un pago inmediato a tus clientes",
    },

    {
      id: "multiple",
      title: "Crear dispersión múltiple",
      description:
        "genera pagos al instante o programa más de dos pagos al mismo tiempo.",
    },
  ];
  // console.log(transactions);
  const SwitchOption = useCallback(
    (errors) => {
      const {
        files,
        loader,
        transactions,
        dispersionInfo,
        fileErrorLoader,
        countryCurrency,
        errors: errorData,
        showMessageDispersion
      } = errors;

      switch (tab) {
        case "tabla":
          return (
            <DispersionTable
              headers={[
                "Destinatario",
                "Detalle",
                "Valor",
                "Fecha de creación",
                "",
              ]}
              transactions={transactions}
              currencyFormat={currencyFormat}
              userCountrySymbol={countryCurrency}
            />
          );

        case "unico":
          return (
            <IndividualDispersion
              control={() => controlForm(dispersionInfo)}
              errors={errorData}
              handleDispersion={handleDispersion}
            />
          );
        case "multiple":
          return (
            <>
              <MultipleDispersion
                file={files}
                loadFile={loader}
                fileError={fileErrorLoader}
                generateCsv={generateCsv}
                pickDocument={pickDocument}
                showMessageDispersion={showMessageDispersion}
                clearDocument={clearDocument}
              />
            </>
          );
        default:
          null;
      }
    },
    [tab]
  );

  return (
    <>
      <View
        style={{
          paddingVertical: c.spacing.spacing_x_big,
          paddingHorizontal: c.spacing.spacing_lg,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: c.fontSize.fs_huge }}>Dispersiones</Text>
          {/* <Pressable>
            <View style={styles.downloadButton}>
              <Text
                style={{
                  color: c.backgrounds.bgContainer,
                  marginRight: c.spacing.spacing_sm,
                }}
              >
                Descargar .csv
              </Text>
              <Icon
                name="download"
                size={20}
                color={c.backgrounds.bgContainer}
              />
            </View>
          </Pressable> */}
        </View>
        <View
          style={{
            marginTop: c.spacing.spacing_xxx_big,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: "27%" }}>
            {tabs.map((t) => (
              <Pressable key={t.id} onPress={() => setTab(t.id)}>
                <View
                  style={[
                    styles.cardButton,
                    tab === t.id && {
                      backgroundColor: c.desktopColors.desktopBase,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: c.fontWeight.fw_bold,
                      marginBottom: 5,
                      color:
                        tab === t.id
                          ? c.backgrounds.bgContainer
                          : c.desktopColors.desktopTitle,
                    }}
                  >
                    {t.title}
                  </Text>
                  <Text
                    style={{
                      color:
                        tab === t.id
                          ? c.backgrounds.bgContainer
                          : c.textIcons.description,
                    }}
                  >
                    {t.description}
                  </Text>
                </View>
              </Pressable>
            ))}
          </View>
          <View style={{ width: "71%" }}>
            <SwitchOption
              files={file}
              loader={loadFile}
              errors={dispersionErrors}
              transactions={transactions}
              fileErrorLoader={fileError}
              dispersionInfo={dispersionInfo}
              countryCurrency={countryCurrency}
              showMessageDispersion={showMessageDispersion}
            />
          </View>
        </View>
      </View>

      <DispersionDetails
        payNow={payNow}
        response={response}
        visible={showDetails}
        checkInfo={checkInfo}
        isMultiple={isMultiple}
        validateCash={validateCash}
        showMessageDispersion={showMessageDispersion}
        bank={dispersionInfo?.bank}
        setCheckInfo={setCheckInfo}
        notEnoughCash={notEnoughCash}
        currencyFormat={currencyFormat}
        idType={dispersionInfo?.idType}
        countryCurrency={countryCurrency}
        close={() => setShowDetails(false)}
        idNumber={dispersionInfo?.idNumber}
        
        confirm={generateIndividualDispersion}
        clientName={dispersionInfo?.receiverName}
        description={dispersionInfo?.description}
        accountType={dispersionInfo?.accountType}
        clientPhone={dispersionInfo?.receiverPhone}
        accountNumber={dispersionInfo?.accountNumber}
      />
      <PayoutLoader
        visible={checkoutModal}
        close={() => setCheckoutModal(false)}
      />
      <ConfirmCashout
        visible={showConfirm}
        close={setShowConfirm}
        createCashout={createCashout}
        loading={cashoutLoading}
        showMessageDispersion={showMessageDispersion}
      />
      <StatusDispersion
        tx={dispersionInfo}
        visible={showStatus}
        status="PENDING"
        currencyFormat={currencyFormat}
        close={() => setShowStatus(false)}
        countryCurrency={countryCurrency}
      />
    </>
  );
};

export default Dispersion;

const styles = StyleSheet.create({
  cardButton: {
    width: "100%",
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    marginBottom: c.spacing.spacing_big,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    paddingVertical: c.spacing.spacing_xx_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  downloadButton: {
    paddingVertical: 6,
    alignItems: "center",
    flexDirection: "row",
    borderRadius: c.borderRadius.br_base,
    paddingHorizontal: c.spacing.spacing_sm,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
