import { Modal, StyleSheet, Text, View, Pressable, Image } from "react-native";
import React from "react";
import { c } from "../../styles/constants";
import Icon from "react-native-vector-icons/Ionicons";
import { txt } from "../../styles/typestyles";
import Divider from "../Divider";

const ShowComprobant = ({ visible, close, receiptImage }) => {
  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={close}
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={styles.cardContainer}>
          <Pressable onPress={close}>
            <View style={styles.backButton_icon}>
              <Icon
                name="arrow-back-circle-outline"
                size={16}
                color={c.textIcons.icon}
              />
              <Text style={[txt.sm_normal, styles.backButton_txt]}>
                Regresar
              </Text>
            </View>
          </Pressable>
          <Image
            source={receiptImage}
            resizeMode="contain"
            style={{ width: "100%", height: 380 }}
          />
        </View>
      </View>
    </Modal>
  );
};

export default ShowComprobant;

const styles = StyleSheet.create({
  cardContainer: {
    width: "85%",
    borderRadius: 12,
    alignSelf: "center",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  backButton_icon: {
    flexDirection: "row",
    alignSelf: "flex-end",
    alignItems: "center",
    marginVertical: c.spacing.spacing_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
});
