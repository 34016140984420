import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { StyleSheet, Text, View, Pressable } from "react-native";

import Divider from "./Divider";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
const NewUserDetails = ({
  action,
  isValidated,
  newUserName,
  newUserRole,
  navigation,
  newUserPhone,
  validateInfoAction,
}) => {
  return (
    <View style={{ padding: c.spacing.spacing_md }}>
      <View
        style={{
          paddingHorizontal: c.spacing.spacing_md,
          paddingTop: c.spacing.spacing_big,
        }}
      >
        <View style={{ alignSelf: "center", alignItems: "center" }}>
          <Icon
            name={!isValidated ? "time" : "checkmark-circle"}
            size={48}
            color={
              !isValidated ? c.warning.warningBase : c.themeColors.primaryBase
            }
          />
          <Text
            style={[
              txt.xl_strong,
              { marginTop: c.spacing.spacing_md, textAlign: "center" },
            ]}
          >
            {!isValidated
              ? "Verifica la información del usuario"
              : "¡Usuario invitado exítosamente!"}
          </Text>
        </View>
        <Divider />
        <View style={{ marginTop: c.spacing.spacing_big }}>
          <Text
            style={{
              textAlign: "center",
              fontSize: c.fontSize.fs_big,
              fontWeight: c.fontWeight.fw_medium,
            }}
          >
            Detalles del usuario
          </Text>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Nombre:</Text>
            <Text style={txt.lg_normal}>{newUserName}</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>WhatsApp:</Text>
            <Text style={txt.lg_normal}>{newUserPhone}</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Rol:</Text>
            <Text style={txt.lg_normal}>{newUserRole}</Text>
          </View>

          <Pressable onPress={!isValidated ? validateInfoAction : action}>
            <View style={styles.mainButton}>
              <Text style={txt.lg_strong}>
                {!isValidated ? "Invitar usuario" : "Invitar otro usuario"}
              </Text>
            </View>
          </Pressable>
          <Pressable
            onPress={
              !isValidated ? action : () => navigation.navigate("Actividad")
            }
          >
            <View
              style={[
                styles.mainButton,
                { backgroundColor: c.themeColors.primaryBg },
              ]}
            >
              <Text style={txt.lg_strong}>
                {!isValidated ? "Corregir información " : "Ver mi actividad"}
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default NewUserDetails;

const styles = StyleSheet.create({
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
