import React from "react";
import { StyleSheet } from "react-native";
import Ionicon from "react-native-vector-icons/Ionicons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import MenuNavigator from "./MenuNavigator";
import Program from "../Screens/Program/Program";
import ActivityNavigator from "./Drawer/ActivityNavigator";
import Analysis from "../Screens/Analysis/Analysis";
import Assistant from "../Screens/Assistant/Assistant";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
import AppHeader from "./AppHeader/AppHeader";
import ProgramNavigator from "./ProgramNavigator";
import Charge from "../Screens/Charge/Charge";
const Tabs = createBottomTabNavigator();
const BottomTabs = () => {
  return (
    <Tabs.Navigator
      headerMode="screen"
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          let rn = route.name;
          if (rn === "Actividad") {
            iconName = focused ? "swap-vertical" : "swap-vertical-outline";
          } else if (rn === "Analisis") {
            iconName = focused ? "stats-chart" : "stats-chart-outline";

            // } else if (rn === "Asistente") {
            //   iconName = focused ? "flash" : "flash-outline";
          } else if (rn === "Cobro") {
            iconName = focused ? "arrow-up-circle" : "arrow-up-circle-outline";
          } else if (rn === "Programar") {
            iconName = focused ? "notifications" : "notifications-outline";
          } else if (rn === "Mas") {
            iconName = focused ? "apps" : "apps-outline";
          }
          return <Ionicon name={iconName} size={size} color={color} />;
        },
        header: ({ navigation, route }) => (
          <AppHeader navigation={navigation} title={route.name} />
        ),

        tabBarActiveTintColor: c.themeColors.primaryBase,
        tabBarInactiveTintColor: c.textIcons.icon,
        tabBarLabelStyle: styles.label,
        tabBarStyle: styles.tabBar,
      })}
    >
      <Tabs.Screen
        name="Actividad"
        options={{ headerShown: false }}
        component={ActivityNavigator}
      />
      <Tabs.Screen
        name="Analisis"
        component={Analysis}
        options={{ title: "Análisis" }}
      />
      <Tabs.Screen name="Cobro" component={Charge} />

      {/* <Tabs.Screen name="Asistente" component={Assistant} /> */}
      {/* <Tabs.Screen
        name="Programar"
        component={ProgramNavigator}
        options={{ headerShown: false }}
      /> */}
      <Tabs.Screen
        name="Mas"
        component={MenuNavigator}
        options={{ headerShown: false }}
      />
    </Tabs.Navigator>
  );
};

export default BottomTabs;

const styles = StyleSheet.create({
  tabBar: {
    height: 75,
    width: "100%",
    minWidth: 320,
    maxWidth: 500,
    padding: c.spacing.spacing_big,
    borderRadius: c.borderRadius.br_lg,
    backgroundColor: c.backgrounds.bgContainer,
  },
  label: { paddingTop: 10, fontSize: c.fontSize.fs_sm },
});
