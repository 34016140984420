import { ActivityIndicator, Modal, StyleSheet, Text, View } from "react-native";
import React from "react";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";

const PayoutLoader = ({ close,visible }) => {
  return (
    <Modal visible={visible} transparent animationType="fade" onRequestClose={close}>
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={[styles.modalCard]}>
          <ActivityIndicator color={c.desktopColors.desktopBase} size="large" />
          <Text style={[txt.base_strong, { marginTop: c.spacing.spacing_md }]}>
            Redirigiendo al checkout
          </Text>
        </View>
      </View>
    </Modal>
  );
};

export default PayoutLoader;

const styles = StyleSheet.create({
  modalCard: {
    borderRadius: 12,
    alignSelf: "center",
    alignItems: "center",
    padding: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
});
