import {
  Text,
  View,
  Modal,
  Pressable,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import { c } from "../../styles/constants";
import Divider from "../Divider";
import { txt } from "../../styles/typestyles";

const DispersionDetails = ({
  bank,
  close,
  idType,
  payNow,
  visible,
  confirm,
  response,
  idNumber,
  checkInfo,
  isMultiple,
  clientName,
  accountType,
  description,
  clientPhone,
  validateCash,
  showMessageDispersion,
  setCheckInfo,
  notEnoughCash,
  accountNumber,
  currencyFormat,
  countryCurrency,
}) => {
  const { height } = useWindowDimensions();
  return (
    <Modal transparent visible={visible} onRequestClose={close}>
      <View style={{ backgroundColor: "rgba(0,0,0,0.25)", height }}>
        <View style={[styles.modalCard, { height }]}>
          <ScrollView>
            <View
              style={{
                paddingHorizontal: c.spacing.spacing_md,
              }}
            >
              <View style={styles.summaryRow}>
                <Pressable onPress={close}>
                  <View style={styles.backButton_icon}>
                    <Icon
                      name="arrow-back-circle-outline"
                      size={16}
                      color={c.textIcons.icon}
                    />
                    <Text style={[txt.sm_normal, styles.backButton_txt]}>
                      Regresar
                    </Text>
                  </View>
                </Pressable>
                {!isMultiple && <Text>Paso {checkInfo ? "2" : "1"} de 2</Text>}
              </View>
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Icon
                  name="remove-circle"
                  size={46}
                  color={
                    !checkInfo ? c.warning.warningBase : c.textIcons.placeholder
                  }
                />
                <Text
                  style={[
                    txt.xl_strong,
                    { marginTop: c.spacing.spacing_xx_sm },
                  ]}
                >
                  {checkInfo
                    ? " Detalles de la dispersión"
                    : "Verifica tu información"}
                </Text>
              </View>
              <Divider />

              {checkInfo ? (
                <>
                  {!response ? (
                    <View style={{ alignItems: "center" }}>
                      <ActivityIndicator
                        size="large"
                        color={c.desktopColors.desktopBase}
                      />
                    </View>
                  ) : (
                    <>
                      <View style={styles.summaryRow}>
                        <Text style={txt.lg_strong}>
                          Cantidad de dispersiones:
                        </Text>
                        <Text style={txt.lg_normal}>
                          {response?.payout_quantity || 1}
                        </Text>
                      </View>

                      <View style={styles.summaryRow}>
                        <Text style={txt.lg_strong}>Valor a dispersar:</Text>
                        <Text style={txt.lg_normal}>
                          {`${countryCurrency} ${currencyFormat.format(
                            response?.payout_request
                          )}`}
                        </Text>
                      </View>
                      <View style={styles.summaryRow}>
                        <Text style={txt.lg_strong}>
                          Costo de la dispersión:
                        </Text>
                        <Text style={txt.lg_normal}>
                          {`${countryCurrency} ${currencyFormat.format(
                            response?.payout_fee
                          )}`}
                        </Text>
                      </View>
                      <View style={styles.summaryRow}>
                        <Text style={txt.lg_strong}>
                          Total de la dispersión:
                        </Text>
                        <Text style={txt.lg_normal}>
                          {`${countryCurrency} ${currencyFormat.format(
                            response?.payout_total
                          )}`}
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ) : (
                <>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Descripción:</Text>
                    <Text style={[txt.lg_normal, { textAlign: "right" }]}>
                      {description}
                    </Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Cliente:</Text>
                    <Text style={txt.lg_normal}>{clientName}</Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Celular del cliente:</Text>
                    <Text style={txt.lg_normal}>{clientPhone}</Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Banco:</Text>
                    <Text style={txt.lg_normal}>{bank}</Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Documento de identidad:</Text>
                    <Text style={txt.lg_normal}>
                      {idType} {idNumber}
                    </Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>tipo de cuenta:</Text>
                    <Text style={txt.lg_normal}>{accountType}</Text>
                  </View>
                  <View style={styles.summaryRow}>
                    <Text style={txt.lg_strong}>Número de cuenta:</Text>
                    <Text style={txt.lg_normal}>{accountNumber}</Text>
                  </View>
                </>
              )}
              {notEnoughCash && (
                <View style={styles.alert}>
                  <Text style={styles.alertText}>
                    ¡Lo sentimos! No cuentas con dinero suficiente para realizar
                    esta dispersión
                  </Text>
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: checkInfo ? "100%" : "47%" }}>
                  <Pressable
                    onPress={checkInfo ? validateCash : close}
                    disabled={notEnoughCash}
                  >
                    <View
                      style={[
                        styles.mainButton,
                        { backgroundColor: c.desktopColors.desktopBg },
                      ]}
                    >
                      <Text style={{ color: c.desktopColors.desktopBase }}>
                        {checkInfo
                          ? "Descontar de mi saldo"
                          : "Modificar datos"}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                {!checkInfo && (
                  <View style={{ width: "47%" }}>
                    <Pressable
                      onPress={
                        checkInfo
                          ? payNow
                          : () => {
                              setCheckInfo(true);
                              confirm();
                            }
                      }
                    >
                      <View style={[styles.mainButton]}>
                        <Text style={{ color: c.backgrounds.bgContainer }}>
                          {checkInfo ? "Pagar por mi cuenta" : "Continuar"}
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
              </View>
            </View>
            <View
              style={{ marginTop: c.spacing.spacing_md, alignSelf: "center" }}
            >
              <Pressable>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <MCI
                    name="message-question-outline"
                    size={14}
                    color={c.textIcons.icon}
                  />
                  <Text
                    style={[
                      txt.sm_normal,
                      {
                        color: c.textIcons.description,
                        marginLeft: c.spacing.spacing_sm,
                      },
                    ]}
                  >
                    Reportar un problema
                  </Text>
                </View>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default DispersionDetails;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    borderWidth: 2,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  alert: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    marginTop: c.spacing.spacing_big,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.error.errorHoverBg,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xx_sm,
  },
  alertText: {
    color: c.error.errorText,
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: c.spacing.spacing_xx_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
});
