import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { ScrollView } from "react-native";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import { TextInput } from "react-native";
import { Pressable } from "react-native";

const Profile = () => {
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={styles.label}>¿Cómo quieres que te llamemos?</Text>
          <TextInput
            style={styles.input}
            placeholder="Nombre"
            placeholderTextColor={c.textIcons.placeholder}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={styles.label}>Documento de identidad</Text>
          <TextInput
            style={[styles.input, styles.input_disabled]}
            editable={false}
            value="CC 11223344"
            placeholderTextColor={c.textIcons.placeholder}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={styles.label}>Actividad del negocio</Text>
          <TextInput
            style={[styles.input, styles.input_disabled]}
            editable={false}
            value="6578 - Elaboración de algo más"
            placeholderTextColor={c.textIcons.placeholder}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={styles.label}>Ubicación</Text>
          <TextInput
            style={[styles.input, styles.input_disabled]}
            editable={false}
            value="Popayán, Cauca"
            placeholderTextColor={c.textIcons.placeholder}
          />
        </View>
        <View style={{ marginTop: c.spacing.spacing_md }}>
          <Text style={styles.label}>Plan</Text>
          <TextInput
            style={[styles.input, styles.input_disabled]}
            editable={false}
            value="Básico"
            placeholderTextColor={c.textIcons.placeholder}
          />
        </View>
        <Pressable>
          <View style={styles.mainButton}>
            <Text style={txt.lg_strong}>Guardar cambios</Text>
          </View>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default Profile;

const styles = StyleSheet.create({
  label: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
    lineHeight: c.lineHeight.lh3,
  },
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  input_disabled: {
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
