import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import _ from "lodash";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";

import useRetire from "./useRetire";
import { c } from "../../styles/constants";
import Loader from "../../Components/Loader";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";
import InputComponent from "../../Components/InputComponent";
import RetireDetails from "../../Components/RetireDetails";
import RetireTable from "../../Components/RetireTable";
import DropdownComponent from "../../Components/DropdownComponent";
import RetireDetails2 from "../../Components/Modal/RetireDetails2";
import NewBankAccount from "../../Components/Modal/NewBankAccount";
import SuccessBankAccount from "../../Components/Modal/SuccessBankAccount";

const Retire = ({ navigation }) => {
  const screenWidth = useWindowDimensions().width;
  const isDesktop = screenWidth > 500;
  const {
    info,
    errors,
    headers,
    loading,
    validate,
    bankList,
    checkInfo,
    addAccount,
    ascendance,
    sortingTable,
    handleChange,
    transactions,
    headerOption,
    showDropdown,
    bankAccounts,
    accountModal,
    currentAmount,
    setAscendance,
    toggleDropdown,
    currencyFormat,
    generateRetire,
    setHeaderOption,
    setAccountModal,
    handleNewAccount,
    validationLoader,
    newAccountErrors,
    showRetiredModal,
    showSuccessModal,
    selectBankAccount,
    setShowSuccessModal,
    setShowRetiredModal,
    setSelectBankAccount,
    validateNewBankAccountInfo,
    merchantData: { user_country_symbol, role, name, userId, idType },
  } = useRetire({ navigation });

  return (
    <>
      {role === "owner" || role === "admin" ? (
        <>
          {isDesktop ? (
            <>
              <View style={{ padding: "2%" }}>
                <Text style={{ fontSize: c.fontSize.fs_huge }}>Retirar</Text>
                <View
                  style={{
                    marginTop: c.spacing.spacing_big,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={styles.rightContainer}>
                    <View style={[styles.balanceCard, styles.balanceDesktop]}>
                      <Text style={[styles.desktopCardTitle]}>Saldo</Text>
                      <Text style={[styles.desktopCardAmount]}>
                        {`${user_country_symbol} ${currencyFormat.format(
                          currentAmount
                        )}`}
                      </Text>
                      <Text style={[{ color: c.backgrounds.bgContainer }]}>
                        El dinero que tienes disponible para retirar
                      </Text>
                    </View>
                    {!_.isEmpty(bankAccounts) ? (
                      <>
                        <InputComponent
                          isNumeric
                          label="Valor a retirar"
                          placeholder="$12.345"
                          onChangeText={(val) => handleChange("value", val)}
                          error={errors.value}
                        />
                        <Text style={styles.label}>Cuenta destino</Text>
                        <Pressable onPress={toggleDropdown}>
                          <View
                            style={[
                              styles.input,
                              {
                                flexDirection: "row",
                                justifyContent: "space-between",
                              },
                            ]}
                          >
                            <Text style={{ color: c.textIcons.text }}>
                              {selectBankAccount?.bankName ||
                                "Elige una cuenta"}
                            </Text>
                            <Icon
                              name={
                                showDropdown
                                  ? "chevron-up-outline"
                                  : "chevron-down-outline"
                              }
                              size={24}
                            />
                          </View>
                        </Pressable>
                        <ScrollView>
                          {showDropdown && (
                            <View style={[styles.input, { maxHeight: 150 }]}>
                              {bankAccounts?.map((x) => (
                                <>
                                  <Pressable
                                    key={x?.id}
                                    onPress={() => {
                                      setSelectBankAccount({
                                        bankName: x?.attributes?.name_bank,
                                        bankId: x?.id,
                                      });
                                      toggleDropdown();
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: "100",
                                        flexDirection: "row",
                                        paddingVertical: c.spacing.spacing_sm,
                                      }}
                                    >
                                      <Text style={txt.lg_strong}>
                                        {x?.attributes?.name_bank}
                                      </Text>
                                      <Text
                                        style={{
                                          color: c.textIcons.description,
                                          marginLeft: c.spacing.spacing_sm,
                                        }}
                                      >
                                        {x?.attributes?.number}
                                      </Text>
                                    </View>
                                  </Pressable>
                                  <View
                                    style={{
                                      width: "100%",
                                      height: 1,
                                      backgroundColor: c.bordersDividers.border,
                                    }}
                                  ></View>
                                </>
                              ))}
                            </View>
                          )}
                        </ScrollView>
                      </>
                    ) : (
                      <View style={styles.alert}>
                        <Text style={styles.alertText}>
                          ¡Lo sentimos! No cuentas con ninguna cuenta registrada
                          para retirar
                        </Text>
                      </View>
                    )}
                    <Pressable onPress={validate}>
                      <View style={styles.desktopButton}>
                        <Text style={[{ color: c.backgrounds.bgContainer }]}>
                          Retirar dinero
                        </Text>
                      </View>
                    </Pressable>
                    <Pressable onPress={() => setAccountModal(true)}>
                      <View
                        style={[
                          styles.desktopButton,
                          {
                            backgroundColor: c.desktopColors.desktopBg,
                            borderColor: c.desktopColors.desktopBase,
                          },
                        ]}
                      >
                        <Text style={{ color: c.desktopColors.desktopBase }}>
                          Registrar nueva cuenta
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                  <View style={{ width: "69%" }}>
                    <ScrollView horizontal>
                      <RetireTable
                        name={name}
                        headers={headers}
                        ascendance={ascendance}
                        transactions={transactions}
                        sortingTable={sortingTable}
                        headerOption={headerOption}
                        currencyFormat={currencyFormat}
                        setHeaderOption={setHeaderOption}
                        userCountrySymbol={user_country_symbol}
                        setAscendance={() => setAscendance(!ascendance)}
                      />
                    </ScrollView>
                  </View>
                </View>
              </View>
              <NewBankAccount
                idType={idType}
                idNumber={userId}
                bankList={bankList}
                visible={accountModal}
                errors={newAccountErrors}
                handleNewAccount={handleNewAccount}
                validationLoader={validationLoader}
                close={() => setAccountModal(false)}
                validate={validateNewBankAccountInfo}
              />
              <SuccessBankAccount
                visible={showSuccessModal}
                close={() => setShowSuccessModal(false)}
              />
            </>
          ) : (
            <ScrollView>
              <View style={{ padding: c.spacing.spacing_md }}>
                <View style={styles.balanceCard}>
                  <Text style={styles.balanceTitle}>Saldo</Text>
                  <Text style={txt.heading2}>
                    {`${user_country_symbol} ${currencyFormat.format(
                      currentAmount
                    )}`}
                  </Text>
                  <Text style={txt.base_strong}>
                    El dinero que tienes disponible para retirar
                  </Text>
                </View>
                {currentAmount < 10000 && (
                  <View style={styles.alert}>
                    <Text style={styles.alertText}>
                      ¡Lo sentimos! No cuentas con dinero suficiente para
                      realizar un retiro
                    </Text>
                  </View>
                )}
                {bankAccounts.length === 0 && (
                  <View style={styles.alert}>
                    <Text style={styles.alertText}>
                      ¡Lo sentimos! No cuentas con ninguna cuenta registrada
                      para retirar
                    </Text>
                  </View>
                )}
                <Divider />
                {bankAccounts.length > 0 && (
                  <>
                    <InputComponent
                      isNumeric
                      label="Valor a retirar"
                      placeholder="$12.345"
                      onChangeText={(val) => handleChange("value", val)}
                      error={errors.value}
                    />
                    <View style={{ marginTop: c.spacing.spacing_md }}>
                      <Text style={txt.lg_strong}>Cuenta destino</Text>
                      <Pressable onPress={toggleDropdown}>
                        <View
                          style={[
                            styles.input,
                            {
                              flexDirection: "row",
                              justifyContent: "space-between",
                            },
                          ]}
                        >
                          <Text style={{ color: c.textIcons.text }}>
                            {selectBankAccount?.bankName || "Elige una cuenta"}
                          </Text>
                          <Icon
                            name={
                              showDropdown
                                ? "chevron-up-outline"
                                : "chevron-down-outline"
                            }
                            size={24}
                          />
                        </View>
                      </Pressable>
                      {showDropdown && (
                        <View style={styles.input}>
                          {bankAccounts?.map((x) => (
                            <Pressable
                              key={x?.id}
                              onPress={() => {
                                setSelectBankAccount({
                                  bankName: x?.attributes?.name_bank,
                                  bankId: x?.id,
                                });
                                toggleDropdown();
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  paddingVertical: c.spacing.spacing_sm,
                                }}
                              >
                                <Text style={txt.lg_strong}>
                                  {x?.attributes?.name_bank}
                                </Text>
                                <Text
                                  style={{
                                    color: c.textIcons.description,
                                    marginLeft: c.spacing.spacing_sm,
                                  }}
                                >
                                  {x?.attributes?.number}
                                </Text>
                              </View>
                              <View
                                style={{
                                  width: "100%",
                                  height: 1,
                                  backgroundColor: c.bordersDividers.border,
                                }}
                              ></View>
                            </Pressable>
                          ))}
                        </View>
                      )}
                    </View>
                    <Pressable onPress={validate}>
                      <View style={styles.mainButton}>
                        <Text style={txt.lg_strong}>Retirar dinero</Text>
                      </View>
                    </Pressable>
                  </>
                )}
                <Pressable
                  onPress={() =>
                    navigation.push("Registrar nueva cuenta", { addAccount })
                  }
                >
                  <View
                    style={[
                      styles.mainButton,
                      { backgroundColor: c.themeColors.primaryHoverBg },
                    ]}
                  >
                    <Text style={txt.lg_strong}>Registrar nueva cuenta</Text>
                  </View>
                </Pressable>
              </View>
            </ScrollView>
          )}

          <RetireDetails2
            value={info?.value}
            checkInfo={checkInfo}
            visible={showRetiredModal}
            sendRetire={generateRetire}
            currencyFormat={currencyFormat}
            countrySymbol={user_country_symbol}
            bankName={selectBankAccount?.bankName}
            close={() => setShowRetiredModal(false)}
          />
          <Loader visible={loading} />
          {/* <RetireDetails
            value={info.value}
            navigation={navigation}
            isValidated={validation}
            retireAction={generateRetire}
            currencyFormat={currencyFormat}
            backAction={() => setRetired(false)}
            bankName={info?.bankAccount}
            user_country_symbol={user_country_symbol}
          /> */}
        </>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            margin: c.spacing.spacing_lg,
          }}
        >
          <Text style={[txt.lg_strong, { textAlign: "center" }]}>
            ¡Lo sentimos! No cuentas con los permisos necesarios para ver este
            contenido
          </Text>
        </View>
      )}
    </>
  );
};

export default Retire;

const styles = StyleSheet.create({
  desktopCardTitle: {
    fontSize: 18,
    fontWeight: c.fontWeight.fw_bold,
    color: c.backgrounds.bgContainer,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  balanceDesktop: {
    backgroundColor: c.desktopColors.desktopBase,
    alignItems: "center",
    textAlign: "center",
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
  desktopButton: {
    width: "100%",
    borderWidth: 1,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_xx_sm,
    borderColor: c.desktopColors.desktopBase,
    // paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  desktopCardAmount: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_bold,
    color: c.backgrounds.bgContainer,
  },
  rightContainer: {
    width: "30%",
    borderWidth: 1,
    padding: c.spacing.spacing_big,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  balanceCard: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  balanceTitle: {
    color: c.themeColors.primaryBase,
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
  },
  input: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  alert: {
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.error.errorHoverBg,
    marginTop: c.spacing.spacing_md,
  },
  alertText: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
    color: c.error.errorText,
  },

  screenButtonsContainer: {
    marginTop: c.spacing.spacing_md,
    flexDirection: "row",
  },
  screenButton: {
    width: 90,
    borderWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    marginRight: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_xx_sm,
    borderColor: c.themeColors.primaryBase,
    backgroundColor: c.themeColors.primaryBg,
  },
  cardButton: {
    width: "100%",
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    marginBottom: c.spacing.spacing_big,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    paddingVertical: c.spacing.spacing_xx_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
});
