import _ from "lodash";
import { useEffect, useState } from "react";

import { parseJwt } from "../../helpers/src";
import { checkProperty } from "../../helpers/src";
import { DashboardApi } from "../../api/src/dashboard";
import { ContactInfoApi } from "../../api/src/contact-info";

const useScheduled = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");
  const currencyFormat = Intl.NumberFormat("es-CO");

  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [newTxInfo, setnewTxInfo] = useState({});
  const [sortValue, setSortValue] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [ascendance, setAscendance] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newTxErrors, setNewTxErrors] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [headerOption, setHeaderOption] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const activeModal = (id) => {
    setSelected(id);
    setShowModal(true);
  };

  const handleChange = (field, value) => {
    setnewTxInfo((prev) => ({ ...prev, [field]: value }));
  };
  const handleError = (message, field) => {
    setNewTxErrors((prev) => ({ ...prev, [field]: message }));
  };
  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      setMerchantData({
        userCountrySymbol: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        userCountryCode: checkProperty(
          x?.contact?.attributes?.user_country_code
        ),
      });
    });
  }, []);
  useEffect(() => {
    DashboardApi.getMerchantFinancesData({
      hashId,
      from: "Mes",
      type: "Programados",
    }).then((x) =>
      setTransactions(
        checkProperty(
          x?.movements?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        )
      )
    );
  }, [transactions]);

  const headers = [
    {
      header: "Fecha de creación",
      action: () => setSortValue("created_at"),
    },
    {
      header: "Código",
      action: () => setSortValue("external_reference"),
    },
    {
      header: "Valor",
      action: () => setSortValue("amount"),
    },
    {
      header: "Descripción",
      action: () => setSortValue("description"),
    },
    {
      header: "Fecha límite",
      action: () => setSortValue("limit_date"),
    },
    {
      header: "Cobrador",
      action: () => setSortValue("payer_full_name"),
    },
    { header: "" },
  ];

  const sortingTable = (a, b) =>
    ascendance
      ? typeof a[sortValue] === "number"
        ? a[sortValue] - b[sortValue]
        : a[sortValue]?.localeCompare(b[sortValue])
      : typeof a[sortValue] === "number"
      ? b[sortValue] - a[sortValue]
      : b[sortValue]?.localeCompare(a[sortValue]);

  const validate = () => {
    Keyboard.dismiss();
    let valid = true;
    if (!newTxInfo?.description) {
      handleError("Este campo es requerido", "description");
      valid = false;
    }
    if (!newTxInfo?.value) {
      handleError("Este campo es requerido", "value");
      valid = false;
    } else if (newTxInfo?.value < 0) {
      handleError("Escribe un valor válido", "value");
      valid = false;
    }
    if (!newTxInfo?.clientName) {
      handleError("Escribe un nombre válido", "clientName");
      valid = false;
    }
    if (!newTxInfo?.clientPhone) {
      handleError("Este campo es requerido", "clientPhone");
      valid = false;
    } else if (newTxInfo?.clientPhone.length !== 10) {
      handleError("El número debe tener 10 digitos", "clientPhone");
    }
    if (tab === "programado") {
      if (newTxInfo?.scheduleFrequency === "Mensual") {
        if (!newTxInfo?.scheduleReminder) {
          handleErrorSchedule("Este campo es requerido", "scheduleReminder");
          valid = false;
        } else if (
          Number(newTxInfo?.scheduleReminder.replace(/\D/g, "")) > 32 ||
          Number(newTxInfo?.scheduleReminder.replace(/\D/g, "")) < 0
        ) {
          handleErrorSchedule("Ingresa una fecha válida", "scheduleReminder");
          valid = false;
        }
        if (!newTxInfo?.scheduleLimit) {
          handleErrorSchedule("Este campo es requerido", "scheduleLimit");
          valid = false;
        } else if (
          Number(newTxInfo?.scheduleLimit.replace(/\D/g, "")) > 32 ||
          Number(newTxInfo?.scheduleLimit.replace(/\D/g, "")) < 0
        ) {
          handleErrorSchedule("Ingresa una fecha válida", "scheduleLimit");
          valid = false;
        }
        if (!newTxInfo?.scheduleAmount) {
          handleErrorSchedule("Este campo es requerido", "scheduleAmount");
          valid = false;
        } else if (Number(newTxInfo?.scheduleAmount) < 0) {
          handleErrorSchedule("Escribe un valor válido", "scheduleAmount");
          valid = false;
        } else if (Number(newTxInfo?.scheduleAmount) > 12) {
          handleErrorSchedule("La cantidad máxima es 12", "scheduleAmount");
          valid = false;
        }
        if (!newTxInfo?.scheduleStart) {
          handleErrorSchedule("Escribe un nombre válido", "scheduleStart");
          valid = false;
        }
      } else if (newTxInfo?.scheduleFrequency === "Único") {
        if (!newTxInfo?.scheduleDate) {
          handleErrorSchedule("Este campo es requerido", "scheduleDate");
          valid = false;
        }
      }
    }
    valid && setShowConfirmation(true);
  };
  const modifyTx = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowEdit(false);
      setShowConfirmation(false);
    }, 3000);
  };

  return {
    headers,
    showEdit,
    selected,
    validate,
    modifyTx,
    showModal,
    ascendance,
    setShowEdit,
    activeModal,
    newTxErrors,
    handleChange,
    sortingTable,
    setShowModal,
    transactions,
    merchantData,
    headerOption,
    setAscendance,
    currencyFormat,
    setHeaderOption,
    showConfirmation,
    setShowConfirmation,
  };
};

export default useScheduled;
