import { StyleSheet, Text, View, Pressable } from "react-native";
import React from "react";
import { txt } from "../styles/typestyles";
import { c } from "../styles/constants";

const FrequencyButtons = ({
  handleChangeSchedule,
  scheduleFrequency,
  isDesktop,
}) => {
  const scheduleOptions = ["Único", "Mensual"];

  return (
    <View style={styles.buttonsContainer}>
      {scheduleOptions.map((op, i) => (
        <View
          key={i}
          style={[
            styles.periodButton,
            op === scheduleFrequency && {
              backgroundColor: isDesktop
                ? c.desktopColors.desktopBase
                : c.themeColors.primaryBg,
            },
          ]}
        >
          <Pressable
            onPress={() => handleChangeSchedule("scheduleFrequency", op)}
          >
            <Text
              style={[
                txt.base_strong,
                op === scheduleFrequency &&
                  isDesktop && { color: c.backgrounds.bgContainer },
              ]}
            >
              {op}
            </Text>
          </Pressable>
        </View>
      ))}
    </View>
  );
};

export default FrequencyButtons;

const styles = StyleSheet.create({
  periodButton: {
    width: "48%",
    paddingVertical: 6,
    textAlign: "center",
    borderRadius: c.borderRadius.br_xs,
    paddingHorizontal: c.spacing.spacing_sm,
  },
  buttonsContainer: {
    padding: 5,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderRadius: c.borderRadius.br_sm,
    backgroundColor: c.backgrounds.bgLayout,
  },
});
