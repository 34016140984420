import { StyleSheet, Text, View, Pressable } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import React from "react";

import Divider from "./Divider";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const RetireDetails = ({
  value,
  bankName,
  navigation,
  backAction,
  isValidated,
  retireAction,
  currencyFormat,
  user_country_symbol,
}) => {
  return (
    <View style={styles.successContainer}>
      <View style={{ alignItems: "center" }}>
        <Icon
          name={!isValidated ? "time" : "checkmark-circle"}
          size={48}
          color={
            !isValidated ? c.warning.warningBase : c.themeColors.primaryBase
          }
        />
        <Text style={[txt.xl_strong, { marginTop: c.spacing.spacing_sm }]}>
          {!isValidated
            ? "Revisa la información del retiro"
            : "¡Retiro exitoso!"}
        </Text>
      </View>
      <Divider />
      <View style={styles.summaryRow}>
        <Text style={txt.lg_strong}>Valor </Text>
        <Text style={txt.lg_normal}>
          {`${user_country_symbol} ${currencyFormat.format(value)}`}
        </Text>
      </View>
      <View style={styles.summaryRow}>
        <Text style={txt.lg_strong}>Medio de pago:</Text>
        <Text style={txt.lg_normal}>{bankName}</Text>
      </View>
      {isValidated && (
        <View style={styles.summaryRow}>
          <Text style={txt.lg_strong}>Tiempo:</Text>
          <Text style={txt.lg_normal}>1-2 horas hábiles</Text>
        </View>
      )}
      <Pressable onPress={!isValidated ? retireAction : backAction}>
        <View style={styles.mainButton}>
          <Text style={txt.lg_strong}>
            {!isValidated ? "Realizar retiro" : "Realizar otro retiro"}
          </Text>
        </View>
      </Pressable>
      <Pressable
        onPress={
          !isValidated ? backAction : () => navigation.navigate("Actividad")
        }
      >
        <View
          style={[
            styles.mainButton,
            { backgroundColor: c.themeColors.primaryBg },
          ]}
        >
          <Text style={txt.lg_strong}>
            {!isValidated ? "Corregir información" : "Ver mi actividad"}
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default RetireDetails;

const styles = StyleSheet.create({
  successContainer: {
    paddingVertical: c.spacing.spacing_big,
    paddingHorizontal: c.spacing.spacing_md,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
