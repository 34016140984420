import { CONTACTS_PHONE_QUERY, BASE_URL } from "../config/index";
import { getData } from "../api-call";
const getInfo = async ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${CONTACTS_PHONE_QUERY}${id}`;

  return getData(requestUrl);
};
export const ContactInfoPhoneApi = {
  getInfo,
};
