import moment from "moment/moment";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { Calendar, LocaleConfig } from "react-native-calendars";
import { StyleSheet, Text, View, Pressable, TextInput } from "react-native";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
import InputComponent from "./InputComponent";
import FrequencyButtons from "./FrequencyButtons";
import ChargeNow from "./ChargeNow";

const week = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
LocaleConfig.locales["es"] = {
  monthNames: months,
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Agos",
    "Sept",
    "Oct",
    "Nov",
    "Dic",
  ],
  dayNames: week,
  dayNamesShort: ["Lun", "Mar", "Mier", "Jue", "Vie", "Sab", "Dom"],
  today: "Hoy",
};
LocaleConfig.defaultLocale = "es";
const ScheduleCharge = ({
  errors,
  txInfo,
  isDesktop,
  handleChange,
  scheduleErrors,
  scheduleFrequency,
  handleChangeSchedule,
}) => {
  const [start, setStart] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [amount, setAmount] = useState(0);

  const startOptions = ["Este mes", "Próximo mes"];

  const today = moment().format("YYYY-MM-DD").toString();
  const limitDay = moment().add(45, "days").format("YYYY-MM-DD").toString();

  const reduceAmount = () => {
    if (amount > 0) {
      setAmount(amount - 1);
      handleChangeSchedule("scheduleAmount", amount - 1);
    }
  };
  const increaseAmount = () => {
    if (amount < 12) {
      setAmount(amount + 1);
      handleChangeSchedule("scheduleAmount", amount + 1);
    }
  };
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  return (
    <>
      {isDesktop ? (
        <>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={{ width: "37%" }}>
              <InputComponent
                isNumeric
                error={errors.value}
                placeholder="$12.345"
                label="Valor del cobro"
                onChangeText={(val) => handleChange("value", val)}
                value={txInfo.amount}
              />
            </View>
            <View style={{ width: "60%" }}>
              <Text style={styles.label}>Frecuencia</Text>
              <FrequencyButtons
                handleChangeSchedule={handleChangeSchedule}
                scheduleFrequency={scheduleFrequency}
                isDesktop={isDesktop}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "48%" }}>
              <InputComponent
                placeholder="Camiseta negra"
                value={txInfo.description}
                label="Motivo del cobro"
                onChangeText={(val) => handleChange("description", val)}
                error={errors.description}
              />
            </View>
            <View style={{ width: "48%" }}>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.label}>Referencia</Text>
                <Icon
                  name="help-circle-outline"
                  size={16}
                  color={c.textIcons.icon}
                />
              </View>
              <InputComponent
                placeholder="211-111"
                onChangeText={(val) => handleChange("reference", val)}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "48%" }}>
              <InputComponent
                isNumeric
                placeholder="311 222 3333"
                error={errors.clientPhone}
                value={txInfo.clientPhone}
                label="Número de WhatsApp del cliente"
                onChangeText={(val) => handleChange("clientPhone", val)}
              />
            </View>
            <View style={{ width: "48%" }}>
              <InputComponent
                label="Nombre del cliente"
                error={errors.clientName}
                value={txInfo.clientName}
                placeholder="Christian Muñoz"
                onChangeText={(val) => handleChange("clientName", val)}
              />
            </View>
          </View>
        </>
      ) : (
        <>
          <ChargeNow errors={errors} handleChange={handleChange} />
          <Text style={styles.label}>Frecuencia</Text>
          <FrequencyButtons
            handleChangeSchedule={handleChangeSchedule}
            scheduleFrequency={scheduleFrequency}
          />
        </>
      )}
      {scheduleFrequency === "Mensual" && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: "48%" }}>
            <Text style={styles.label}>Recordatorio</Text>
            <View style={[styles.dateInput]}>
              <TextInput
                maxLength={2}
                placeholder="5"
                keyboardType="numeric"
                value={txInfo.scheduleReminder}
                style={{ width: "50%", minWidth: 20 }}
                placeholderTextColor={c.textIcons.placeholder}
                onChangeText={(val) =>
                  handleChangeSchedule("scheduleReminder", val)
                }
              />
              <Text>de cada mes</Text>
            </View>
            {scheduleErrors.scheduleReminder && (
              <Text style={[txt.sm_strong, styles.errorText]}>
                {scheduleErrors.scheduleReminder}
              </Text>
            )}
          </View>
          <View style={{ width: "48%" }}>
            <Text style={styles.label}>Fecha límite</Text>
            <View style={[styles.dateInput]}>
              <TextInput
                maxLength={2}
                placeholder="5"
                keyboardType="numeric"
                value={txInfo.scheduleLimit}
                style={{ width: "50%", minWidth: 20 }}
                placeholderTextColor={c.textIcons.placeholder}
                onChangeText={(val) =>
                  handleChangeSchedule("scheduleLimit", val)
                }
              />
              <Text>de cada mes</Text>
            </View>
            {scheduleErrors.scheduleLimit && (
              <Text style={[txt.sm_strong, styles.errorText]}>
                {scheduleErrors.scheduleLimit}
              </Text>
            )}
          </View>
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {scheduleFrequency === "Mensual" && (
          <View style={{ width: "48%" }}>
            {isDesktop ? (
              <>
                <Text style={styles.label}>Número de cobros</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Pressable onPress={reduceAmount}>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: c.bordersDividers.border,
                        paddingVertical: 12,
                        paddingHorizontal: c.spacing.spacing_md,
                        borderTopLeftRadius: c.borderRadius.br_base,
                        borderBottomLeftRadius: c.borderRadius.br_base,
                      }}
                    >
                      <Text>-</Text>
                    </View>
                  </Pressable>
                  <View
                    style={{
                      paddingVertical: 12,
                      paddingHorizontal: c.spacing.spacing_md,
                      borderWidth: 1,
                      borderColor: c.bordersDividers.border,
                      width: "50%",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ textAlign: "center" }}>{amount}</Text>
                  </View>
                  <Pressable onPress={increaseAmount}>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: c.bordersDividers.border,
                        paddingVertical: 12,
                        paddingHorizontal: c.spacing.spacing_md,
                        borderTopRightRadius: c.borderRadius.br_base,
                        borderBottomRightRadius: c.borderRadius.br_base,
                      }}
                    >
                      <Text>+</Text>
                    </View>
                  </Pressable>
                </View>
                {scheduleErrors.scheduleAmount && (
                  <Text style={[txt.sm_strong, styles.errorText]}>
                    {scheduleErrors.scheduleAmount}
                  </Text>
                )}
              </>
            ) : (
              <InputComponent
                isNumeric
                label="Número de cobros"
                value={txInfo.scheduleAmount}

                onChangeText={(val) =>
                  handleChangeSchedule("scheduleAmount", val)
                }
                error={scheduleErrors.scheduleAmount}
              />
            )}
          </View>
        )}
        {scheduleFrequency && (
          <>
            <View
              style={{
                width: scheduleFrequency === "Mensual" ? "48%" : "100%",
              }}
            >
              <Text style={styles.label}>Inicio del cobro</Text>
              <Pressable onPress={toggleDropdown}>
                <View style={[styles.dateInput]}>
                  <Text>
                    {scheduleFrequency === "Mensual" ? start : scheduleDate}
                  </Text>
                  <Icon
                    name={
                      showDropdown ? "chevron-up-circle" : "chevron-down-circle"
                    }
                    color={c.textIcons.icon}
                    size={18}
                  />
                </View>
              </Pressable>

              {showDropdown && (
                <View style={styles.dropdownContianer}>
                  {scheduleFrequency === "Mensual" ? (
                    <>
                      {startOptions.map((s, i) => (
                        <Pressable
                          key={i}
                          onPress={() => {
                            setStart(s);
                            toggleDropdown();
                            handleChangeSchedule("scheduleStart", s);
                          }}
                        >
                          <Text
                            style={[
                              txt.base_strong,
                              { marginTop: c.spacing.spacing_xx_sm },
                            ]}
                          >
                            {s}
                          </Text>
                        </Pressable>
                      ))}
                    </>
                  ) : (
                    <Calendar
                      style={{ borderRadius: 10 }}
                      onDayPress={(day) => {
                        setScheduleDate(day.dateString);
                        handleChangeSchedule("scheduleDate", day.dateString);
                        setShowDropdown(false);
                      }}
                      disableAllTouchEventsForDisabledDays={true}
                      minDate={today}
                      maxDate={limitDay}
                      renderArrow={(direction) =>
                        direction === "left" ? (
                          <Icon
                            name="chevron-back-circle"
                            size={25}
                            color={c.themeColors.primaryBase}
                          />
                        ) : (
                          <Icon
                            name="chevron-forward-circle"
                            size={25}
                            color={c.themeColors.primaryBase}
                          />
                        )
                      }
                    />
                  )}
                </View>
              )}
              {scheduleErrors.scheduleStart && (
                <Text style={[txt.sm_strong, styles.errorText]}>
                  {scheduleErrors.scheduleStart}
                </Text>
              )}
            </View>
          </>
        )}
      </View>
    </>
  );
};

export default ScheduleCharge;

const styles = StyleSheet.create({
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  dateInput: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },

  dropdownContianer: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
});
