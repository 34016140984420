import { ActivityIndicator, Image, StyleSheet, Text, View } from "react-native";
import { useWindowDimensions } from "react-native";
import React from "react";
import { c } from "../styles/constants";

const ValidationLoader = ({ visible }) => {
  const { height, width } = useWindowDimensions();

  return (
    visible && (
      <View style={[styles.container, { height, width }]}>
        <ActivityIndicator size="large" color={c.themeColors.primaryBase} />
        <View style={styles.textContainer}>
          <Text style={styles.loadingTitle}>
            Estamos validando tu identidad
          </Text>
          <Text style={{ color: c.textIcons.description }}>
            Recuerda completar el proceso para poder continuar con tu retiro.
          </Text>
        </View>
        <Image
          source={require("../assets/qz-logo-black.png")}
          resizeMode="contain"
          style={{ width: 80, height: 22 }}
        />
      </View>
    )
  );
};

export default ValidationLoader;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    zIndex: 10,
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgLayout,
  },
  loadingTitle: {
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
  },
  textContainer: {
    width: "80%",
    textAlign: "center",
    marginVertical: c.spacing.spacing_x_big,
  },
});
