import {
  Text,
  View,
  Modal,
  Pressable,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import { c } from "../../styles/constants";
import Divider from "../Divider";
import { txt } from "../../styles/typestyles";
const RetireDetails2 = ({
  value,
  close,
  visible,
  bankName,
  checkInfo,
  sendRetire,
  countrySymbol,
  currencyFormat,
}) => {
  const { height } = useWindowDimensions();

  return (
    <Modal transparent visible={visible} onRequestClose={close}>
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          height,
          // flex: 1,
          // justifyContent: "center",
        }}
      >
        <View style={[styles.modalCard, { height }]}>
          <ScrollView>
            <View
              style={{
                paddingHorizontal: c.spacing.spacing_md,
              }}
            >
              <Pressable onPress={close}>
                <View style={styles.backButton_icon}>
                  <Icon
                    name="arrow-back-circle-outline"
                    size={16}
                    color={c.textIcons.icon}
                  />
                  <Text style={[txt.sm_normal, styles.backButton_txt]}>
                    Regresar
                  </Text>
                </View>
              </Pressable>
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Icon
                  name={!checkInfo ? "time" : "checkmark-circle"}
                  size={46}
                  color={
                    !checkInfo
                      ? c.warning.warningBase
                      : c.desktopColors.desktopBase
                  }
                />
                <Text
                  style={[
                    txt.xl_strong,
                    { marginTop: c.spacing.spacing_xx_sm },
                  ]}
                >
                  {checkInfo
                    ? "¡Retiro exitoso!"
                    : "Verifica la información del retiro"}
                </Text>
              </View>
              <Divider />

              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Valor </Text>
                <Text style={txt.lg_normal}>
                  {`${countrySymbol} ${currencyFormat.format(value)}`}
                </Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Cuenta destino:</Text>
                <Text style={txt.lg_normal}>{bankName}</Text>
              </View>
              {checkInfo && (
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Tiempo:</Text>
                  <Text style={txt.lg_normal}>4-8 horas hábiles</Text>
                </View>
              )}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: !checkInfo ? "47%" : "100%" }}>
                  <Pressable onPress={checkInfo ? close : sendRetire}>
                    <View style={[styles.mainButton]}>
                      <Text style={[{ color: c.backgrounds.bgContainer }]}>
                        {checkInfo ? "Salir" : "Realizar retiro"}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                {!checkInfo && (
                  <View style={{ width: "47%" }}>
                    <Pressable onPress={close}>
                      <View
                        style={[
                          styles.mainButton,
                          { backgroundColor: c.desktopColors.desktopBg },
                        ]}
                      >
                        <Text style={[{ color: c.desktopColors.desktopBase }]}>
                          Modificar información
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
              </View>
            </View>
            <View
              style={{ marginTop: c.spacing.spacing_md, alignSelf: "center" }}
            >
              <Pressable>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <MCI
                    name="message-question-outline"
                    size={14}
                    color={c.textIcons.icon}
                  />
                  <Text
                    style={[
                      txt.sm_normal,
                      {
                        color: c.textIcons.description,
                        marginLeft: c.spacing.spacing_sm,
                      },
                    ]}
                  >
                    Reportar un problema
                  </Text>
                </View>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default RetireDetails2;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingVertical: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    borderWidth: 1,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: c.spacing.spacing_xx_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
});
