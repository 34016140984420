import axios from "axios";

export function setup() {
  const baseURL = "/";
  const apiClient = axios.create({
    baseURL,
    // headers: {
    //   "x-auth-token":
    //     "e7d653881b156cf67597d6812eded6f740770197779b8229fd94ef6e6002f097467d721dd5523d692a047846ca568ef7a38f554d32671e56091dd5488729b551b2f811eee5b0f4ba3bfe7b1b044a23bb449b818782fbca2e915bdfa7b21614cf22ed20bc99d8338d2c24f7325d00930706bbe42c54be535505a81f5ad62b986f",
    // },
    
  });
  return { apiClient };
}
const {
  apiClient: { get, post, put, patch, delete: destroy },
} = setup();

export { get, post, put, patch, destroy };
