import {
  Linking,
  StyleSheet,
  Text,
  View,
  Pressable,
  Image,
} from "react-native";
import React from "react";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const EmptyContent = () => {
  return (
    <View style={styles.messageContainer}>
      <Text style={txt.heading3}>
        ¡Estamos trabajando para ofrecerte lo mejor!
      </Text>
      <View style={{ padding: c.spacing.spacing_big }}>
        <Image
          source={require("../assets/bot.png")}
          resizeMode="contain"
          style={{ width: 220, height: 214 }}
        />
      </View>
      <Text style={styles.caption}>
        Próximamente encontrarás información de valor sobre tu negocio, por
        ejemplo: los días con mayores cobros, otras estadísticas y mucho más.
        ¡Sigue realizando movimientos para que puedas acceder a esta página tan
        pronto esté lista!
      </Text>
      <Pressable onPress={() => Linking.openURL("https://tally.so/r/3j6My6")}>
        <View style={styles.mainButton}>
          <Text style={txt.lg_strong}>¡Cuéntanos tus ideas!</Text>
        </View>
      </Pressable>
    </View>
  );
};

export default EmptyContent;

const styles = StyleSheet.create({
  messageContainer: {
    textAlign: "center",
    alignItems: "center",
    paddingHorizontal: c.spacing.spacing_big,
  },
  caption: {
    fontSize: c.fontSize.fs_md,
    lineHeight: c.lineHeight.lh3,
    color: c.textIcons.description,
    marginVertical: c.spacing.spacing_big,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  buttonTitle: {
    fontSize: c.fontSize.fs_md,
    color: c.themeColors.primaryText,
    fontWeight: c.fontWeight.fw_medium,
  },
});
