import {
  Text,
  View,
  Modal,
  Pressable,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import React from "react";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
const ConfirmScheduledModification = ({
  visible,
  close,
  loading,
  modifyTx,
}) => {
  return (
    <Modal
      transparent
      visible={visible}
      animationType="fade"
      onRequestClose={close}
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={[styles.modalCard]}>
          <Text style={[txt.base_strong, { textAlign: "center" }]}>
            ¿Deseas confirmar la modificación de este cobro programado?
          </Text>
          {loading && (
            <View
              style={{
                marginVertical: c.spacing.spacing_md,
                alignSelf: "center",
              }}
            >
              <ActivityIndicator
                color={c.desktopColors.desktopBase}
                size="large"
              />
            </View>
          )}
          <View
            style={{
              marginTop: c.spacing.spacing_big,
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <View style={{ width: "40%" }}>
              <Pressable onPress={close}>
                <View
                  style={[
                    styles.mainButton,
                    {
                      backgroundColor: c.error.errorBase,
                      borderColor: c.error.errorBase,
                    },
                  ]}
                >
                  <Text style={{ color: c.backgrounds.bgContainer }}>
                    No, cancelar
                  </Text>
                </View>
              </Pressable>
            </View>
            <View style={{ width: "40%" }}>
              <Pressable onPress={modifyTx}>
                <View style={[styles.mainButton]}>
                  <Text style={{ color: c.backgrounds.bgContainer }}>
                    Si, confirmar
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmScheduledModification;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "center",
    padding: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    borderWidth: 2,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
