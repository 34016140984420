import React from "react";
import { ScrollView, StyleSheet, Text, View, Pressable } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import EmptyContent from "../../Components/EmptyContent";

const Analysis = () => {
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <EmptyContent />
        <Pressable>
          <View style={styles.mainButton}>
            <Icon name="cloud-download" size={25} />
            <Text style={[txt.lg_strong, { marginLeft: c.spacing.spacing_md }]}>
              Descargar mi reporte
            </Text>
          </View>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default Analysis;

const styles = StyleSheet.create({
  mainButton: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
