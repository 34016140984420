import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import More from "../Screens/More/More";
import AppHeader from "./AppHeader/AppHeader";
import Profile from "../Screens/Profile/Profile";
import Learn from "../Screens/Learn/Learn";
import Referrals from "../Screens/Referrals/Referrals";
import AdquirePlan from "../Screens/AdquirePlan/AdquirePlan";
import Customize from "../Screens/Customize/Customize";
const Stack = createStackNavigator();
const MenuNavigator = () => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={() => ({
        header: ({ navigation, route }) => (
          <AppHeader navigation={navigation} title={route.name} />
        ),
      })}
    >
      <Stack.Screen name="Mas" component={More} />
      <Stack.Screen name="Mi perfil" component={Profile} />
   <Stack.Screen name="¿Cómo funciona Qentaz?"component={Learn}/>
   <Stack.Screen name ="Refiere a alguien"component={Referrals}/>
   <Stack.Screen name ="Adquirir Plan Pro" component={AdquirePlan}/>
   <Stack.Screen name ="Personalizar mis cobros" component={Customize}/>
    </Stack.Navigator>
  );
};

export default MenuNavigator;
