import {
  StyleSheet,
  Text,
  View,
  Pressable,
  useWindowDimensions,
  TextInput,
} from "react-native";
import React from "react";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import useOTP from "./useOTP";
import ValidationLoader from "../../Components/ValidationLoader";

const OTP = ({ navigation, route }) => {
  const { phone } = route.params;
  const { height } = useWindowDimensions();
  const { error, setCode, validate, load } = useOTP({
    navigation,
    phone,
  });

  return (
    <>
      {load && <ValidationLoader visible={load} />}
      <View>
        <View
          style={{
            maxWidth: 500,
            alignSelf: "center",
            textAlign: "center",
            height: height - "20%",
            paddingVertical: c.spacing.spacing_x_lg,
            paddingHorizontal: c.spacing.spacing_big,
          }}
        >
          <Text style={txt.heading3}>Inicia sesión con tu teléfono</Text>
          <Text style={{ marginVertical: c.spacing.spacing_x_big }}>
            Por favor ingresa el código de 6 dígitos que enviamos a tu WhatsApp.{" "}
            <Pressable>
              <Text
                style={{
                  textDecorationLine: c.textDecotation.td_underline,
                  lineHeight: c.lineHeight.lh0,
                }}
              >
                Solicitar código.
              </Text>
            </Pressable>
          </Text>

          <View style={styles.input}>
            <TextInput
              maxLength={6}
              placeholder={"* * * * * *"}
              //   onFocus={() => {
              //     setIsFocused(true);
              //   }}
              //   onBlur={() => {
              //     setIsFocused(false);
              //   }}
              textAlign="center"
              keyboardType="numeric"
              secureTextEntry={true}
              onChangeText={(val) => setCode(val)}
              placeholderTextColor={c.textIcons.placeholder}
            />
          </View>
          {error !== "" && (
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}>{error}</Text>
            </View>
          )}
          <Pressable onPress={validate}>
            <View style={styles.mainButton}>
              <Text
                style={[txt.lg_strong, { color: c.backgrounds.bgContainer }]}
              >
                Ingresar
              </Text>
            </View>
          </Pressable>
        </View>
        <View style={styles.problemButtonContainer}>
          <MCI
            name="message-question-outline"
            size={14}
            color={c.textIcons.icon}
          />
          <Text
            style={[
              txt.sm_normal,
              {
                color: c.textIcons.icon,
                marginLeft: c.spacing.spacing_xx_sm,
              },
            ]}
          >
            Reportar un problema
          </Text>
        </View>
      </View>
    </>
  );
};

export default OTP;

const styles = StyleSheet.create({
  countryCodeButton: {
    borderWidth: 1,
    backgroundColor: "#EFEDF1",
    marginRight: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderColor: c.bordersDividers.borderSecondary,
  },
  errorContainer: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_x_big,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xx_sm,
  },
  errorText: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
    color: c.error.errorText,
  },
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
    marginTop: c.spacing.spacing_xx_big,
  },
  problemButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: c.spacing.spacing_sm,
  },
});
