import React from "react";
import moment from "moment";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";

import Divider from "../Divider";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";

const TransactionDetails = ({
  close,
  txCode,
  txDate,
  visible,
  txValue,
  txTitle,
  txStatus,
  bankName,
  isDesktop,
  bankNumber,
  serviceFee,
  txLimitDate,
  txClientName,
  txClientPhone,
  paymentMethod,
  currencyFormat,
  handleDownload,
  countryCurrency,
  comprobantAction,
}) => {
  const { width, height } = useWindowDimensions();

  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={close}
    >
      <View
        style={[
          { backgroundColor: "rgba(0,0,0,0.25)" },
          { flex: 1, justifyContent: "center" },
        ]}
      >
        <View
          style={isDesktop ? [styles.desktopContainer] : styles.cardContainer}
        >
          <View style={styles.contentContainer}>
            <Pressable onPress={close}>
              <View style={styles.backButton_icon}>
                <Icon
                  name="arrow-back-circle-outline"
                  size={16}
                  color={c.textIcons.icon}
                />
                <Text
                  style={[txt.sm_normal, { color: c.textIcons.description }]}
                >
                  Regresar
                </Text>
              </View>
            </Pressable>
            <View style={{ alignItems: "center" }}>
              <Icon
                name={
                  txStatus === "Pendiente"
                    ? "alert-circle"
                    : txStatus === "Exitosa"
                    ? "checkmark-circle"
                    : txStatus === "Rechazada"
                    ? "close-circle"
                    : txStatus === "Programada"
                    ? "time"
                    : "remove-circle"
                }
                size={48}
                color={
                  txStatus === "Pendiente"
                    ? c.warning.warningBase
                    : txStatus === "Exitosa"
                    ? c.desktopColors.desktopBase
                    : txStatus === "Rechazada"
                    ? c.error.errorBase
                    : txStatus === "Programada"
                    ? c.info.infoBase
                    : c.textIcons.icon
                }
              />
              <Text
                style={[txt.xl_strong, { marginTop: c.spacing.spacing_sm }]}
              >
                ¡Transacción {txStatus.toLowerCase()}!
              </Text>
              {txStatus === "Programada" ? (
                <>
                  <Text
                    style={[
                      txt.sm_normal,
                      {
                        color: c.textIcons.icon,
                        marginTop: c.spacing.spacing_sm,
                      },
                    ]}
                  >
                    {txDate}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: c.spacing.spacing_sm,
                    }}
                  >
                    <Text> Fecha límite: </Text>
                    <Text
                      style={[
                        txt.sm_normal,
                        { color: c.textIcons.description },
                      ]}
                    >
                      {txLimitDate}
                    </Text>
                  </View>
                </>
              ) : (
                <Text
                  style={[
                    txt.sm_normal,
                    {
                      color: c.textIcons.icon,
                      marginTop: c.spacing.spacing_sm,
                    },
                  ]}
                >
                  {txDate}
                </Text>
              )}
            </View>
            <Divider />
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Valor:</Text>
              <Text style={txt.lg_normal}>
                {`${countryCurrency} ${currencyFormat.format(txValue)}`}
              </Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Descripción:</Text>
              {bankName ? (
                <Text
                  style={[
                    txt.lg_normal,
                    { maxWidth: !isDesktop && 180, textAlign: "right" },
                  ]}
                >
                  {`Retiro de ${bankName} (${bankNumber})`}
                </Text>
              ) : (
                <Text
                  style={[
                    txt.lg_normal,
                    { maxWidth: !isDesktop && 180, textAlign: "right" },
                  ]}
                >
                  {txTitle}
                </Text>
              )}
            </View>
            {!bankName && (
              <>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Cliente:</Text>
                  <Text
                    style={[
                      txt.lg_normal,
                      { maxWidth: !isDesktop && 180, textAlign: "right" },
                    ]}
                  >
                    {txClientName}
                  </Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Telefono del cliente:</Text>
                  <Text style={txt.lg_normal}>{txClientPhone}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Código de transacción:</Text>
                  <Text style={txt.lg_normal}>{txCode}</Text>
                </View>
              </>
            )}
            {txStatus === "Exitosa" && (
              <>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Medio de pago:</Text>
                  <Text style={txt.lg_normal}>{paymentMethod}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={txt.lg_strong}>Comisión :</Text>
                  <Text style={txt.lg_normal}>
                    {`${countryCurrency} ${currencyFormat.format(serviceFee)}`}
                  </Text>
                </View>

                <Pressable
                  onPress={() => {
                    close();
                    handleDownload(txCode);
                  }}
                >
                  <View style={styles.mainButton}>
                    <Text
                      style={[
                        txt.lg_strong,
                        { color: isDesktop && c.backgrounds.bgContainer },
                      ]}
                    >
                      Descargar comprobante
                    </Text>
                  </View>
                </Pressable>
              </>
            )}
          </View>
          <View style={styles.problemButtonContainer}>
            <MCI
              name="message-question-outline"
              size={14}
              color={c.textIcons.icon}
            />
            <Text
              style={[
                txt.sm_normal,
                {
                  color: c.textIcons.icon,
                  marginLeft: c.spacing.spacing_sm,
                },
              ]}
            >
              Reportar un problema
            </Text>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default TransactionDetails;

const styles = StyleSheet.create({
  desktopContainer: {
    width: 500,
    borderRadius: 12,
    alignSelf: "center",
    justifyContent: "center",
    paddingBottom: c.spacing.spacing_md,
    marginVertical: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  cardContainer: {
    width: "100%",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    position: "absolute",
    bottom: 0,
    backgroundColor: c.backgrounds.bgContainer,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: c.spacing.spacing_sm,
  },
  contentContainer: { paddingHorizontal: c.spacing.spacing_md },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    marginBottom: c.spacing.spacing_big,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
  problemButtonContainer: {
    marginVertical: c.spacing.spacing_sm,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    width: 50,
    height: 3,
    alignSelf: "center",
    borderRadius: c.borderRadius.br_xs,
    marginVertical: c.spacing.spacing_sm,
    backgroundColor: c.textIcons.disabled,
  },
});
