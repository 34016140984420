import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Pay from "../../Screens/Pay/Pay";
import Users from "../../Screens/Users/Users";
import AppHeader from "../AppHeader/AppHeader";
import Retire from "../../Screens/Retire/Retire";
import Activity from "../../Screens/Activity/Activity";
import NewAccount from "../../Screens/NewAccount/NewAccount";
const Stack = createStackNavigator();
const ActivityNavigator = () => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={() => ({
        header: ({ navigation, route }) => (
          <AppHeader navigation={navigation} title={route.name} />
        ),
      })}
    >
      <Stack.Screen name="Actividad" component={Activity} />
      <Stack.Screen name="Realizar pago" component={Pay} />
      <Stack.Screen name="Usuarios" component={Users} />
      <Stack.Screen name="Realizar retiro" component={Retire} />
      <Stack.Screen name="Registrar nueva cuenta" component={NewAccount} />
    </Stack.Navigator>
  );
};

export default ActivityNavigator;
