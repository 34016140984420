import React, { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
import ConfirmDeleteUser from "./Modal/ConfirmDeleteUser";

const UsersList = ({ name, phone, role, isEditable }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <View style={styles.cardContainer}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontWeight: c.fontWeight.fw_medium }}>{name}</Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={styles.roleTitle}>{role}</Text>
            {isEditable && (
              <Pressable onPress={() => setShowModal(true)}>
                <Icon
                  name="close-circle"
                  size={18}
                  color={c.error.errorBase}
                  style={{ marginLeft: c.spacing.spacing_md }}
                />
              </Pressable>
            )}
          </View>
        </View>
        <Text
          style={[
            txt.sm_normal,
            {
              marginTop: c.spacing.spacing_xxx_sm,
              color: c.textIcons.description,
            },
          ]}
        >
          {phone}
        </Text>
      </View>
      <ConfirmDeleteUser
        visible={showModal}
        close={() => setShowModal(false)}
      />
    </>
  );
};

export default UsersList;

const styles = StyleSheet.create({
  cardContainer: {
    borderWidth: 1,
    marginBottom: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  roleTitle: {
    fontSize: c.fontSize.fs_md,
    color: c.themeColors.primaryBase,
    fontWeight: c.fontWeight.fw_medium,
  },
});
