import {
  Text,
  View,
  Pressable,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import _ from "lodash";
import React from "react";
import { c } from "../styles/constants";
import Pagination from "./Pagination";
import StatusBadge from "./StatusBadge";

const RetireTable = ({
  name,
  headers,
  ascendance,
  transactions,
  sortingTable,
  headerOption,
  setAscendance,
  currencyFormat,
  setHeaderOption,
  userCountrySymbol,
}) => {
  const { height } = useWindowDimensions();

  return _.isEmpty(transactions) ? (
    <View
      style={{
        height: height / 2,
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
        No tienes retiros en estos momentos.
      </Text>
      <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
        ¡Es un buen momento para programar realizar uno!
      </Text>
    </View>
  ) : (
    <View>
      <View style={{ flexDirection: "row" }}>
        {headers.map((h, i) => (
          <Pressable
            key={i}
            onPress={() => {
              h.action();
              setHeaderOption(i);
              setAscendance();
            }}
          >
            <View
              style={[
                styles.cell,
                {
                  backgroundColor: c.backgrounds.bgLayout,
                },
                i === 0 && {
                  borderTopLeftRadius: c.borderRadius.br_base,
                  borderLeftColor: c.bordersDividers.border,
                },
                i === headers.length - 1 && {
                  borderTopRightRadius: c.borderRadius.br_base,
                  borderRightColor: c.bordersDividers.border,
                },
                (h.header === "Valor" || h.header === "Fecha") && {
                  width: 120,
                },
                h.header === "Estado" && { width: 150 },
              ]}
            >
              <Text style={{ color: c.textIcons.icon }}>{h.header}</Text>
              {i == headerOption && (
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Icon
                    name={ascendance ? "chevron-down" : "chevron-up"}
                    color={c.textIcons.icon}
                    size={16}
                  />
                </View>
              )}
            </View>
          </Pressable>
        ))}
      </View>
      <View style={{ maxHeight: 550 }}>
        <ScrollView>
          {transactions
            ?.sort((a, b) => sortingTable(a, b))
            ?.map((tx, i) => (
              <>
                <View key={i} style={{ flexDirection: "row" }}>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderLeftColor: c.bordersDividers.border,
                      },
                    ]}
                  >
                    <Text numberOfLines={1}>{name}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 120,
                      },
                    ]}
                  >
                    <Text>{`${userCountrySymbol} ${currencyFormat.format(
                      tx.amount
                    )}`}</Text>
                  </View>

                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                      },
                    ]}
                  >
                    <Text numberOfLines={1}>{`${tx.name_bank}  ${
                      "*******" + tx?.number?.slice(-3)
                    }`}</Text>
                  </View>

                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 120,
                      },
                    ]}
                  >
                    <Text>
                      {new Date(tx.created_at).toLocaleDateString("es-CO", {
                        month: "short",
                        day: "numeric",
                      })}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderRightColor: c.bordersDividers.border,
                        width: 150,
                      },
                    ]}
                  >
                    <StatusBadge txStatus={tx.status} />
                  </View>
                </View>               
              </>
            ))}
        </ScrollView>
      </View>
    </View>
  );
};

export default RetireTable;

const styles = StyleSheet.create({
  cell: {
    height: 40,
    width: 180,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    paddingVertical: c.spacing.spacing_md,
    borderTopColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    borderBottomColor: c.bordersDividers.border,
  },
  moreButton: {
    width: 95,
    paddingHorizontal: 17,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.desktopColors.desktopBase,
    textAlign: "center",
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableButton: {
    paddingVertical: 6,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_base,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
