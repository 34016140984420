import {
  StyleSheet,
  Text,
  View,
  Pressable,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import _ from "lodash";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";

import { c } from "../../styles/constants";
import useScheduled from "./useScheduled";
import { txt } from "../../styles/typestyles";
import Pagination from "../../Components/Pagination";
import ScheduledDetails from "../../Components/Modal/ScheduledDetails";
import EditScheduled from "../../Components/Modal/EditScheduled";
import ConfirmScheduledModification from "../../Components/Modal/ConfirmScheduledModification";

const Scheduled = ({ navigation }) => {
  const height = useWindowDimensions().height;
  const {
    headers,
    showEdit,
    selected,
    validate,
    modifyTx,
    showModal,
    ascendance,
    newTxErrors,
    setShowEdit,
    activeModal,
    handleChange,
    setShowModal,
    headerOption,
    sortingTable,
    transactions,
    setAscendance,
    currencyFormat,
    setHeaderOption,
    showConfirmation,
    setShowConfirmation,
    merchantData: { userCountrySymbol, userCountryCode },
  } = useScheduled();
  // console.log(transactions);
  return (
    <View style={{ padding: "2%" }}>
      <View style={styles.spacedRow}>
        <Text style={{ fontSize: c.fontSize.fs_huge }}>Cobros programados</Text>
        <Pressable onPress={() => navigation.navigate("Cobrar")}>
          <View style={styles.tableButton}>
            <Text
              style={{
                color: c.backgrounds.bgContainer,
                marginRight: c.spacing.spacing_sm,
              }}
            >
              Generar un nuevo cobro
            </Text>
            <Icon name="calendar" size={20} color={c.backgrounds.bgContainer} />
          </View>
        </Pressable>
      </View>
      {_.isEmpty(transactions) ? (
        <View
          style={{
            height: height / 2,
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
            No tienes cobros programados en estos momentos.
          </Text>
          <Text style={{ fontSize: c.fontSize.fs_big, textAlign: "center" }}>
            ¡Es un buen momento para programar uno!
          </Text>
        </View>
      ) : (
        <ScrollView horizontal>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <View style={{ flexDirection: "row" }}>
              {headers.map((h, i) => (
                <Pressable
                  key={i}
                  onPress={() => {
                    h.action();
                    setHeaderOption(i);
                    setAscendance(!ascendance);
                  }}
                >
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgLayout,
                      },
                      i === 0 && {
                        borderTopLeftRadius: c.borderRadius.br_base,
                        borderLeftColor: c.bordersDividers.border,
                      },
                      i === headers.length - 1 && {
                        borderTopRightRadius: c.borderRadius.br_base,
                        borderRightColor: c.bordersDividers.border,
                      },
                      h.header === "Valor" && { width: 110 },
                      h.header === "Descripción" && { width: 210 },
                      h.header === "Código" && { width: 90 },
                      (h.header === "" || h.header === "Fecha límite") && {
                        width: 135,
                      },
                    ]}
                  >
                    <Text style={{ color: c.textIcons.icon }}>{h.header}</Text>
                    {i === headerOption && (
                      <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                        <Icon
                          name={ascendance ? "chevron-down" : "chevron-up"}
                          color={c.textIcons.icon}
                          size={16}
                        />
                      </View>
                    )}
                  </View>
                </Pressable>
              ))}
            </View>
            <View style={{ maxHeight: 550 }}>
              <ScrollView>
                {transactions
                  ?.sort((a, b) => sortingTable(a, b))
                  ?.map((tx, i) => (
                    <>
                      <View key={i} style={{ flexDirection: "row" }}>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              borderLeftColor: c.bordersDividers.border,
                            },
                          ]}
                        >
                          <Text>{tx.created_at}</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              width: 90,
                            },
                          ]}
                        >
                          <Text>{tx.external_reference}</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              width: 110,
                            },
                          ]}
                        >
                          <Text>{`${userCountrySymbol} ${currencyFormat.format(
                            tx.amount
                          )}`}</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              width: 210,
                            },
                          ]}
                        >
                          <Text numberOfLines={1}>{tx.description}</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              width: 135,
                            },
                          ]}
                        >
                          <Text>
                            {new Date(tx.limit_date).toLocaleDateString(
                              "es-CO",
                              {
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            { backgroundColor: c.backgrounds.bgContainer },
                          ]}
                        >
                          <Text numberOfLines={1}>{tx.full_name}</Text>
                        </View>
                        <View
                          style={[
                            styles.cell,
                            {
                              backgroundColor: c.backgrounds.bgContainer,
                              borderRightColor: c.bordersDividers.border,
                              width: 135,
                            },
                          ]}
                        >
                          <Pressable
                            onPress={() => activeModal(tx.external_reference)}
                          >
                            <View style={styles.moreButton}>
                              <Text
                                style={{
                                  color: c.backgrounds.bgContainer,
                                  fontWeight: c.fontWeight.fw_medium,
                                }}
                              >
                                Ver más
                              </Text>
                            </View>
                          </Pressable>
                        </View>
                      </View>
                      {selected === tx.external_reference && (
                        <>
                          <ScheduledDetails
                            isDesktop
                            visible={showModal}
                            txValue={tx.amount}
                            txDate={tx.created_at}
                            txTitle={tx.description}
                            setShowEdit={setShowEdit}
                            key={tx.external_reference}
                            txCode={tx.external_reference}
                            txClientPhone={tx.payer_phone}
                            currencyFormat={currencyFormat}
                            txClientName={tx.payer_full_name}
                            close={() => setShowModal(false)}
                            countryCurrency={userCountrySymbol}
                            txLimitDate={new Date(
                              tx.limit_date
                            ).toLocaleDateString("es-CO", {
                              month: "long",
                              day: "numeric",
                            })}
                          />
                          <EditScheduled
                            txInfo={tx}
                            visible={showEdit}
                            validate={validate}
                            errors={newTxErrors}
                            handleChange={handleChange}
                            close={() => setShowEdit(false)}
                          />
                          <ConfirmScheduledModification
                            modifyTx={modifyTx}
                            visible={showConfirmation}
                            close={() => setShowConfirmation(false)}
                          />
                        </>
                      )}
                    </>
                  ))}
              </ScrollView>
            </View>
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default Scheduled;

const styles = StyleSheet.create({
  cell: {
    height: 40,
    width: 175,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    paddingVertical: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    borderTopColor: c.bordersDividers.border,
    borderBottomColor: c.bordersDividers.border,
  },
  moreButton: {
    width: 95,
    paddingHorizontal: 17,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.desktopColors.desktopBase,
    textAlign: "center",
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableButton: {
    paddingVertical: 6,
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_base,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
