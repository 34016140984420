import { getData, postData } from "../api-call";
import {
  BASE_URL,
  CREATE_MULTIPLE_TRANSACTION_HOOK,
  CREATE_SCHEDULED_TRANSACTION_HOOK,
  CREATE_TRANSACTION_HOOK,
  TRANSACTIONS_QUERY,
} from "../config";

const getTransactions = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${TRANSACTIONS_QUERY}/${id}`;
  return getData(requestUrl);
};
const createTransaction = async ({
  value,
  nickname,
  client_id,
  description,
  merchant_id,
  merchant_name,
  reference_one,
  merchant_phone,
  payment_method,
}) => {
  const data = {
    value,
    nickname,
    client_id,
    description,
    merchant_id,
    merchant_name,
    reference_one,
    merchant_phone,
    payment_method,
    source: "Reporte",
    channel: "REPORTE",
  };

  const requestUrl = `${CREATE_TRANSACTION_HOOK}`;

  return postData(requestUrl, data);
};
const createScheduledTransaction = async ({
  value,
  description,

  client_id,
  reference_one,
  payment_method,
  frequency,
  startDate,
  reminderDate,
  limitDate,
  chargeAmount,
  contactName,
  contactId,
  merchantName,
  merchantPaymentId,
  merchantId,
}) => {
  const data = {
    value,
    contactName,
    contactId,
    merchantName,
    merchantPaymentId,
    merchantId,
    description,
    client_id,
    reference_one,
    payment_method,
    type: "SCHEDULED",
    frequency,
    startDate,
    reminderDate,
    limitDate,
    chargeAmount,
  };
  const requestUrl = `${CREATE_SCHEDULED_TRANSACTION_HOOK}`;

  return postData(requestUrl, data);
};
const createMultipleTransaction = ({
  csvFile,
  contactName,
  contactId,
  merchantName,
  merchantPaymentId,
  merchantId,
}) => {
  const requestUrl = `${CREATE_MULTIPLE_TRANSACTION_HOOK}`;

  return postData(requestUrl, {
    csv: csvFile,
    contact_name: contactName,
    contact_id: contactId,
    merchant_name: merchantName,
    merchant_payment_id: merchantPaymentId,
    merchant_id: merchantId,
  });
};
export const TransactionsInfoAPI = {
  getTransactions,
  createTransaction,
  createMultipleTransaction,
  createScheduledTransaction,
};
