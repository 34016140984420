import { StyleSheet, Text, TextInput, View } from "react-native";
import React, { useState } from "react";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const InputComponent = ({
  label,
  error,
  value,
  isNumeric,
  placeholder,
  onChangeText,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <View style={label && { marginTop: c.spacing.spacing_md }}>
      {label && <Text style={styles.label}>{label}</Text>}
      <View
        style={[
          styles.input,
          {
            borderColor: error
              ? c.error.errorBorder
              : isFocused
              ? c.textIcons.text
              : c.bordersDividers.border,
          },
        ]}
      >
        <TextInput
          value={value}
          placeholder={placeholder}
          onChangeText={onChangeText}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          keyboardType={isNumeric && "numeric"}
          placeholderTextColor={c.textIcons.placeholder}
        />
      </View>
      {error && <Text style={[txt.sm_strong, styles.errorText]}>{error}</Text>}
    </View>
  );
};

export default InputComponent;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.backgrounds.bgContainer,
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
});
