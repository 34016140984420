import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import React from "react";
import { ScrollView } from "react-native";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import { TextInput } from "react-native";
import Divider from "../../Components/Divider";

const Customize = () => {
  const screenWidth = useWindowDimensions().width;
  const isDesktop = screenWidth > 500;
  return isDesktop ? (
    <View
      style={{
        paddingVertical: c.spacing.spacing_x_big,
        paddingHorizontal: c.spacing.spacing_lg,
      }}
    >
      <Text style={{ fontSize: c.fontSize.fs_huge }}>Personalizar</Text>
    </View>
  ) : (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <View style={styles.alertContianer}>
          <Text style={styles.alertTitle}>
            ¡Lo sentimos! Estas opciones solo están habilitadas para los
            usuarios del plan pro de Qentaz
          </Text>
        </View>
        <Text style={styles.label}>Color Principal</Text>
        <Text
          style={[
            txt.sm_normal,
            {
              lineHeight: c.lineHeight.lh2,
              marginTop: c.spacing.spacing_xx_sm,
            },
          ]}
        >
          Este color aparecerá en botones, titulos e iconos. Se recomienda
          colores que hagan constraste con fondos claros y textos negros.
        </Text>
        <View style={styles.colorContainer}>
          <View style={{ width: "48%" }}>
            <TextInput
              placeholder="#30ce88"
              placeholderTextColor={c.textIcons.placeholder}
            />
          </View>
          <View
            style={[
              styles.colorCaption,
              {
                backgroundColor: c.themeColors.primaryBase,
              },
            ]}
          >
            <Text>Texto</Text>
          </View>
        </View>
        <Text style={styles.label}>Color secundario</Text>
        <Text
          style={[
            txt.sm_normal,
            {
              lineHeight: c.lineHeight.lh2,
              marginTop: c.spacing.spacing_xx_sm,
            },
          ]}
        >
          Este color aparecerá en fondos. Se recomienda colores claros
          complementarios al color principal.
        </Text>
        <View style={styles.colorContainer}>
          <View style={{ width: "48%" }}>
            <TextInput
              placeholder="#eafaf3"
              placeholderTextColor={c.textIcons.placeholder}
            />
          </View>
          <View
            style={[
              styles.colorCaption,
              {
                backgroundColor: c.themeColors.primaryBg,
              },
            ]}
          >
            <Text>Texto</Text>
          </View>
          <Divider />
        </View>
        <Text style={styles.label}>Logo</Text>
        <Text
          style={[
            txt.sm_normal,
            {
              lineHeight: c.lineHeight.lh2,
              marginTop: c.spacing.spacing_xx_sm,
            },
          ]}
        >
          Este logo aparecerá en toda la experiencia de tus clientes.
        </Text>
        <View style={styles.input}>
          <Image
            source={require("../../assets/fanta_example.png")}
            resizeMode="contain"
            style={{ width: 75, height: 60 }}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <Pressable>
            <View
              style={[
                styles.button,
                { backgroundColor: c.backgrounds.bgLayout },
              ]}
            >
              <Text style={[styles.buttonTitle, { color: c.textIcons.icon }]}>
                Subir logo
              </Text>
            </View>
          </Pressable>
          <Pressable>
            <View style={[styles.button, styles.errorButton]}>
              <Text style={[styles.buttonTitle, { color: c.error.errorBase }]}>
                Eliminar logo
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

export default Customize;

const styles = StyleSheet.create({
  label: {
    marginTop: c.spacing.spacing_md,
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
    lineHeight: c.lineHeight.lh3,
  },
  alertContianer: {
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xx_sm,
    backgroundColor: c.error.errorBg,
    borderRadius: c.borderRadius.br_base,
  },
  alertTitle: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
    color: c.error.errorText,
  },
  colorContainer: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  colorCaption: {
    width: "48%",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
    alignItems: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    alignSelf: "flex-end",
    marginTop: c.spacing.spacing_md,
  },
  button: {
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_xxx_sm,
    paddingHorizontal: c.spacing.spacing_md,
  },
  buttonTitle: {
    lineHeight: c.lineHeight.lh2,
    fontWeight: c.fontWeight.fw_medium,
  },
  errorButton: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
    marginLeft: c.spacing.spacing_md,
  },
});
