import _ from "lodash";
import React from "react";
import { Modal } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { StyleSheet, Text, View, Pressable, ScrollView } from "react-native";

import { txt } from "../../styles/typestyles";
import { c } from "../../styles/constants";

const MultipleChargeSent = ({
  navigation,
  visible,
  closeModal,
  clearDocument,
}) => {
  return (
    <>
      <Modal
        transparent
        visible={visible}
        animationType="slide"
        onRequestClose={closeModal}
      >
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0.25)",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <View style={[styles.modalCard]}>
            <ScrollView>
              <View
                style={{
                  paddingHorizontal: c.spacing.spacing_md,
                  paddingTop: c.spacing.spacing_big,
                }}
              >
                <View style={{ alignSelf: "center", alignItems: "center" }}>
                  <Icon
                    name="documents"
                    size={48}
                    color={c.themeColors.primaryBase}
                  />
                  <Text
                    style={[txt.xl_strong, { marginTop: c.spacing.spacing_md }]}
                  >
                    ¡Cobro múltiple realizado!
                  </Text>

                  <Pressable
                    onPress={() => {
                      closeModal();
                      clearDocument();
                    }}
                  >
                    <View style={styles.mainButton}>
                      <Text style={txt.lg_strong}>
                        Realizar otro cobro múltiple
                      </Text>
                    </View>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      navigation.navigate("Programados");
                      closeModal();
                      clearDocument();
                    }}
                  >
                    <View
                      style={[
                        styles.mainButton,
                        { backgroundColor: c.themeColors.primaryBg },
                      ]}
                    >
                      <Text style={txt.lg_strong}>Ver mis programados</Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default MultipleChargeSent;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "center",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
});
