import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import OTP from "../Screens/OTP/OTP";
import Register from "../Screens/Register/Register";
import RegisterHeader from "../Components/RegisterHeader";
const Stack = createStackNavigator();

const RegisterNavigator = ({ isDesktop }) => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={() => ({
        header: () => <RegisterHeader isDesktop={isDesktop} />,
      })}
    >
      <Stack.Screen
        name="Registro"
        component={Register}
        initialParams={{ isDesktop: isDesktop }}
      />
      <Stack.Screen name="OTP" component={OTP} />
    </Stack.Navigator>
  );
};

export default RegisterNavigator;
