import _ from "lodash";
import csvToJson from "csvtojson";
import { useState, useEffect } from "react";
import * as DocumentPicker from "expo-document-picker";
import { checkProperty, parseJwt } from "../../helpers/src";

import { BusinessInfoAPI } from "../../api/src/business";
import { ContactInfoApi } from "../../api/src/contact-info";
import { DispersionsInfoApi } from "../../api/src/dispersions";
import { TransactionsInfoAPI } from "../../api/src/transactions";
import { DashboardApi } from "../../api/src/dashboard";
import axios from "axios";
import { CashoutAPI } from "../../api/src/create-cash-out";

const useDispersion = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  var convertFileJson = [];
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");
  const currencyFormat = Intl.NumberFormat("es-CO");

  const [file, setFile] = useState(null);
  const [tab, setTab] = useState("tabla");
  const [response, setResponse] = useState({});
  const [business, setBussiness] = useState({});
  const [fileError, setFileError] = useState("");
  const [loadFile, setLoadFile] = useState(false);
  const [checkInfo, setCheckInfo] = useState(false);
  const [convertFile, setConvertFile] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [showMessageDispersion, setShowMessageDispersion] = useState(false);

  const [merchantData, setMerchantData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [dispersionInfo, setDispersionInfo] = useState({});
  const [notEnoughCash, setNotEnoughCash] = useState(false);
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [cashoutLoading, setCashoutLoading] = useState(false);
  const [dispersionErrors, setDispersionErrors] = useState({});
  const [externalReference, setExteralReference] = useState("");

  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      BusinessInfoAPI.getBusinessByPhone({
        phone: checkProperty(x?.contact?.attributes?.phone),
      }).then((x) => {
        setBussiness({
          payment_id: checkProperty(x?.data?.payment_id),
          name: checkProperty(x?.data?.business_name),
        });
      });

      setMerchantData({
        countryCurrency: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        id: checkProperty(x?.contact?.id),
        name: checkProperty(x?.contact?.attributes?.first_name),

        phone: checkProperty(x?.contact?.attributes?.phone),
        userCountryCode: checkProperty(
          x?.contact?.attributes?.user_country_code
        ),
        currentAmount: checkProperty(
          x?.contact?.attributes?.accounts?.data[0]?.attributes?.current_amount
        ),
      });
    });
  }, []);

  useEffect(() => {
    DashboardApi.getMerchantFinancesData({
      hashId,
      from: "Mes",
      type: "Dispersion",
    }).then((x) =>
      setTransactions(
        checkProperty(
          x?.movements?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        )
      )
    );
  }, []);

  const clearDocument = () => setFile(null);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({});

    setLoadFile(true);
    setTimeout(async () => {
      if (result?.output[0]?.type === "text/csv") {
        const parseBase = result.assets[0]?.uri?.replace(
          "data:text/csv;base64,",
          ""
        );

        setFile(result?.assets);

        const convertbase = atob(parseBase);

        const json = await csvToJson().fromString(
          `${convertbase}`
            ?.replaceAll("Ã³", "ó")
            .replaceAll("Ãº", "ú")
            .replaceAll("Ã", "í")
            .replaceAll("í©", "é")
            .replaceAll("Moneda", "moneda")
            .replaceAll("Detalle", "detalle")
            .replaceAll("Paí­s", "pais")
            .replaceAll("Número de cuenta", "numero_de_cuenta")
            .replaceAll("Descripción", "descripcion")
            .replaceAll("Valor", "valor")
            .replaceAll("Tipo de Documento", "tipo_documento")
            .replaceAll("Referencia 1", "referencia_1")
            .replaceAll("Número de Documento", "numero_documento")
            .replaceAll("Ãa", "e")
            .replaceAll("Referencia 2", "referencia_2")
            .replaceAll("Nombre del destinatario", "nombre_destinatario")
            .replaceAll("Celular", "celular")
            .replaceAll("Correo", "correo")
            .replaceAll("Medio de comunicación", "medio_comunicacion")
            .replace("Banco", "banco")
            .replaceAll("País", "pais")
            .replaceAll("Tipo de Cuenta", "tipo_cuenta")
            .replaceAll("Número de Cuenta", "numero_cuenta")
        );

        const jsonString = JSON.stringify(json, null, 2);
        const parseJson = JSON.parse(jsonString);
        convertFileJson = parseJson;
        setConvertFile(parseJson);

        // setFileError("");
        setLoadFile(false);
      } else {
        setFileError("Formato de archivo no aceptado");
        setLoadFile(false);
      }
    }, 2000);
  };
  const handleDispersion = (field, value) => {
    setDispersionInfo((prev) => ({ ...prev, [field]: value }));
    handleError(field, "");
  };

  const handleError = (field, message) => {
    setDispersionErrors((prev) => ({ ...prev, [field]: message }));
  };

  const controlForm = (dispersionInfo) => {
    let isValid = true;

    if (_.isEmpty(dispersionInfo?.amount)) {
      handleError("amount", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.description)) {
      handleError("description", "Este campo es obligatorio");
      isValid = false;
    } else {
      handleError("description", "");
    }
    if (_.isEmpty(dispersionInfo?.receiverName)) {
      handleError("receiverName", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.idType)) {
      handleError("idType", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.idNumber)) {
      handleError("idNumber", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.receiverPhone)) {
      handleError("receiverPhone", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.email)) {
      handleError("email", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.bank)) {
      handleError("bank", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.accountType)) {
      handleError("accountType", "Este campo es obligatorio");
      isValid = false;
    }
    if (_.isEmpty(dispersionInfo?.accountNumber)) {
      handleError("accountNumber", "Este campo es obligatorio");
      isValid = false;
    }
    isValid && setShowDetails(true);
  };

  const generateIndividualDispersion = () => {
    const {
      bank,
      email,
      idType,
      amount,
      idNumber,
      description,
      accountType,
      receiverName,
      receiverPhone,
      accountNumber,
    } = dispersionInfo;
    const merchantId = business?.payment_id;
    const merchantName = business?.name;
    const merchantMember = merchantData?.name;
    const merchantPhone = merchantData?.phone;
    try {
      DispersionsInfoApi.createIndividualDispersion({
        bank,
        email,
        idType,
        amount,
        idNumber,
        description,
        accountType,
        receiverName,
        receiverPhone,
        accountNumber,
        merchantId,
        merchantName,
        merchantMember,
        merchantPhone,
      }).then((x) => {
        setResponse(x);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const generateCsv = async () => {
    setIsMultiple(true);
    setShowDetails(true);
    setCheckInfo(true);
    const contactName = merchantData?.name;
    const contactId = merchantData?.phone;
    const merchantName = business?.name;
    const merchantPaymentId = business?.payment_id;
    const merchantId = merchantData?.id;

    try {
      DispersionsInfoApi.createMultipleDispersion({
        csvFile: convertFileJson,
        contactName,
        contactId,
        merchantName,
        merchantPaymentId,
        merchantId,
      }).then((x) => setResponse(x));
    } catch (error) {
      console.log(error);
    }
  };

  const payNow = async () => {
    setCheckoutModal(true);
    TransactionsInfoAPI.createTransaction({
      clientName: merchantData?.name,
      merchant_name: "QENTAZ S.A.S",
      nickname: "",
      client_id: merchantData?.phone,
      description: dispersionInfo?.description,
      merchant_id: "qentaz",
      merchant_phone: "573103922795",
      payment_method: "",
      reference_one: "",
      value: response?.payout_total,
    }).then((x) => {
      const { external_reference } = x;
      setCheckoutModal(false);
      setExteralReference(external_reference);
    });
  };

  useEffect(() => {
    if (externalReference) {
      window.open(
        `http://pago.qentaz.com/qentaz/t/${externalReference}`,
        "_blank"
      );
    }
  }, [externalReference]);

  const validateCash = () =>
    Number(response?.payout_total) > merchantData?.currentAmount
      ? setNotEnoughCash(true)
      : setShowConfirm(true);

  const createCashout = () => {
    try {
      axios
        .post("https://hook.us1.make.com/uejht2rpv2po3wrggvaqohfomb97c94l", {
          payout_id: response?.payout_id,
        })
        .then((x) => {
          setResponse(x);
          setCashoutLoading(true);
          setTimeout(() => {
            setShowMessageDispersion(true);
            // setShowConfirm(false);
            // setShowDetails(false);
            setCashoutLoading(false);
          }, 2000);
        });
    } catch (error) {
      console.error(error);
    }
  };
  // console.log("total", Number(response?.payout_total));
  // console.log("current", merchantData?.currentAmount);
  return {
    tab,
    file,
    payNow,
    setTab,
    loadFile,
    response,
    fileError,
    checkInfo,
    isMultiple,
    showStatus,
    generateCsv,
    controlForm,
    showDetails,
    showConfirm,
    setCheckInfo,
    transactions,
    merchantData,
    pickDocument,
    validateCash,
    setShowStatus,
    notEnoughCash,
    showMessageDispersion,
    checkoutModal,
    createCashout,
    clearDocument,
    setShowConfirm,
    cashoutLoading,
    dispersionInfo,
    setShowDetails,
    currencyFormat,
    setCheckoutModal,
    handleDispersion,
    dispersionErrors,
    setCashoutLoading,
    generateIndividualDispersion,
  };
};

export default useDispersion;
