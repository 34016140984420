import React from "react";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { StyleSheet, Image, View } from "react-native";
import Ionicon from "react-native-vector-icons/Ionicons";

import { c } from "../styles/constants";

import Divider from "../Components/Divider";
import Charge from "../Screens/Charge/Charge";
import Analysis from "../Screens/Analysis/Analysis";
import Activity from "../Screens/Activity/Activity";
import Scheduled from "../Screens/Scheduled/Scheduled";
import Dispersion from "../Screens/Dispersion/Dispersion";
import Retire from "../Screens/Retire/Retire";
import Customize from "../Screens/Customize/Customize";
import Users from "../Screens/Users/Users";
import Learn from "../Screens/Learn/Learn";
import { useDrawerInfo } from "./Drawer/useDrawer";
const Drawer = createDrawerNavigator();
const DrawerNavigator = () => {
  const { merchantLogo } = useDrawerInfo();
  return (
    <Drawer.Navigator
      headerMode="screen"
      drawerContent={(props) => (
        <View style={{ flex: 1, width: 230 }}>
          <DrawerContentScrollView {...props}>
            <View
              style={{
                alignSelf: "center",
                // padding: c.spacing.spacing_md,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={merchantLogo}
                resizeMode="contain"
                style={{ width: 130, height: 100 }}
              />
            </View>
            <View style={{ marginBottom: c.spacing.spacing_md }}>
              <Divider />
            </View>
            <DrawerItemList {...props} />
          </DrawerContentScrollView>
          <View>
            <Image
              source={require("../assets/qz-desktop-pay.png")}
              resizeMode="contain"
              style={{
                width: 105,
                height: 45,
                marginBottom: c.spacing.spacing_big,
                marginLeft: c.spacing.spacing_md,
              }}
            />
          </View>
        </View>
      )}
      screenOptions={({ route }) => ({
        headerShown: false,
        drawerIcon: ({ focused, color, size }) => {
          let iconName;
          let rn = route.name;
          if (rn === "Actividad") {
            iconName = "swap-vertical-outline";
          } else if (rn === "Analisis") {
            iconName = "stats-chart-outline";
          } else if (rn === "Cobrar") {
            iconName = "arrow-up-circle-outline";
          } else if (rn === "Programados") {
            iconName = "calendar-outline";
          } else if (rn === "Mas") {
            iconName = "apps-outline";
          } else if (rn === "Dispersiones") {
            iconName = "exit-outline";
          } else if (rn === "Personalización") {
            iconName = "brush-outline";
          } else if (rn === "Retiros") {
            iconName = "cash-outline";
          } else if (rn === "Equipo") {
            iconName = "people-outline";
          } else if (rn === "Ayuda") {
            iconName = "help-circle-outline";
          }
          return <Ionicon name={iconName} size={size} color={color} />;
        },
        drawerType: "permanent",
        drawerStyle: styles.tabBar,
        drawerInactiveTintColor: c.textIcons.text,
        drawerActiveTintColor: c.backgrounds.bgContainer,
        drawerActiveBackgroundColor: c.desktopColors.desktopBase,
      })}
    >
      <Drawer.Screen name="Actividad" component={Activity} />
      <Drawer.Screen name="Cobrar" component={Charge} />
      <Drawer.Screen name="Programados" component={Scheduled} />
      <Drawer.Screen name="Retiros" component={Retire} />
      <Drawer.Screen name="Dispersiones" component={Dispersion} />
      {/* <Drawer.Screen name="Personalización" component={Customize} /> */}
      {/* <Drawer.Screen name="Analisis" component={Analysis} /> */}
      {/* <Drawer.Screen name="Equipo" component={Users} /> */}
      {/* <Drawer.Screen name="Ayuda" component={Learn} /> */}
      {/* <Tabs.Screen name="Asistente" component={Assistant} /> */}
      {/* <Drawer.Screen name="Mas" component={MenuNavigator} /> */}
    </Drawer.Navigator>
  );
};

export default DrawerNavigator;

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: c.backgrounds.bgContainer,
    width: 230,
  },
});
