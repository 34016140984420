import React from "react";
import { StyleSheet, Text, View, Modal, Pressable } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import { txt } from "../../styles/typestyles";
import { c } from "../../styles/constants";

const ConfirmDeleteUser = ({ visible, close }) => {
  return (
    <Modal
      visible={visible}
      onRequestClose={close}
      animationType="slide"
      transparent
    >
      <View style={styles.modalBackground}>
        <View style={styles.cardContainer}>
          <Pressable onPress={close}>
            <View style={styles.backButton_icon}>
              <Icon
                name="arrow-back-circle-outline"
                size={16}
                color={c.textIcons.icon}
              />
              <Text style={[txt.sm_normal, styles.backButton_txt]}>
                Regresar
              </Text>
            </View>
          </Pressable>
          <Text style={[styles.cardTitle]}>
            ¿Deseas eliminar a este usuario?
          </Text>
          <Text style={{ textAlign: "center" }}>
            Toda su información guardada en nuestro sistema será borrado de
            forma permanente.
          </Text>
          <View
            style={{
              marginTop: c.spacing.spacing_md,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={[styles.button, { backgroundColor: c.error.errorBase }]}
            >
              <Pressable onPress={close}>
                <Text
                  style={[txt.lg_strong, { color: c.backgrounds.bgContainer }]}
                >
                  No, cancelar
                </Text>
              </Pressable>
            </View>
            <View
              style={[
                styles.button,
                { backgroundColor: c.themeColors.primaryBase },
              ]}
            >
              <Pressable>
                <Text style={txt.lg_strong}>Si, eliminar</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmDeleteUser;

const styles = StyleSheet.create({
  modalBackground: {
    backgroundColor: "rgba(0,0,0,0.25)",
    flex: 1,
    justifyContent: "center",
  },
  cardContainer: {
    width: "85%",
    borderRadius: 12,
    alignSelf: "center",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  backButton_icon: {
    flexDirection: "row",
    alignSelf: "flex-end",
    alignItems: "center",
    marginVertical: c.spacing.spacing_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
  cardTitle: {
    textAlign: "center",
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_md,
  },
  button: {
    width: "47%",
    alignItems: "center",
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
  },
});
