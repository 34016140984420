import {
  Text,
  View,
  Modal,
  Pressable,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import { c } from "../../styles/constants";
import Divider from "../Divider";
import { txt } from "../../styles/typestyles";
const StatusDispersion = ({
  tx,
  close,
  status,
  visible,
  countryCurrency,
  currencyFormat,
}) => {
  const { height } = useWindowDimensions();
  let title, icon, color;
  switch (status) {
    case "SUCCESS":
      title = "exitosa";
      icon = "checkmark-circle";
      color = c.desktopColors.desktopBase;
      break;
    case "PENDING":
      title = "en proceso";
      icon = "time";
      color = c.warning.warningBase;
      break;
    case "DELINED":
      title = "rechazada";
      icon = "close-circle";
      color = c.error.errorBase;
      break;
    case "CREATED":
      title = "creada";
      icon = "alert-circle";
      color = c.textIcons.icon;
      break;

    default:
      null;
  }
  return (
    <Modal transparent visible={visible} onRequestClose={close}>
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          height,
        }}
      >
        <View style={[styles.modalCard, { height }]}>
          <ScrollView>
            <View
              style={{
                paddingHorizontal: c.spacing.spacing_md,
              }}
            >
              <View style={styles.summaryRow}>
                <Pressable onPress={close}>
                  <View style={styles.backButton_icon}>
                    <Icon
                      name="arrow-back-circle-outline"
                      size={16}
                      color={c.textIcons.icon}
                    />
                    <Text style={[txt.sm_normal, styles.backButton_txt]}>
                      Regresar
                    </Text>
                  </View>
                </Pressable>
              </View>
              <View style={{ alignSelf: "center", alignItems: "center" }}>
                <Icon name={icon} size={46} color={color} />
                <Text
                  style={[
                    txt.xl_strong,
                    { marginTop: c.spacing.spacing_xx_sm },
                  ]}
                >
                  ¡Transacción {title}!
                </Text>
              </View>
              <Text
                style={{
                  marginTop: c.spacing.spacing_md,
                  color: c.textIcons.description,
                }}
              >
                {tx?.created_at}
              </Text>
              <Divider />

              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Destinatario:</Text>
                <Text style={txt.lg_normal}>{tx?.receiverName}</Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Valor:</Text>
                <Text style={txt.lg_normal}>
                  {`${countryCurrency} ${currencyFormat.format(tx?.amount)}`}
                </Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>País:</Text>
                <Text style={txt.lg_normal}>Colombia</Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Celular:</Text>
                <Text style={txt.lg_normal}>{tx?.receiverPhone}</Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Banco:</Text>
                <Text style={txt.lg_normal}>{tx?.bank}</Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Documento de identidad:</Text>
                <Text style={txt.lg_normal}>
                  {tx?.idType} {tx?.idNumber}
                </Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>tipo de cuenta:</Text>
                <Text style={txt.lg_normal}>{tx?.accountType}</Text>
              </View>
              <View style={styles.summaryRow}>
                <Text style={txt.lg_strong}>Número de cuenta:</Text>
                <Text style={txt.lg_normal}>{tx?.accountNumber}</Text>
              </View>
              <View style={{ width: "100%" }}>
                <Pressable onPress={close}>
                  <View style={[styles.mainButton, { backgroundColor: color }]}>
                    <Text style={{ color: c.backgrounds.bgContainer }}>
                      Salir
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
            <View
              style={{ marginTop: c.spacing.spacing_md, alignSelf: "center" }}
            >
              <Pressable>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <MCI
                    name="message-question-outline"
                    size={14}
                    color={c.textIcons.icon}
                  />
                  <Text
                    style={[
                      txt.sm_normal,
                      {
                        color: c.textIcons.description,
                        marginLeft: c.spacing.spacing_sm,
                      },
                    ]}
                  >
                    Reportar un problema
                  </Text>
                </View>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default StatusDispersion;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: c.spacing.spacing_md,
  },
  mainButton: {
    borderWidth: 2,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  alert: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    marginTop: c.spacing.spacing_big,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.error.errorHoverBg,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xx_sm,
  },
  alertText: {
    color: c.error.errorText,
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: c.spacing.spacing_xx_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
});
