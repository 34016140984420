import React, { useState } from "react";
import _ from "lodash";

const useUsers = () => {
  const [tab, setTab] = useState("");
  const [userRole, setUserRole] = useState("");
  const [infoSent, setInfoSent] = useState(false);
  const [userErrors, setUserErrors] = useState({});
  const [newUserInfo, setNewUserInfo] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [validationLoader, setValidationLoader] = useState(false);

  const toggleEdit = () => setIsEditable(!isEditable);
  const handleChange = (field, value) => {
    setNewUserInfo((prev) => ({ ...prev, [field]: value }));
  };
  const handleError = (message, field) => {
    setUserErrors((prev) => ({ ...prev, [field]: message }));
  };
  const validateInfo = () => {
    if (!newUserInfo.newUserName) {
      handleError("Este campo es obligatorio", "newUserName");
    }
    if (!newUserInfo.newUserPhone) {
      handleError("Este campo es obligatorio", "newUserPhone");
    } else if (newUserInfo.newUserPhone.length !== 10) {
      handleError("Ingresa un teléfono válido", "newUserPhone");
    }
    if (!newUserInfo.newUserRole) {
      handleError("Este campo es obligatorio", "newUserRole");
    }
    _.isEmpty(userErrors) && setInfoSent(true);
  };
  const createNewUser = () => {
    setValidationLoader(true);
    setTimeout(() => {
      setIsValidated(true);
      setValidationLoader(false);
    }, 2000);
  };
  return {
    tab,
    setTab,
    infoSent,
    userRole,
    toggleEdit,
    userErrors,
    isEditable,
    setUserRole,
    newUserInfo,
    isValidated,
    validateInfo,
    handleChange,
    createNewUser,
    setInfoSent,
    validationLoader,
  };
};

export default useUsers;
