export const banksArray = [
    "Nequi",
    "DaviPlata",
    "Dale",
    "RappiPay",
    "MOVii S.A.",
    "Banco de Bogotá",
    "Banco Popular",
    "Banco Itaú",
    "Bancolombia",
    "Citibank",
    "Banco GNB Sudameris",
    "Banco BBVA Colombia S.A.",
    "Scotiabank Colpatria",
    "Banco De Occidente",
    "Banco Caja Social",
    "Banco Agrario",
    "Banco Davivienda",
    "Banco AV Villas",
    "Bancamia S.A.",
    "Banco Pichincha S.A.",
    "Bancoomeva S.A.",
    "Banco Falabella",
    "Banco Finandina",
    "Banco Santander Colombia",
    "Banco Cooperativo Coopcentral",
    "Banco Serfinanza",
    "Lulo Bank",
    "CFA Cooperativa Financiera",
    "COTRAFA",
    "COOFINEP Cooperativa Financiera",
    "Confiar Cooperativa Financiera",
    "Banco Union",
    "Coltefinanciera",
    "Banco Credifinanciera",
    "IRIS",
  ];
  