import { StyleSheet, Text, View, Pressable, ScrollView } from "react-native";
import React, { useState } from "react";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";

import Icon from "react-native-vector-icons/Ionicons";
import Divider from "../../Components/Divider";
import { TextInput } from "react-native";
import EditTransaction from "../../Components/Modal/EditTransaction";

const Program = ({ navigation }) => {
  const [period, setPeriod] = useState("");
  const [showModal, setShowModal] = useState(false);
  const periods = ["Vencen pronto", "Vencidos", "Recientes", "Elegir fecha"];
  const transactions = [
    {
      title: "Mensualidad del apto 205",
      value: "$128.500",
      date: "15 de enero de 2023",
      type: "Cobro",
    },
    {
      title: "Gases de occidente SA ESP",
      value: "$128.500",
      date: "15 de enero de 2023",
      type: "Pago",
    },
  ];
  return (
    <>
      <ScrollView>
        <View style={{ padding: c.spacing.spacing_md }}>
          <View style={styles.balanceCard}>
            <Text style={styles.balanceTitle}>Saldo pendiente</Text>
            <Text style={[txt.heading2, { color: c.backgrounds.bgContainer }]}>
              $ 122.500
            </Text>
            <Text
              style={[txt.base_normal, { color: c.backgrounds.bgContainer }]}
            >
              El dinero que podrás recibir si todos tus cobros programados se
              hacen efectivos
            </Text>
          </View>
          <View style={styles.programmingButtonsContainer}>
            <Pressable onPress={() => navigation.push("Programar cobro")}>
              <View style={styles.programmingButton}>
                <Icon
                  name="arrow-up-circle"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <Text style={{ marginLeft: c.spacing.spacing_xxx_sm }}>
                  Programar cobro
                </Text>
              </View>
            </Pressable>
            <Pressable>
              <View style={styles.programmingButton}>
                <Icon
                  name="arrow-down-circle"
                  size={30}
                  color={c.themeColors.primaryBase}
                />
                <Text style={{ marginLeft: c.spacing.spacing_xxx_sm }}>
                  Programar pago
                </Text>
              </View>
            </Pressable>
          </View>
          <Divider />
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.periodFilterContainer}>
              {periods.map((p, i) => (
                <Pressable key={i} onPress={() => setPeriod(p)}>
                  <View
                    style={[
                      styles.periodFilterButton,
                      period === p && styles.periodFilterButton_selected,
                    ]}
                  >
                    {p === "Elegir fecha" ? (
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Icon
                          name="calendar-outline"
                          size={18}
                          color={
                            period === p
                              ? c.themeColors.primaryBase
                              : c.textIcons.disabled
                          }
                        />
                        <Text
                          style={[
                            txt.sm_strong,
                            {
                              color:
                                period === p
                                  ? c.themeColors.primaryBase
                                  : c.textIcons.disabled,
                              marginLeft: c.spacing.spacing_xx_sm,
                            },
                          ]}
                        >
                          {p}
                        </Text>
                      </View>
                    ) : (
                      <Text
                        style={[
                          txt.sm_strong,
                          {
                            color:
                              period === p
                                ? c.themeColors.primaryBase
                                : c.textIcons.disabled,
                          },
                        ]}
                      >
                        {p}
                      </Text>
                    )}
                  </View>
                </Pressable>
              ))}
            </View>
          </ScrollView>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.xl_strong}>Movimientos programados</Text>
            <View
              style={{
                marginTop: c.spacing.spacing_md,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <TextInput
                style={styles.searchInput}
                placeholder="Escribe una palabra clave"
                placeholderTextColor={c.textIcons.placeholder}
              />
              <Pressable>
                <View style={styles.filterButton}>
                  <Icon
                    name="filter"
                    size={16}
                    color={c.textIcons.placeholder}
                  />
                  <Text
                    style={[
                      txt.sm_normal,
                      {
                        color: c.textIcons.placeholder,
                        marginLeft: c.spacing.spacing_xx_sm,
                      },
                    ]}
                  >
                    Filtrar
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
          {transactions.map((tx, i) => (
            <>
              <Pressable key={i} onPress={() => setShowModal(true)}>
                <View style={styles.movementsCard}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={txt.base_strong}>{tx.title}</Text>
                    <Text style={txt.lg_strong}>{tx.value}</Text>
                  </View>
                  <View style={styles.statusContainer}>
                    <Text
                      style={[
                        txt.sm_normal,
                        { color: c.textIcons.description },
                      ]}
                    >
                      {tx.date}
                    </Text>
                    <View
                      style={[
                        styles.statusBadge,
                        tx.type === "Cobro"
                          ? styles.statusBadge_payment
                          : styles.statusBadge_pay,
                      ]}
                    >
                      <Text
                        style={{
                          fontWeight: c.fontWeight.fw_medium,
                          fontSize: c.fontSize.fs_sm,
                          color:
                            tx.type === "Cobro"
                              ? c.info.infoBase
                              : c.textIcons.description,
                        }}
                      >
                        {tx.type}
                      </Text>
                    </View>
                  </View>
                </View>
              </Pressable>
              <EditTransaction
                visible={showModal}
                close={() => setShowModal(false)}
                txDate={tx.date}
                txTitle={tx.title}
                txValue={tx.value}
                txType={tx.type}
              />
            </>
          ))}
        </View>
      </ScrollView>
    </>
  );
};

export default Program;

const styles = StyleSheet.create({
  balanceCard: {
    borderWidth: 1,

    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,

    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  balanceTitle: {
    color: c.backgrounds.bgContainer,
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
  },
  programmingButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: c.spacing.spacing_md,
  },
  programmingButton: {
    borderWidth: 1,
    alignItems: "center",
    flexDirection: "row",
    padding: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.backgrounds.bgContainer,
  },
  periodFilterContainer: {
    marginTop: c.spacing.spacing_md,
    flexDirection: "row",
  },
  periodFilterButton: {
    borderWidth: 1,
    height: 30,
    borderRadius: c.borderRadius.br_sm,
    marginRight: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  periodFilterButton_selected: {
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.themeColors.primaryBg,
  },
  searchInput: {
    width: "80%",
    borderWidth: 1,
    marginRight: c.spacing.spacing_xx_sm,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  filterButton: {
    borderWidth: 1,
    alignItems: "center",
    flexDirection: "row",
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  movementsCard: {
    borderWidth: 1,
    shadowRadius: 5,
    marginTop: c.spacing.spacing_md,
    shadowColor: "rgba(0,0,0,0.25)",
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_lg,
    backgroundColor: c.backgrounds.bgContainer,
    shadowOffset: { width: 0, height: 0 },
  },
  statusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_xxx_sm,
  },
  statusBadge: {
    borderWidth: 1,
    padding: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
  },
  statusBadge_pay: {
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  statusBadge_payment: {
    borderColor: c.info.infoBorder,
    backgroundColor: c.info.infoBg,
  },
});
