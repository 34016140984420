import { Image, StyleSheet, View, useWindowDimensions } from "react-native";
import React from "react";
import { c } from "../styles/constants";
const RegisterHeader = ({ isDesktop }) => {
  const width = useWindowDimensions().width;
  return (
    <View
      style={[
        styles.headerContainer,
        { width, backgroundColor: isDesktop && "transparent" },
      ]}
    >
      <Image
        source={require("../assets/qz-pay.png")}
        resizeMode="contain"
        style={{ width: 110, height: 40 }}
      />
    </View>
  );
};

export default RegisterHeader;

const styles = StyleSheet.create({
  headerContainer: {
    maxWidth: 500,
    alignSelf: "center",
    alignItems: "center",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.themeColors.primaryBg,
    paddingVertical: c.spacing.spacing_big,
  },
});
