import {
  Text,
  View,
  Modal,
  Image,
  Pressable,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import React, { useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { c } from "../../styles/constants";

import { txt } from "../../styles/typestyles";
import InputComponent from "../InputComponent";
import _ from "lodash";

const NewBankAccount = ({
  close,
  idType,
  errors,
  visible,
  idNumber,
  bankList,
  validate,
  validationLoader,
  handleNewAccount,
}) => {
  const { height } = useWindowDimensions();
  const [selectBank, setSelectBank] = useState({});
  const [accountType, setAccountType] = useState("");
  const [openDropDown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => setOpenDropdown(!openDropDown);

  return (
    <Modal
      transparent
      visible={visible}
      onRequestClose={close}
      animationType="slide"
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          height,
        }}
      >
        <View style={[styles.modalContainer, { height }]}>
          <Pressable onPress={close}>
            <View style={styles.backButton_icon}>
              <Icon
                name="arrow-back-circle-outline"
                size={16}
                color={c.textIcons.icon}
              />
              <Text style={[txt.sm_normal, styles.backButton_txt]}>
                Regresar
              </Text>
            </View>
          </Pressable>
          <View style={styles.formCard}>
            <Text style={txt.lg_strong}>Registrar nueva cuenta</Text>
            <Text style={{ marginTop: c.spacing.spacing_xx_sm }}>
              Recuerda que la cuenta bancaria que vas a registrar debe estar
              vinculada al
              <Text style={txt.base_strong}> número de documento </Text>
              registrado en Qentaz
            </Text>
            <Text style={styles.label}>Documento de identidad</Text>
            <View
              style={[
                styles.input,
                { backgroundColor: c.backgrounds.bgContainerDisabled },
              ]}
            >
              <Text style={{ color: c.textIcons.placeholder }}>
                {idType} {idNumber}
              </Text>
            </View>
            <InputComponent
              isNumeric
              label="Número de cuenta"
              placeholder="1122334455"
              error={errors.accountNumber}
              onChangeText={(val) => handleNewAccount("accountNumber", val)}
            />
            <Text style={styles.label}>Nombre del banco</Text>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.summaryRowBanks}
            >
              <View style={styles.bankButton}>
                <Pressable
                  onPress={() => {
                    setSelectBank({ code: "1007", name: "BANCOLOMBIA" });
                    handleNewAccount("bankName", "BANCOLOMBIA");
                  }}
                >
                  <Image
                    source={
                      "https://qentaz-pagos.s3.amazonaws.com/bank_logos/1007.svg"
                    }
                    resizeMode="contain"
                    style={{ width: 90, height: 30 }}
                  />
                </Pressable>
              </View>
              <View style={styles.bankButton}>
                <Pressable
                  onPress={() => {
                    setSelectBank({ code: "1051", name: "DAVIVIENDA" });
                    handleNewAccount("bankName", "DAVIVIENDA");
                  }}
                >
                  <Image
                    source={
                      "https://qentaz-pagos.s3.amazonaws.com/bank_logos/1051.svg"
                    }
                    resizeMode="contain"
                    style={{ width: 90, height: 30 }}
                  />
                </Pressable>
              </View>
              <View style={styles.bankButton}>
                <Pressable
                  onPress={() => {
                    setSelectBank({ code: "1001", name: "BANCO BOGOTA" });
                    handleNewAccount("bankName", "BANCO BOGOTA");
                  }}
                >
                  <Image
                    source={
                      "https://qentaz-pagos.s3.amazonaws.com/bank_logos/1001.svg"
                    }
                    resizeMode="contain"
                    style={{ width: 90, height: 30 }}
                  />
                </Pressable>
              </View>
              <View style={styles.bankButton}>
                <Pressable
                  onPress={() =>
                    setSelectBank({ code: "1013", name: "BANCO BBVA" })
                  }
                >
                  <Image
                    source={
                      "https://qentaz-pagos.s3.amazonaws.com/bank_logos/1013.svg"
                    }
                    resizeMode="contain"
                    style={{ width: 90, height: 30 }}
                  />
                </Pressable>
              </View>

              <View style={styles.bankButton}>
                <Pressable
                  onPress={() => {
                    setSelectBank({ code: "1023", name: "BANCO DE OCCIDENTE" });
                    handleNewAccount("bankName", "BANCO DE OCCIDENTE");
                  }}
                >
                  <Image
                    source={
                      "https://qentaz-pagos.s3.amazonaws.com/bank_logos/1023_full.svg"
                    }
                    resizeMode="contain"
                    style={{ width: 90, height: 30 }}
                  />
                </Pressable>
              </View>
            </ScrollView>
            <View style={{ marginTop: c.spacing.spacing_md }}>
              <Pressable onPress={toggleDropdown}>
                <View style={[styles.input]}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!_.isEmpty(selectBank) ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={`https://qentaz-pagos.s3.amazonaws.com/bank_logos/${selectBank.code}.svg`}
                          resizeMode="contain"
                          style={{ width: 45, height: 30 }}
                        />
                        <Text>{selectBank.name}</Text>
                      </View>
                    ) : (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text>Seleccionar otro banco</Text>
                      </View>
                    )}
                    <Icon
                      name={`chevron-${openDropDown ? "up" : "down"}`}
                      size={24}
                    />
                  </View>
                </View>
              </Pressable>
            </View>

            {openDropDown && !_.isEmpty(bankList) && (
              <View
                style={[styles.input, { maxHeight: 150, overflow: "scroll" }]}
              >
                {bankList?.map((x) => (
                  <Pressable
                    key={x.financial_institution_code}
                    onPress={() => {
                      handleNewAccount(
                        "bankName",
                        x.financial_institution_name
                      );
                      setSelectBank({
                        code: x.financial_institution_code,
                        name: x.financial_institution_name,
                      });
                      setOpenDropdown(false);
                    }}
                    style={{
                      borderBottomWidth: 0.1,
                      borderBottomColor: "rgba(179, 170, 170, 0.2)",
                    }}
                  >
                    <Text
                      style={{
                        flex: 1,
                        flexDirection: "row",
                      }}
                      key={x.financial_institution_code}
                    >
                      <Image
                        source={`https://qentaz-pagos.s3.amazonaws.com/bank_logos/${x.financial_institution_code}.svg`}
                        resizeMode="contain"
                        style={{ width: 50, height: 30 }}
                      />
                      <Text style={{ position: "absolute", left: 60, top: 9 }}>
                        {x.financial_institution_name}
                      </Text>
                    </Text>
                  </Pressable>
                ))}
              </View>
            )}
            {errors.bankName && (
              <Text style={[txt.sm_strong, styles.errorText]}>
                {errors.bankName}
              </Text>
            )}
            <View
              style={[
                styles.accountTypeContainer,
                errors.accountType && { borderColor: c.error.errorBorder },
              ]}
            >
              <View style={{ width: "48%" }}>
                <Pressable
                  onPress={() => {
                    setAccountType("cAhorros");
                    handleNewAccount("accountType", "cAhorros");
                  }}
                >
                  <View
                    style={[
                      styles.accountTypeButton,
                      accountType === "cAhorros" &&
                        styles.accountTypeButton_selected,
                    ]}
                  >
                    <Text
                      style={
                        accountType === "cAhorros" && {
                          color: c.backgrounds.bgContainer,
                        }
                      }
                    >
                      Ahorros
                    </Text>
                  </View>
                </Pressable>
              </View>
              <View style={{ width: "48%" }}>
                <Pressable
                  onPress={() => {
                    setAccountType("cCorriente");
                    handleNewAccount("accountType", "cCorriente");
                  }}
                >
                  <View
                    style={[
                      styles.accountTypeButton,
                      accountType === "cCorriente" &&
                        styles.accountTypeButton_selected,
                    ]}
                  >
                    <Text
                      style={
                        accountType === "cCorriente" && {
                          color: c.backgrounds.bgContainer,
                        }
                      }
                    >
                      Corriente
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
            {errors.accountType && (
              <Text style={[txt.sm_strong, styles.errorText]}>
                {errors.accountType}
              </Text>
            )}
            <View style={{ marginTop: c.spacing.spacing_xx_big }}>
              <Pressable onPress={validate} disabled={validationLoader}>
                <View style={styles.mainButton}>
                  {validationLoader ? (
                    <ActivityIndicator
                      size="small"
                      color={c.backgrounds.bgContainer}
                    />
                  ) : (
                    <Text style={{ color: c.backgrounds.bgContainer }}>
                      Validar mi identidad
                    </Text>
                  )}
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default NewBankAccount;

const styles = StyleSheet.create({
  formCard: {
    borderWidth: 1,
    padding: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.bordersDividers.border,
  },
  modalContainer: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingVertical: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.backgrounds.bgContainer,
  },

  mainButton: {
    borderWidth: 1,
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: c.spacing.spacing_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
  input: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  accountTypeContainer: {
    width: "100%",
    borderWidth: 1,
    flexDirection: "row",
    marginTop: c.spacing.spacing_md,
    justifyContent: "space-between",
    borderColor: c.bordersDividers.border,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.backgrounds.bgLayout,
    padding: 5,
  },
  accountTypeButton: {
    alignItems: "center",
    paddingHorizontal: c.spacing.spacing_sm,
    paddingVertical: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
  },
  accountTypeButton_selected: {
    backgroundColor: c.desktopColors.desktopBase,
  },
  bankButton: {
    height: 50,
    borderWidth: 1,
    marginRight: 8,
    justifyContent: "center",
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_sm,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRowBanks: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.3%",
  },
});
