import { StyleSheet, Text, View, Pressable, ScrollView } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import _ from "lodash";
import React, { useState } from "react";
import { c } from "../styles/constants";

const DropdownComponent = ({
  options,
  selected,
  selectOption,
  saveValue,
  error,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const toggleDrop = () => setDropdown(!dropdown);
  const closeDropdown = (val) => {
    setDropdown(false);
    selectOption(val);
    saveValue(val);
  };
  return (
    <>
      <Pressable onPress={toggleDrop}>
        <View
          style={[
            styles.input,
            styles.spacedRow,
            { borderColor: error && c.error.errorBorder },
          ]}
        >
          <Text style={!selected && { color: c.textIcons.placeholder }}>
            {selected || "Elige una opción"}
          </Text>
          <Icon
            name={`chevron-${dropdown ? "up" : "down"}-circle`}
            size={18}
            color={c.textIcons.icon}
          />
        </View>
      </Pressable>
      {dropdown && (
        <View style={[{ maxHeight: 130 }, styles.input]}>
          <ScrollView>
            {options.map((item, i) => (
              <Pressable key={i} onPress={() => closeDropdown(item)}>
                <Text style={{ lineHeight: c.lineHeight.lh3 }}>{item}</Text>
              </Pressable>
            ))}
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default DropdownComponent;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
