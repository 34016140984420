import {
  Text,
  View,
  Modal,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import { c } from "../../styles/constants";
import Divider from "../Divider";
import { txt } from "../../styles/typestyles";
const ShowMoreDispersion = ({
  tx,
  close,
  visible,
  countryCurrency,
  currencyFormat,
}) => {
  const { height } = useWindowDimensions();
  const status =
    tx?.status?.toLowerCase() === "success"
      ? "Exitosa"
      : tx?.status?.toLowerCase() === "pending"
      ? "En proceso"
      : tx?.status?.toLowerCase() === "declined"
      ? "Cancelado"
      : "Vencida";
  return (
    <Modal transparent visible={visible} onRequestClose={close}>
      <View style={{ backgroundColor: "rgba(0,0,0,0.25)", height }}>
        <View style={[styles.modalCard, { height }]}>
          <View style={{ paddingHorizontal: c.spacing.spacing_x_big }}>
            <View style={styles.summaryRow}>
              <Pressable onPress={close}>
                <View style={styles.backButton_icon}>
                  <Icon
                    name="arrow-back-circle-outline"
                    size={16}
                    color={c.textIcons.icon}
                  />
                  <Text style={[txt.sm_normal, styles.backButton_txt]}>
                    Regresar
                  </Text>
                </View>
              </Pressable>
            </View>
            <View style={{ alignSelf: "center", alignItems: "center" }}>
              <Text
                style={[txt.xl_strong, { marginTop: c.spacing.spacing_xx_sm }]}
              >
                Detalles de la transacción
              </Text>
            </View>
            <Divider />
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Estado de la transacción:</Text>
              <Text style={txt.lg_normal}>{status}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Destinatario:</Text>
              <Text style={txt.lg_normal}>{tx?.clientName}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Valor de la transacción:</Text>
              <Text style={txt.lg_normal}>
                {`${countryCurrency} ${currencyFormat.format(tx?.amount)}`}
              </Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Código:</Text>
              <Text style={[txt.lg_normal, { textAlign: "right" }]}>
                {tx?.external_reference}
              </Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Celular del cliente:</Text>
              <Text style={txt.lg_normal}>{tx?.clientPhone}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Banco:</Text>
              <Text style={txt.lg_normal}>{tx?.name_bank}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Documento de identidad:</Text>
              <Text style={txt.lg_normal}>
                {tx?.idType} {tx?.idNumber}
              </Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>tipo de cuenta:</Text>
              <Text style={txt.lg_normal}>{tx?.type_account}</Text>
            </View>
            <View style={styles.summaryRow}>
              <Text style={txt.lg_strong}>Número de cuenta:</Text>
              <Text style={txt.lg_normal}>{tx?.number}</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "47%" }}>
                <Pressable>
                  <View style={[styles.mainButton]}>
                    <Text style={{ color: c.backgrounds.bgContainer }}>
                      Descargar PDF
                    </Text>
                  </View>
                </Pressable>
              </View>
              <View style={{ width: "47%" }}>
                <Pressable onPress={close}>
                  <View
                    style={[
                      styles.mainButton,
                      { backgroundColor: c.desktopColors.desktopBg },
                    ]}
                  >
                    <Text style={{ color: c.desktopColors.desktopBase }}>
                      Salir
                    </Text>
                  </View>
                </Pressable>
              </View>
            </View>
          </View>
          <View
            style={{ marginTop: c.spacing.spacing_md, alignSelf: "center" }}
          >
            <Pressable>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <MCI
                  name="message-question-outline"
                  size={14}
                  color={c.textIcons.icon}
                />
                <Text
                  style={[
                    txt.sm_normal,
                    {
                      color: c.textIcons.description,
                      marginLeft: c.spacing.spacing_sm,
                    },
                  ]}
                >
                  Reportar un problema
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ShowMoreDispersion;

const styles = StyleSheet.create({
  modalCard: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  summaryRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    justifyContent: "space-between",
  },
  mainButton: {
    borderWidth: 2,
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.desktopColors.desktopBase,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: c.spacing.spacing_xx_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
});
