import { getData, postData } from "../api-call";
import { BASE_URL, SECURITY_OTP_QUERY, CHECK_OTP } from "../config";

const createOtp = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${SECURITY_OTP_QUERY}/${id}`;
  return getData(requestUrl);
};

const checkOtp = ({ id, otp }: { id: string; otp: string }) => {
  const data = {
    id: "57" + id,
    otp,
  };
  const requestUrl = `${BASE_URL}${CHECK_OTP}`;
  return postData(requestUrl, data);
};
export const OtpAPI = {
  createOtp,
  checkOtp,
};
