import React from "react";
import { Modal, Pressable, StyleSheet, Text, View } from "react-native";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
const SuccessBankAccount = ({ close, visible }) => {
  return (
    <Modal
      transparent
      visible={visible}
      animationType="fade"
      onRequestClose={close}
    >
      <View style={styles.contianer}>
        <View style={styles.loader}>
          <Text style={txt.base_strong}>
            ¡Cuenta bancaria registrada y validada exitosamente!
          </Text>
          <View style={{ marginTop: c.spacing.spacing_xx_big }}>
            <Pressable onPress={close}>
              <View style={styles.mainButton}>
                <Text style={{ color: c.backgrounds.bgContainer }}>
                  Entendido
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SuccessBankAccount;

const styles = StyleSheet.create({
  contianer: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  loader: {
    maxWidth: 500,
    borderRadius: 12,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    padding: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    width: "100%",
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
