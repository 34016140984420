import { StyleSheet, View } from "react-native";
import React from "react";
import { c } from "../styles/constants";

const Divider = () => {
  return <View style={styles.divider}></View>;
};

export default Divider;

const styles = StyleSheet.create({
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: c.bordersDividers.split,
    marginTop: c.spacing.spacing_md,
  },
});
