import _ from "lodash";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import React from "react";
import Icon from "react-native-vector-icons/Ionicons";

import { c } from "../../styles/constants";
import { useActivity } from "./useActivity";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";
import DateFilter from "../../Components/DateFilter";
import Pagination from "../../Components/Pagination";
import TableComponent from "../../Components/TableComponent";
import TransactionsCard from "../../Components/TransactionsCard";
import SegmentedButtons from "../../Components/SegmentedButtons";
const Activity = ({ navigation }) => {
  const screenWidth = useWindowDimensions().width;
  const isDesktop = screenWidth > 500;
  const {
    fetchData,
    sortValue,
    isSelected,
    saveReport,
    setSortValue,
    setIsSelected,
    handleDownload,
    currencyFormat,
    financeData: { transactions },
    merchantData: { user_country_symbol, current_amount },
  } = useActivity();
  const headers = [
    {
      header: "Fecha de creación",
      action: () => setSortValue("created_at"),
    },
    {
      header: "Código",
      action: () => setSortValue("external_reference"),
    },
    {
      header: "Estado",
      action: () => setSortValue("status"),
    },
    {
      header: "Valor",
      action: () => setSortValue("amount"),
    },
    {
      header: "Descripción",
      action: () => setSortValue("description"),
    },
    {
      header: "Cobrador",
      action: () => setSortValue("payer_full_name"),
    },
    { header: "" },
  ];
  return (
    <>
      {isDesktop ? (
        <View style={{ padding: "2%" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: c.fontSize.fs_huge }}>Actividad</Text>
            <Pressable onPress={saveReport}>
              <View
                style={{
                  paddingVertical: 6,
                  alignItems: "center",
                  flexDirection: "row",
                  paddingHorizontal: c.spacing.spacing_sm,
                  borderRadius: c.borderRadius.br_base,
                  backgroundColor: c.desktopColors.desktopBase,
                }}
              >
                <Text
                  style={{
                    color: c.backgrounds.bgContainer,
                    marginRight: c.spacing.spacing_sm,
                  }}
                >
                  Descargar reporte
                </Text>
                <Icon
                  name="download"
                  size={20}
                  color={c.backgrounds.bgContainer}
                />
              </View>
            </Pressable>
          </View>
          <ScrollView horizontal>
            <TableComponent
              currencyFormat={currencyFormat}
              countrySymbol={user_country_symbol}
              transactions={transactions?.filter(
                (tx) => tx.status !== "SCHEDULED"
              )}
              headers={headers}
              sortValue={sortValue}
              handleDownload={handleDownload}
            />
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <View style={{ padding: c.spacing.spacing_md }}>
            <View style={styles.balanceCard}>
              <Text style={styles.balanceTitle}>Saldo</Text>
              <Text
                style={[txt.heading2, { color: c.backgrounds.bgContainer }]}
              >
                {`${user_country_symbol} ${currencyFormat.format(
                  current_amount
                )}`}
              </Text>
              <Text
                style={[txt.base_normal, { color: c.backgrounds.bgContainer }]}
              >
                El dinero que tienes disponible para retirar
              </Text>
            </View>
            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
              <View style={styles.screenButtonsContainer}>
                {/* <View style={styles.screenButton}>
              <Pressable onPress={() => navigation.push("Realizar pago")}>
                <Icon
                  name="arrow-down-circle"
                  size={35}
                  color={c.themeColors.primaryBase}
                />
                <Text style={txt.base_normal}>Pagar</Text>
              </Pressable>
            </View> */}

                <Pressable onPress={() => navigation.push("Realizar retiro")}>
                  <View style={styles.screenButton}>
                    <Icon
                      name="add-circle"
                      size={35}
                      color={c.themeColors.primaryBase}
                    />
                    <Text style={txt.base_normal}>Retirar</Text>
                  </View>
                </Pressable>

                <Pressable onPress={() => navigation.navigate("Usuarios")}>
                  <View style={styles.screenButton}>
                    <Icon
                      name="people-circle"
                      size={35}
                      color={c.themeColors.primaryBase}
                    />
                    <Text style={[txt.base_normal]}>Equipo</Text>
                  </View>
                </Pressable>
              </View>
            </ScrollView>
            <Divider />
            <SegmentedButtons
              arr={["Movimientos", "Retiros", "Programados"]}
              isSelected={isSelected}
              setIsSelected={setIsSelected}
            />

            {/* <View style={styles.paymentsCard}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <View>
              <Text
                style={[txt.lg_strong, { color: c.themeColors.primaryBase }]}
              >
                Cobros
              </Text>
              <Text
                style={[txt.xl_normal, { marginTop: c.spacing.spacing_xx_sm }]}
              >
                $97.200
              </Text>
            </View>
            <View>
              <Text
                style={[
                  txt.lg_strong,
                  { color: c.error.errorBase, textAlign: "right" },
                ]}
              >
                Pagos
              </Text>
              <Text
                style={[txt.xl_normal, { marginTop: c.spacing.spacing_xx_sm }]}
              >
                $97.200
              </Text>
            </View>
          </View>
          <View style={{ marginTop: c.spacing.spacing_sm, width: "100%" }}>
            <View
              style={[styles.line, { backgroundColor: c.error.errorActive }]}
            >
              <View
                style={[
                  styles.line,
                  {
                    backgroundColor: c.themeColors.primaryBase,
                    width: "50%",
                  },
                ]}
              ></View>
            </View>
          </View>
        </View> */}

            {/* <DateFilter weeks_days={weeks_days} /> */}

            {/* <View
          style={{
            marginTop: c.spacing.spacing_md,
            flexDirection: "row",
            width: "100%",
          }}
        >
          <TextInput
            style={styles.input}
            placeholder="Escribe una palabra clave"
            placeholderTextColor={c.textIcons.placeholder}
          />
          <Pressable>
            <View style={styles.filterButton}>
              <Icon name="filter" size={16} color={c.textIcons.placeholder} />
              <Text
                style={[
                  txt.sm_normal,
                  {
                    color: c.textIcons.placeholder,
                    marginLeft: c.spacing.spacing_xx_sm,
                  },
                ]}
              >
                Filtrar
              </Text>
            </View>
          </Pressable>
        </View> */}

            {!_.isEmpty(transactions) ? (
              <View style={{ maxHeight: 450 }}>
                <ScrollView>
                  {transactions?.map((tx) => (
                    <TransactionsCard
                      key={tx.id}
                      serviceFee={tx.fee}
                      txValue={tx.amount}
                      txStatus={tx.status}
                      bankNumber={tx.number}
                      txDate={tx.created_at}
                      bankName={tx.name_bank}
                      txTitle={tx.description}
                      limitDate={tx.limit_date}
                      txCode={tx.external_reference}
                      currencyFormat={currencyFormat}
                      paymentMethod={tx.payment_method}
                      txClientName={tx.payer_full_name}
                      countryCurrency={user_country_symbol}
                      txClientPhone={tx.payer_phone?.slice(2)}
                      receiptImage={{
                        uri: `https://qentaz-pagos.s3.amazonaws.com/receipt-images/${tx.external_reference}.jpeg`,
                      }}
                    />
                  ))}
                </ScrollView>
              </View>
            ) : (
              <View style={styles.emptyContentContainer}>
                <Text style={{ color: c.textIcons.description }}>
                  No tienes movimientos para este periodo
                </Text>

                <Image
                  source={require("../../assets/qz-logo-black.png")}
                  resizeMode="contain"
                  style={{
                    width: 80,
                    height: 22,
                    marginTop: c.spacing.spacing_sm,
                  }}
                />
              </View>
            )}
          </View>
        </ScrollView>
      )}
    </>
  );
};

export default Activity;

const styles = StyleSheet.create({
  balanceCard: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  balanceTitle: {
    fontSize: c.fontSize.fs_big,
    color: c.backgrounds.bgContainer,
    fontWeight: c.fontWeight.fw_medium,
  },
  screenButtonsContainer: {
    flexDirection: "row",
    marginTop: c.spacing.spacing_md,
  },
  screenButton: {
    width: 90,
    borderWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    marginRight: c.spacing.spacing_sm,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_xx_sm,
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.backgrounds.bgContainer,
  },

  paymentsCard: {
    borderWidth: 1,
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_sm,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  line: {
    height: 10,
    borderRadius: 5,
  },
  input: {
    width: "80%",
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    marginRight: c.spacing.spacing_xx_sm,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  filterButton: {
    borderWidth: 1,
    alignItems: "center",
    flexDirection: "row",
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  emptyContentContainer: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: c.spacing.spacing_md,
  },
});
