import React from "react";
import { ActivityIndicator, Modal, StyleSheet, Text, View } from "react-native";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const Loader = ({ visible, closeAction }) => {
  return (
    <Modal
      visible={visible}
      animationType="fade"
      onRequestClose={closeAction}
      transparent
    >
      <View style={styles.contianer}>
        <View style={styles.loader}>
          <ActivityIndicator size="large" color={c.desktopColors.desktopBase} />
          <Text style={[txt.base_strong, { marginLeft: c.spacing.spacing_md }]}>
            Generando...
          </Text>
        </View>
      </View>
    </Modal>
  );
};

export default Loader;

const styles = StyleSheet.create({
  contianer: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  loader: {
    borderRadius: 12,
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
});
