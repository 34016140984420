import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Pressable,
  Image,
} from "react-native";
import _ from "lodash";
import React from "react";

import { c } from "../../styles/constants";
import useNewAccount from "./useNewAccount";
import { txt } from "../../styles/typestyles";
import InputComponent from "../../Components/InputComponent";
import ValidationLoader from "../../Components/ValidationLoader";
const NewAccount = ({ route, navigation }) => {
  const { addAccount } = route.params;
  const {
    accountInfo,
    merchantData: { user_id_number },
    errors,
    handleChange,
    loading,
    validate,
    encryptId,
  } = useNewAccount({ addAccount, navigation });

  const encryptedId = encryptId(user_id_number);
  const banks = [
    {
      id: "nequi",
      image: require("../../assets/nequi-logo.png"),
    },
    {
      id: "bancolombia",
      image: require("../../assets/logo-bancolombia.png"),
    },
    {
      id: "BBVA",
      image: {
        uri: "https://www.bbva.com/wp-content/uploads/2019/04/Logo-BBVA-1920x1080.jpg",
      },
    },
    {
      name: "Otro banco",
      id: "otro",
    },
  ];

  return (
    <ScrollView>
      <ValidationLoader visible={loading} />
      <View style={{ padding: c.spacing.spacing_md }}>
        <Text>
          Recuerda que la cuenta bancaria que vas a registrar debe estar
          vinculada al "número de documento" registrado en Qentaz
        </Text>
        <View
          style={[
            styles.input,
            { borderColor: c.bordersDividers.borderSecondary },
          ]}
        >
          <Text style={{ color: c.textIcons.disabled }}>{encryptedId}</Text>
        </View>
        <Text style={[txt.lg_strong, { marginTop: c.spacing.spacing_md }]}>
          Selecciona tu banco
        </Text>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <View style={styles.banksContainer}>
            {banks.map((bank) => (
              <Pressable
                key={bank.image}
                onPress={() => handleChange("bankAccount", bank.id)}
              >
                <View
                  style={[
                    styles.bankButton,
                    accountInfo.bankAccount === bank.id && {
                      borderColor: c.themeColors.primaryBase,
                      backgroundColor: c.backgrounds.bgContainer,
                    },
                  ]}
                >
                  {bank.image ? (
                    <Image
                      source={bank.image}
                      resizeMode="contain"
                      style={{ width: 100, height: 50 }}
                    />
                  ) : (
                    <Text
                      style={[
                        txt.lg_strong,
                        { marginBottom: c.spacing.spacing_sm },
                      ]}
                    >
                      {bank.name}
                    </Text>
                  )}
                  <View style={styles.bankBadge}>
                    <Text style={styles.bankBadge_caption}>Gratis</Text>
                  </View>
                </View>
              </Pressable>
            ))}
          </View>
        </ScrollView>
        {errors.bankAccount && (
          <Text style={[txt.sm_strong, styles.errorText]}>
            {errors.bankAccount}
          </Text>
        )}
        {!_.isEmpty(accountInfo.bankAccount) && (
          <>
            {accountInfo.bankAccount === "otro" && (
              <InputComponent
                label="Nombre del banco"
                placeholder="banco"
                error={errors.bankName}
                onChangeText={(val) => handleChange("bankName", val)}
              />
            )}
            <InputComponent
              isNumeric
              label={
                accountInfo.bankAccount === "nequi"
                  ? "Número de celular"
                  : "Número de cuenta"
              }
              placeholder="1122334455"
              error={errors.accountNumber}
              onChangeText={(val) => handleChange("accountNumber", val)}
            />
            {accountInfo.bankAccount !== "nequi" && (
              <>
                <View style={styles.accountTypeContainer}>
                  <View
                    style={[
                      styles.accountTypeButton,
                      accountInfo.accountType === "cAhorros" &&
                        styles.accountTypeButton_selected,
                    ]}
                  >
                    <Pressable
                      onPress={() => handleChange("accountType", "cAhorros")}
                    >
                      <Text style={txt.base_strong}>Ahorros</Text>
                    </Pressable>
                  </View>
                  <View
                    style={[
                      styles.accountTypeButton,
                      accountInfo.accountType === "cCorriente" &&
                        styles.accountTypeButton_selected,
                    ]}
                  >
                    <Pressable
                      onPress={() => handleChange("accountType", "cCorriente")}
                    >
                      <Text style={txt.base_strong}>Corriente</Text>
                    </Pressable>
                  </View>
                </View>
                {errors.accountType && (
                  <Text style={[txt.sm_strong, styles.errorText]}>
                    {errors.accountType}
                  </Text>
                )}
              </>
            )}
            <Pressable onPress={validate}>
              <View style={styles.mainButton}>
                <Text style={txt.lg_strong}>Validar mi identidad</Text>
              </View>
            </Pressable>
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default NewAccount;

const styles = StyleSheet.create({
  banksContainer: {
    marginTop: c.spacing.spacing_xx_sm,
    flexDirection: "row",
  },
  bankButton: {
    width: 110,
    height: 106,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    marginRight: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    backgroundColor: c.backgrounds.bgContainerDisabled,
  },
  input: {
    borderWidth: 1,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_xx_sm,
    backgroundColor: c.backgrounds.bgContainer,
  },
  bankBadge: {
    marginTop: c.spacing.spacing_xxx_sm,
    padding: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
    borderWidth: 1,
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.success.successBg,
  },
  bankBadge_caption: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_medium,
    color: c.themeColors.primaryBase,
  },
  accountTypeContainer: {
    marginTop: c.spacing.spacing_md,
    width: "100%",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: c.bordersDividers.border,
    borderRadius: c.borderRadius.br_xs,
    backgroundColor: c.backgrounds.bgLayout,
    padding: 5,
  },
  accountTypeButton: {
    width: "50%",
    alignItems: "center",
    paddingHorizontal: c.spacing.spacing_sm,
    paddingVertical: c.spacing.spacing_xxx_sm,
    borderRadius: c.borderRadius.br_xs,
  },
  accountTypeButton_selected: {
    backgroundColor: c.themeColors.primaryBg,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
});
