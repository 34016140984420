import React, { useState } from "react";
import { StyleSheet, Text, View, Pressable, TextInput } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";

const Pay = () => {
  const [step, setStep] = useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const toggleCheckbox = () => setCheckbox(!checkbox);
  return (
    <View style={{ padding: c.spacing.spacing_md }}>
      {step !== 2 ? (
        <>
          <Pressable>
            <View style={styles.scanButton}>
              <Icon name="camera" size={23} />
              <Text
                style={[
                  txt.lg_strong,
                  { marginRight: c.spacing.spacing_xx_sm },
                ]}
              >
                Escanear el recibo de pago
              </Text>
            </View>
          </Pressable>
          <Divider />
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>Proveedor del servicio</Text>
            <TextInput
              style={styles.input}
              placeholder="Escribe el nombre del servicio a pagar"
              placeholderTextColor={c.textIcons.placeholder}
            />
          </View>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>
              Referencia de pago o No. de contrato
            </Text>
            <TextInput style={styles.input} placeholder="11223334455" />
          </View>

          {step === 1 && (
            <>
              <View style={{ marginTop: c.spacing.spacing_md }}>
                <Text style={txt.lg_strong}>Valor a pagar</Text>
                <TextInput
                  style={styles.input}
                  placeholder="$35.460"
                  editable={false}
                />
              </View>
              {checkbox && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{ marginTop: c.spacing.spacing_md, width: "48%" }}
                  >
                    <Text style={txt.lg_strong}>Fecha de recordatorio</Text>
                    <TextInput
                      style={styles.input}
                      placeholder="5 de cada mes"
                      placeholderTextColor={c.textIcons.placeholder}
                    />
                  </View>
                  <View
                    style={{ marginTop: c.spacing.spacing_md, width: "48%" }}
                  >
                    <Text style={txt.lg_strong}>Fecha limite</Text>
                    <TextInput
                      style={styles.input}
                      placeholder="10 de cada mes"
                      placeholderTextColor={c.textIcons.placeholder}
                    />
                  </View>
                </View>
              )}
              <View style={styles.checkboxContainer}>
                <Pressable onPress={toggleCheckbox}>
                  <Icon
                    name={checkbox ? "checkbox" : "square-outline"}
                    size={20}
                    color={c.themeColors.primaryBase}
                  />
                </Pressable>
                <Text style={{ marginLeft: c.spacing.spacing_xxx_sm }}>
                  Recordarme esta factura cada mes
                </Text>
              </View>
            </>
          )}
          <Pressable onPress={() => setStep(step + 1)}>
            <View style={styles.mainButton}>
              <Text style={txt.lg_strong}>
                {step === 1 ? "Pagar factura" : "Consultar información"}
              </Text>
            </View>
          </Pressable>
          {step === 1 && (
            <Pressable>
              <View style={[styles.mainButton, styles.cancelButton]}>
                <Text style={[txt.lg_strong, { color: c.error.errorBase }]}>
                  Cancelar
                </Text>
              </View>
            </Pressable>
          )}
        </>
      ) : (
        <View style={styles.successContainer}>
          <View style={{ alignItems: "center" }}>
            <Icon
              name="checkmark-circle"
              size={48}
              color={c.themeColors.primaryBase}
            />
            <Text style={[txt.xl_strong, { marginTop: c.spacing.spacing_sm }]}>
              ¡Transacción exitosa!
            </Text>
          </View>
          <Divider />
          <Text style={[txt.xl_normal, { marginTop: c.spacing.spacing_big }]}>
            Resumen de la compra
          </Text>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Valor de la transacción:</Text>
            <Text style={txt.lg_normal}>$15.000</Text>
          </View>
          <Text
            style={[
              txt.lg_normal,
              {
                color: c.textIcons.placeholder,
                marginTop: c.spacing.spacing_md,
              },
            ]}
          >
            Este pago corresponde a la compra de productos electrónicos
            realizada en la tienda en línea "ElectroTech"...
          </Text>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Cliente:</Text>
            <Text style={txt.lg_normal}>Cristian Muñoz</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Telefono:</Text>
            <Text style={txt.lg_normal}>+57 3208051514</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Medio de pago:</Text>
            <Text style={txt.lg_normal}>Tarjeta de crédito</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Modo de pago:</Text>
            <Text style={txt.lg_normal}>Bancolombia</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Código de transacción:</Text>
            <Text style={txt.lg_normal}>A5H3U</Text>
          </View>
          <Pressable>
            <View style={styles.mainButton}>
              <Text style={txt.lg_strong}>Ver comprobante</Text>
            </View>
          </Pressable>
          <Pressable>
            <View
              style={[
                styles.mainButton,
                { backgroundColor: c.themeColors.primaryHoverBg },
              ]}
            >
              <Text style={txt.lg_strong}>Programar mis facturas</Text>
            </View>
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default Pay;

const styles = StyleSheet.create({
  scanButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    backgroundColor: c.themeColors.primaryBg,
    paddingHorizontal: c.spacing.spacing_x_big,
  },
  input: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    marginTop: c.spacing.spacing_xx_sm,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginTop: c.spacing.spacing_md,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  checkboxContainer: {
    flexDirection: "row",
    marginTop: c.spacing.spacing_md,
    alignItems: "center",
  },
  cancelButton: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
  },
  successContainer: {
    paddingVertical: c.spacing.spacing_big,
    paddingHorizontal: c.spacing.spacing_md,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
});
