import {
  ActivityIndicator,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import _ from "lodash";
import React from "react";

import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";

const MultipleDispersion = ({
  file,
  loadFile,
  fileError,
  generateCsv,
  pickDocument,
  clearDocument,
}) => {
  const excelUrl =
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vS7bDTkozzmb5FfZICz7jzE0exJWmdN4GWPTJEedVV9B3v_c8xrM54ySdacnM9xKYvRxBJ_fEW8zqTl/pub?output=xlsx";
  const sheetsUrl =
    "https://docs.google.com/spreadsheets/d/1fNord6-GKBPTiMzv7jfqlsDoAqaUY3O5VCszhGVNnew/edit?usp=sharing";

  return (
    <View style={styles.optionContainer}>
      <Text style={styles.optionTitle}>Dispersión múltiple</Text>
      <Text
        style={{
          marginVertical: c.spacing.spacing_md,
          fontSize: 16,
          lineHeight: c.lineHeight.lh3,
          textAlign: "justify",
        }}
      >
        Al realizar una dispersión múltiple, envías dos o más pagos
        independientes a la vez, a través de una hoja de cálculo. Descarga un
        ejemplo en{" "}
        <Pressable onPress={() => Linking.openURL(excelUrl)}>
          <Text style={styles.template}>Excel</Text>
        </Pressable>{" "}
        o{" "}
        <Pressable onPress={() => Linking.openURL(sheetsUrl)}>
          <Text style={styles.template}>Google Sheets</Text>
        </Pressable>
        , rellénalo con los datos de tus pagos y cárgalo abajo en formato .csv.
      </Text>
      <View style={styles.fileInput}>
        <Text numberOfLines={1} style={{ maxWidth: 200 }}>
          {!_.isEmpty(file) && file[0]?.name}
        </Text>
        <Pressable onPress={file ? clearDocument : pickDocument}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon
              name={file ? "trash-outline" : "cloud-upload-outline"}
              size={20}
              color={file ? c.error.errorBase : c.desktopColors.desktopBase}
            />
            <Text
              style={{
                marginLeft: c.spacing.spacing_xx_sm,
                color: file ? c.error.errorBase : c.desktopColors.desktopBase,
              }}
            >
              {file ? "Eliminar" : "Subir"}
            </Text>
          </View>
        </Pressable>
      </View>
      {fileError && (
        <Text
          style={{
            marginTop: c.spacing.spacing_sm,
            fontWeight: c.fontWeight.fw_medium,
            color: c.error.errorText,
          }}
        >
          {fileError}
        </Text>
      )}
      <Text
        style={[
          txt.sm_normal,
          {
            marginTop: c.spacing.spacing_md,
            color: c.textIcons.description,
          },
        ]}
      >
        Solo se aceptan archivos de tipo .csv. Tamaño máximo permitido 10 MB.
      </Text>
      {loadFile && (
        <View style={styles.fileLoader}>
          <ActivityIndicator size="large" color={c.desktopColors.desktopBase} />
          <Text
            style={{
              color: c.desktopColors.desktopBase,
              marginTop: c.spacing.spacing_sm,
            }}
          >
            Estamos validando el archivo, un momento por favor
          </Text>
        </View>
      )}
      {file && (
        <Pressable onPress={generateCsv}>
          <View style={styles.mainButton}>
            <Text style={{ color: c.backgrounds.bgContainer }}>
              Generar dispersion múltiple
            </Text>
          </View>
        </Pressable>
      )}
    </View>
  );
};

export default MultipleDispersion;

const styles = StyleSheet.create({
  optionContainer: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
    paddingHorizontal: c.spacing.spacing_xx_big,
  },
  optionTitle: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: c.fontWeight.fw_bold,
    color: c.desktopColors.desktopTitle,
  },
  template: {
    fontWeight: c.fontWeight.fw_bold,
    color: c.desktopColors.desktopBase,
    textDecorationLine: c.textDecotation.td_underline,
  },
  fileLoader: {
    textAlign: "center",
    justifyContent: "center",
    marginTop: c.spacing.spacing_md,
  },
  fileInput: {
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  mainButton: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
});
