import { StyleSheet, Text, View } from "react-native";
import React from "react";
import InputComponent from "./InputComponent";

const ChargeNow = ({ handleChange, errors, tx }) => {
  return (
    <>
      <InputComponent
        isNumeric
        value={tx?.amount}
        error={errors?.value}
        placeholder="$12.345"
        label="Valor del cobro"
        onChangeText={(val) => handleChange("value", val)}
      />
      <InputComponent
        value={tx?.description}
        error={errors?.description}
        placeholder="Camiseta negra"
        label="Descripción del cobro"
        onChangeText={(val) => handleChange("description", val)}
      />
      <InputComponent
        value={tx?.receiverName}
        label="Nombre del cliente"
        error={errors?.clientName}
        placeholder="Christian Muñoz"
        onChangeText={(val) => handleChange("clientName", val)}
      />
      <InputComponent
        isNumeric
        value={tx?.receiverPhone}
        placeholder="311 222 3333"
        error={errors?.clientPhone}
        label="Número de WhatsApp del cliente"
        onChangeText={(val) => handleChange("clientPhone", val)}
      />
    </>
  );
};

export default ChargeNow;

const styles = StyleSheet.create({});
