import React, { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { banksArray } from "../utils";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
import InputComponent from "./InputComponent";
import DropdownComponent from "./DropdownComponent";

const IndividualDispersion = ({ errors, control, handleDispersion, }) => {
  const [selectBank, setSelectBank] = useState("");
  const [selectAccount, setSelectAccount] = useState("");
  const [selectIdType, setSelectIdType] = useState("");

  return (
    <View style={styles.optionContainer}>
      <Text style={styles.optionTitle}>Dispersión individual</Text>
      <View style={styles.spacedRow}>
        <View style={[{ width: "48%" }, styles.spacedRow]}>
          <View style={{ width: "30%" }}>
            <Text style={styles.label}>Moneda</Text>
            <View style={styles.input}>
              <Text style={{ color: c.textIcons.placeholder }}>COP</Text>
            </View>
          </View>
          <View style={{ width: "66%" }}>
            <InputComponent
              isNumeric
              label="Valor"
              placeholder="$200.000"
              error={errors?.amount}
              onChangeText={(val) => handleDispersion("amount", val)}
            />
          </View>
        </View>
        <View style={{ width: "48%" }}>
          <InputComponent
            label="Detalle"
            error={errors?.description}
            placeholder="Camiseta negra"
            onChangeText={(val) => handleDispersion("description", val)}
          />
        </View>
      </View>
      <View style={styles.spacedRow}>
        <View style={{ width: "48%" }}>
          <InputComponent
            label="Destinatario"
            placeholder="Nombre"
            error={errors?.receiverName}
            onChangeText={(val) => handleDispersion("receiverName", val)}
          />
        </View>
        <View style={{ width: "48%" }}>
          <Text style={styles.label}>Tipo y número de documento</Text>
          <View style={styles.spacedRow}>
            <View style={{ width: "50%" }}>
              <DropdownComponent
                error={errors?.idType}
                selected={selectIdType}
                options={["CC", "CE", "NIT"]}
                selectOption={setSelectIdType}
                saveValue={(val) => handleDispersion("idType", val)}
              />
              <Text style={[txt.sm_strong, styles.errorText]}>
                {errors?.idType}
              </Text>
            </View>
            <View style={{ width: "46%" }}>
              <InputComponent
                isNumeric
                error={errors?.idNumber}
                placeholder="00.0000.0000"
                onChangeText={(val) => handleDispersion("idNumber", val)}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={styles.spacedRow}>
        <View style={{ width: "48%" }}>
          <InputComponent
            isNumeric
            placeholder="311222333"
            error={errors?.receiverPhone}
            label="Celular del destinatario"
            onChangeText={(val) => handleDispersion("receiverPhone", val)}
          />
        </View>
        <View style={{ width: "48%" }}>
          <InputComponent
            error={errors?.email}
            label="Correo electrónico"
            placeholder="correo@ejemplo.com"
            onChangeText={(val) => handleDispersion("email", val)}
          />
        </View>
      </View>
      <View style={styles.spacedRow}>
        <View style={{ width: "48%" }}>
          <Text style={styles.label}>Banco de destino</Text>
          <DropdownComponent
            error={errors?.bank}
            selected={selectBank}
            options={banksArray.sort()}
            selectOption={setSelectBank}
            saveValue={(val) => handleDispersion("bank", val)}
          />
          <Text style={[txt.sm_strong, styles.errorText]}>{errors?.bank}</Text>
        </View>
        <View style={{ width: "48%" }}>
          <Text style={styles.label}>País</Text>
          <View style={styles.input}>
            <Text style={{ color: c.textIcons.placeholder }}>Colombia</Text>
          </View>
        </View>
      </View>
      <View style={styles.spacedRow}>
        <View style={{ width: "48%" }}>
          <Text style={styles.label}>Tipo de cuenta</Text>
          <DropdownComponent
            selected={selectAccount}
            error={errors?.accountType}
            selectOption={setSelectAccount}
            options={["CORRIENTE", "AHORROS"]}
            saveValue={(val) => handleDispersion("accountType", val)}
          />
          <Text style={[txt.sm_strong, styles.errorText]}>
            {errors?.accountType}
          </Text>
        </View>
        <View style={{ width: "48%" }}>
          <InputComponent
            label="Número de cuenta"
            placeholder="00-0000000000"
            error={errors?.accountNumber}
            onChangeText={(val) => handleDispersion("accountNumber", val)}
          />
        </View>
      </View>
      <Pressable onPress={control}>
        <View style={styles.mainButton}>
          <Text style={{ color: c.backgrounds.bgContainer }}>
            Generar Dispersión
          </Text>
        </View>
      </Pressable>
    </View>
  );
};

export default IndividualDispersion;

const styles = StyleSheet.create({
  optionContainer: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
    paddingHorizontal: c.spacing.spacing_xx_big,
  },
  optionTitle: {
    fontSize: 18,
    marginBottom: 5,
    fontWeight: c.fontWeight.fw_bold,
    color: c.desktopColors.desktopTitle,
  },
  mainButton: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  input: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    paddingVertical: c.spacing.spacing_sm,
    borderColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  errorText: {
    marginTop: c.spacing.spacing_xx_sm,
    color: c.error.errorText,
  },
});
