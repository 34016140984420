import { postData } from "../api-call";
import { BASE_URL, DOWNLOAD_REPORT_HOOK, TRANSACTIONS_QUERY } from "../config";

const getMerchantFinancesData = ({
  hashId,
  from,
  type,
}: MerchantFinancesDataProps) => {
  const data = { hashId, from, type };
  const requestUrl = `${BASE_URL}${TRANSACTIONS_QUERY}`;

  return postData(requestUrl, data);
};

const downloadReport = ({ role, phone, merchant_name }) => {
  const data = { role, phone, merchant_name };
  const requestUrl = `${DOWNLOAD_REPORT_HOOK}`;
  return postData(requestUrl, data);
};
export const DashboardApi = {
  getMerchantFinancesData,
  downloadReport,
};

interface MerchantFinancesDataProps {
  hashId: string;
  from: string;
  type: string;
}
