import { StyleSheet } from "react-native";

import { c } from "./constants";
export const txt = StyleSheet.create({
  base_normal: {
    fontSize: c.fontSize.fs,
    fontWeight: c.fontWeight.fw_normal,
  },
  base_strong: {
    fontSize: c.fontSize.fs,
    fontWeight: c.fontWeight.fw_bold,
  },
  base_underline: {
    fontSize: c.fontSize.fs,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_underline,
  },
  base_delete: {
    fontSize: c.fontSize.fs,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_lineThrough,
  },
  base_italic: {
    fontSize: c.fontSize.fs,
    fontWeight: c.fontWeight.fw_normal,
    fontStyle: c.fontStyle.fs_italic,
  },
  sm_normal: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_normal,
  },
  sm_strong: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_bold,
  },
  sm_underline: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_underline,
  },
  sm_delete: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_lineThrough,
  },
  sm_italic: {
    fontSize: c.fontSize.fs_sm,
    fontWeight: c.fontWeight.fw_normal,
    fontStyle: c.fontStyle.fs_italic,
  },
  lg_normal: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_normal,
  },
  lg_strong: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_bold,
  },
  lg_underline: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_underline,
  },
  lg_delete: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_lineThrough,
  },
  lg_italic: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_normal,
    fontStyle: c.fontStyle.fs_italic,
  },
  xl_normal: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_normal,
  },
  xl_strong: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_bold,
  },
  xl_underline: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_underline,
  },
  xl_delete: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_normal,
    textDecorationLine: c.textDecotation.td_lineThrough,
  },
  xl_italic: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_normal,
    fontStyle: c.fontStyle.fs_italic,
  },
  heading1: {
    fontSize: c.fontSize.fs_x_huge,
    fontWeight: c.fontWeight.fw_bold,
  },
  heading2: {
    fontSize: c.fontSize.fs_huge,
    fontWeight: c.fontWeight.fw_bold,
  },
  heading3: {
    fontSize: c.fontSize.fs_x_big,
    fontWeight: c.fontWeight.fw_bold,   
  },
  heading4: {
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
  },
  heading5: {
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_bold,
  },
});
