import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import AppHeader from "./AppHeader/AppHeader";
import PaymentShecule from "../Screens/PaymentSchedule/PaymentShecule";
import Program from "../Screens/Program/Program";
const Stack = createStackNavigator();

const ProgramNavigator = () => {
  return (
    <Stack.Navigator
      headerMode="screen"
      screenOptions={() => ({
        header: ({ navigation, route }) => (
          <AppHeader navigation={navigation} title={route.name} />
        ),
      })}
    >
      <Stack.Screen name="Programar" component={Program} />
      <Stack.Screen name="Programar cobro" component={PaymentShecule} />
    </Stack.Navigator>
  );
};

export default ProgramNavigator;

const styles = StyleSheet.create({});
