import React, { useState, useEffect } from "react";
import { getHashId, checkProperty, parseJwt } from "../../helpers/src";
import { ContactInfoApi } from "../../api/src/contact-info";

export const useHeaderInfo = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");

  const [merchantInfo, setMerchantInfo] = useState({});

  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      setMerchantInfo({
        nickname: checkProperty(x?.contact?.attributes?.nickname),
        image:
          checkProperty(
            x?.contact?.attributes?.businesses?.data[0]?.attributes?.logo
          ) || require("../../assets/qz-pay-logo.jpg"),
      });
    });
  }, []);
  return {
    merchantInfo,
  };
};
