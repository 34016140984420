import { useState } from "react";
import { Keyboard } from "react-native";

import { OtpAPI } from "../../api/src/create-otp";
import { checkProperty } from "../../helpers/src";
import { ContactInfoPhoneApi } from "../../api/src/contact-info-by-phone";

const useRegister = ({ navigation }) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const validate = () => {
    if (phone) {
      ContactInfoPhoneApi.getInfo({ id: "57" + phone }).then((x) => {
        const merchantPhone = checkProperty(x?.contact?.attributes?.phone);
        const merchantRole = checkProperty(x?.contact?.attributes?.role);
        const formatedPhone =
          typeof merchantPhone === "string" && merchantPhone.slice(2);

        Keyboard.dismiss();

        if (phone !== formatedPhone) {
          setError(
            "¡Lo sentimos! Tu número no está registrado en nuestra base de datos."
          );
        } else if (phone.length !== 10) {
          setError("Ingresa un número telefónico válido");
        } else if (merchantRole === "member") {
          setError("¡Lo sentimos! No cuentas con los permisos suficientes.");
        } else {
          createOtp();
          navigation.navigate("OTP", { phone });
        }
      });
    } else {
      setError("Este campo es requerido");
    }
  };

  const createOtp = async () => {
    OtpAPI.createOtp({ id: "57" + phone });
  };

  return { setPhone, error, validate, createOtp };
};

export default useRegister;
