import { getAllInfoByISO } from "iso-country-currency";
import {
  DEFAULT_CURRENCY_SYMBOL,
  HASH_ID_QUERY,
  KEY_VALUE_CURRENCY,
  PERIOD_TIME_QUERY,
} from "../../Screens/config";

const checkProperty = (value) => value || 0;
const getHashId = (path) => path.replace(HASH_ID_QUERY, "");
const getDate = (path) => path?.replace(PERIOD_TIME_QUERY, "");

const getCurrencySymbol = (code) => {
  const { symbol } = getAllInfoByISO(code, KEY_VALUE_CURRENCY) || {
    symbol: DEFAULT_CURRENCY_SYMBOL,
  };

  return symbol.slice(-1);
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export { getHashId, checkProperty, getDate, getCurrencySymbol, parseJwt };
