import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { StyleSheet, Text, View, ScrollView, Pressable } from "react-native";

import useUsers from "./useUsers";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
import Divider from "../../Components/Divider";
import UsersList from "../../Components/UsersList";
import InputComponent from "../../Components/InputComponent";
import NewUserDetails from "../../Components/NewUserDetails";
import Loader from "../../Components/Loader";
const Users = ({ navigation }) => {
  const {
    tab,
    setTab,
    infoSent,
    userRole,
    toggleEdit,
    userErrors,
    isEditable,
    newUserInfo,
    setUserRole,
    handleChange,
    isValidated,
    validateInfo,
    createNewUser,
    setInfoSent,
    validationLoader,
  } = useUsers();
  const tabs = [
    {
      id: "users",
      title: "Usuarios",
      icon: "people-circle",
    },
    {
      id: "addUser",
      title: "Agregar usuarios",
      icon: "add-circle",
    },
  ];
  const team = [
    { name: "MANUEL PAREDES", role: "Propietario", phone: "3113384395" },
    {
      name: "DANIELA ALDANA VALENCIA",
      role: "Administrador",
      phone: "3103922795",
    },
    { name: "JUAN CAMILO MUÑOZ APRAEZ", role: "Análista", phone: "3116999973" },
    { name: "SOL BROUCKAERT", role: "Miembro", phone: "92396623255" },
  ];
  const rolesOptions = [
    {
      role: "admin",
      title: "Admin",
      description: "Tiene acceso a todas las funcionalidades.",
    },
    {
      role: "member",
      title: "Miembro",
      description:
        "Puede generar todo tipo de cobros (WhatsApp, programados y masivos) y acceder a su reporte individual y descargarlo.",
    },
    {
      role: "analyst",
      title: "Análista",
      description:
        "Puede generar cobros a través de WhatsApp y acceder a su reporte individual con la información de su propio trabajo",
    },
  ];
  return (
    <>
      {!infoSent ? (
        <ScrollView>
          <View style={{ padding: c.spacing.spacing_md }}>
            <View style={styles.screenButtonsContainer}>
              {tabs.map((t) => (
                <View
                  key={t.id}
                  style={[
                    styles.screenButton,
                    tab === t.id && styles.screenButton_selected,
                  ]}
                >
                  <Pressable
                    onPress={() => {
                      setTab(t.id);
                    }}
                  >
                    <Icon
                      name={tab === t.id ? t.icon : `${t.icon}-outline`}
                      size={35}
                      color={
                        tab === t.id
                          ? c.themeColors.primaryBase
                          : c.textIcons.icon
                      }
                    />
                    <Text
                      style={[
                        txt.base_normal,
                        {
                          color:
                            tab === t.id
                              ? c.themeColors.primaryBase
                              : c.textIcons.icon,
                        },
                      ]}
                    >
                      {t.title}
                    </Text>
                  </Pressable>
                </View>
              ))}
            </View>

            <Divider />
            {tab !== "" &&
              (tab === "users" ? (
                <View style={{ marginTop: c.spacing.spacing_big }}>
                  <Text style={txt.xl_strong}>Usuarios Actuales</Text>
                  <View style={{ marginTop: c.spacing.spacing_md }}>
                    {team.map((u) => (
                      <UsersList
                        key={u.phone}
                        name={u.name}
                        role={u.role}
                        phone={u.phone}
                        isEditable={isEditable}
                      />
                    ))}
                  </View>
                  <View style={styles.mainButton}>
                    <Pressable onPress={toggleEdit}>
                      <Text style={txt.lg_strong}>
                        {isEditable ? "Cancelar" : "Editar usuarios"}
                      </Text>
                    </Pressable>
                  </View>
                </View>
              ) : (
                <View>
                  <InputComponent
                    label="Nombre de usuario"
                    placeholder="Escribe el nombre"
                    onChangeText={(val) => handleChange("newUserName", val)}
                    error={userErrors.newUserName}
                  />
                  <InputComponent
                    isNumeric
                    label="Número de WhatsApp del usuario"
                    placeholder="Escribe el teléfono"
                    onChangeText={(val) => handleChange("newUserPhone", val)}
                    error={userErrors.newUserPhone}
                  />
                  <Text style={styles.label}>Tipo de usuario</Text>
                  <View style={styles.userRoleButtonContainer}>
                    {rolesOptions.map((role) => (
                      <View
                        key={role.role}
                        style={[
                          styles.userRoleButton,
                          role.role === userRole && {
                            backgroundColor: c.themeColors.primaryBg,
                          },
                        ]}
                      >
                        <Pressable
                          onPress={() => {
                            setUserRole(role.role);
                            handleChange("newUserRole", role.role);
                          }}
                        >
                          <Text style={txt.base_strong}>{role.title}</Text>
                        </Pressable>
                      </View>
                    ))}
                  </View>
                  {userErrors.newUserRole && (
                    <Text style={[txt.sm_strong, styles.errorText]}>
                      {userErrors.newUserRole}
                    </Text>
                  )}
                  {rolesOptions.map(
                    (role) =>
                      role.role === userRole && (
                        <Text
                          key={role.role}
                          style={{
                            marginTop: c.spacing.spacing_x_big,
                            color: c.textIcons.description,
                          }}
                        >
                          {role.description}
                        </Text>
                      )
                  )}
                  <View style={styles.mainButton}>
                    <Pressable onPress={validateInfo}>
                      <Text style={txt.lg_strong}>Invitar usuario</Text>
                    </Pressable>
                  </View>
                </View>
              ))}
          </View>
        </ScrollView>
      ) : (
        <>
          <Loader visible={validationLoader} />
          <NewUserDetails
            navigation={navigation}
            isValidated={isValidated}
            action={() => setInfoSent(false)}
            validateInfoAction={createNewUser}
            newUserName={newUserInfo.newUserName}
            newUserRole={newUserInfo.newUserRole}
            newUserPhone={newUserInfo.newUserPhone}
          />
        </>
      )}
    </>
  );
};

export default Users;

const styles = StyleSheet.create({
  screenButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: c.spacing.spacing_md,
  },
  screenButton: {
    width: "49%",
    borderWidth: 1,
    textAlign: "center",
    justifyContent: "center",
    padding: c.spacing.spacing_xx_sm,
    borderRadius: c.borderRadius.br_sm,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  screenButton_selected: {
    borderColor: c.themeColors.primaryBorder,
    backgroundColor: c.themeColors.primaryBg,
  },
  mainButton: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_lg,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_x_big,
    backgroundColor: c.themeColors.primaryBase,
  },
  errorText: {
    color: c.error.errorText,
    marginTop: c.spacing.spacing_xx_sm,
  },
  label: {
    fontSize: c.fontSize.fs_md,
    marginTop: c.spacing.spacing_md,
    fontWeight: c.fontWeight.fw_medium,
    marginBottom: c.spacing.spacing_xx_sm,
  },
  userRoleButtonContainer: {
    padding: 5,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    borderRadius: c.borderRadius.br_sm,
    backgroundColor: c.backgrounds.bgLayout,
  },
  userRoleButton: {
    width: "32%",
    paddingVertical: 6,
    textAlign: "center",
    borderRadius: c.borderRadius.br_xs,
    paddingHorizontal: c.spacing.spacing_xxx_sm,
  },
});
