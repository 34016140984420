export const c = {
  desktopColors: {
    desktopBase: "#0F5C38",
    desktopBg: "#D4F8DF",
    desktopTxt: "#169252",
    desktopTitle: "#2A393A",
  },
  themeColors: {
    primaryBg: "#eafaf3",
    primaryHoverBg: "#d6f5e7",
    primaryBorder: "#c1f0db",
    primaryBorderHover: "#acebcf",
    primaryHover: "#83e2b8",
    primaryBase: "#30ce88",
    primaryActive: "#26a56d",
    primaryTextHover: "#1d7c52",
    primaryText: "#0a291b",
    primaryTextActive: "#05150e",
  },
  success: {
    successBg: "#f6ffed",
    successHoverBg: "#d9f7be",
    successBorder: "#b7eb8f",
    successBorderHover: "#95de64",
    successHover: "#73d13d",
    successBase: "#52c41a",
    successActive: "#389e0d",
    successTextHover: "#237804",
    successText: "#135200",
    successTextActive: "#092b00",
  },
  warning: {
    warningBg: "#fffbe6",
    warningHoverBg: "#fff1b8",
    warningBorder: "#ffe58f",
    warningBorderHover: "#ffd666",
    warningHover: "#ffc53d",
    warningBase: "#faad14",
    warningActive: "#d48806",
    warningTextHover: "#ad6800",
    warningText: "#874d00",
    warningTextActive: "#613400",
  },
  error: {
    errorBg: "#fff1f0",
    errorHoverBg: "#ffccc7",
    errorBorder: "#ffa39e",
    errorBorderHover: "#ff7875",
    errorHover: "#ff7875", //Repetido
    errorBase: "#ff4d4f",
    errorActive: "#cf1322",
    errorTextHover: "#a8071a",
    errorText: "#820014",
    errorTextActive: "#5c0011",
  },
  info: {
    infoBg: "#eaf3ff",
    infoHoverBg: "#d5e6ff",
    infoBorder: "#c0daff",
    infoBorderHover: "#abcdff",
    infoHover: "#589bff",
    infoBase: "#2e82ff",
    infoActive: "#2568cd",
    infoTextHover: "#1c4f9b",
    infoText: "#123569",
    infoTextActive: "#091c37",
  },
  textIcons: {
    text: "#060d11",
    heading: "#15232b",
    description: "#848688",
    placeholder: "#a3a3a3",
    disabled: "#7a7a7a",
    icon: "#848688",
    iconHover: "#33373a",
  },
  backgrounds: {
    bgContainer: "#ffffff",
    bgLayout: "#f5f5f5",
    bgContainerDisabled: "#e9e9e9",
  },
  bordersDividers: {
    border: "#d7d7d7",
    borderSecondary: "#e1e1e1",
    split: "#e3e3e3",
  },
  lineHeight: {
    lh0: 22,
    lh1: 20,
    lh2: "100%",
    lh3: 24,
    lh4: 28,
    lh5: 46,
    lh6: 38,
    lh7: 32,
  },
  fontStyle: {
    fs_italic: "italic",
  },
  fontSize: {
    fs_sm: 12,
    fs: 14,
    fs_md: 16,
    fs_big: 20,
    fs_x_big: 24,
    fs_huge: 30,
    fs_x_huge: 38,
  },
  fontWeight: {
    fw_normal: "normal",
    fw_medium: "500",
    fw_light: "200",
    fw_bold: "bold",
  },
  textDecotation: {
    td_underline: "underline",
    td_lineThrough: "line-through",
  },
  spacing: {
    spacing_xxx_sm: 4,
    spacing_xx_sm: 8,
    spacing_sm: 12,
    spacing_md: 16,
    spacing_big: 20,
    spacing_x_big: 24,
    spacing_xx_big: 28,
    spacing_xxx_big: 32,
    spacing_lg: 40,
    spacing_x_lg: 48,
    spacing_xx_lg: 64,
    spacing_xxx_lg: 80,
    spacing_huge: 96,
    spacing_x_huge: 128,
    spacing_xx_huge: 160,
    spacing_xxx_huge: 192,
  },
  borderRadius: {
    br_base: 8,
    br_xs: 4,
    br_sm: 6,
    br_lg: 10,
  },
};
