import _ from "lodash";
import { useEffect, useState } from "react";

import { parseJwt } from "../../helpers/src";
import { checkProperty } from "../../helpers/src";
import { DashboardApi } from "../../api/src/dashboard";
import { BusinessInfoAPI } from "../../api/src/business";
import { ContactInfoApi } from "../../api/src/contact-info";

export const useActivity = () => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");

  const currencyFormat = Intl.NumberFormat("es-CO");
  const ITEMS_PER_PAGE = 16;

  const [date, setDate] = useState("Mes");
  const [businessName, setBussinessName] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [txPerPage, setTxPerPage] = useState([]);
  const [totalPages, setTotalpages] = useState(0);
  const [fetchData, setFechData] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [financeData, setFinanceData] = useState({});
  const [merchantData, setMerchantData] = useState({});

  const [isSelected, setIsSelected] = useState("Movimientos");

  // const filterByDate = (filterDate, country, counter) => {
  //   const { timezones } = getCountry(country);
  //   const [timeZone] = timezones;

  //   const dateOptions = {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   };

  //   const date = new Date();

  //   const dateMonth = new Date();
  //   let finalDate = "";
  //   const weekKey = "week";

  //   dateMonth.setMonth(dateMonth.getMonth() + counter);

  //   date.setDate(date.getDate() + counter);

  //   const formatDate = momentTimeZone(date).tz(timeZone);
  //   finalDate =
  //     months.indexOf(filterDate) !== -1 ||
  //     filterDate?.toLowerCase()?.includes("mes")
  //       ? months[dateMonth.getMonth()]
  //       : filterDate?.toLowerCase()?.includes("semana") ||
  //         filterDate.includes("week")
  //       ? weekKey + Math.abs(counter)
  //       : new Date(formatDate).toLocaleDateString("es-ES", dateOptions);

  //   return { firstDay: finalDate };
  // };
  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      BusinessInfoAPI.getBusinessByPhone({
        phone: checkProperty(x?.contact?.attributes?.phone),
      }).then((x) => {
        setBussinessName(checkProperty(x?.data?.business_name));
      });
      setMerchantData({
        user_country_symbol: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        user_country_code: checkProperty(
          x?.contact?.attributes?.user_country_code
        ),
        phone: checkProperty(x?.contact?.attributes?.phone),
        current_amount: checkProperty(
          x?.contact?.attributes?.accounts?.data[0]?.attributes?.current_amount
        ),
      });
      setFechData(false);
    });
  }, []);
  useEffect(() => {
    DashboardApi.getMerchantFinancesData({
      hashId: hashId,
      from: date,
      type: isSelected,
    }).then((x) => {
      setFinanceData({
        transactions: checkProperty(
          x?.movements?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        ),
        weeks_days: checkProperty(x?.weeks_days),
      });
    });
  }, [isSelected, financeData]);
  useEffect(() => {
    const total = Number(
      Math.floor(financeData?.transactions?.length / ITEMS_PER_PAGE)
    );
    setTotalpages(total);
    setTxPerPage(financeData?.transactions?.slice(0, ITEMS_PER_PAGE));
  }, [totalPages]);

  const saveReport = () => {
    try {
      DashboardApi.downloadReport({
        role: "owner",
        phone: "573112017222",
        merchant_name: "COMERCIO PRUEBA",
      }).then((x) => {
        const { url } = x;
        window.open(url);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (ticket) => {
    const urlTicket = `https://qentaz-pagos.s3.amazonaws.com/receipt-images/${ticket}.jpeg`;
    fetch(urlTicket, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Comprobante${businessName}-${ticket}.png`
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return {
    setDate,
    sortValue,
    fetchData,
    isSelected,
    saveReport,
    financeData,
    merchantData,
    setSortValue,
    setIsSelected,
    handleDownload,
    currencyFormat,
  };
};
