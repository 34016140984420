import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import React from "react";
import { c } from "../../styles/constants";
import Icon from "react-native-vector-icons/Ionicons";
import { txt } from "../../styles/typestyles";
import Divider from "../Divider";

const EditTransaction = ({
  visible,
  close,
  txTitle,
  txValue,
  txDate,
  txType,
}) => {
  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={close}
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,0.25)",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={styles.cardContainer}>
          <Pressable onPress={close}>
            <View style={styles.backButton_icon}>
              <Icon
                name="arrow-back-circle-outline"
                size={16}
                color={c.textIcons.icon}
              />
              <Text style={[txt.sm_normal, styles.backButton_txt]}>
                Regresar
              </Text>
            </View>
          </Pressable>
          <Text style={[styles.cardTitle]}>Editar {txType} programado</Text>
          <Divider />
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>Valor</Text>
            <TextInput style={styles.input} value={txValue} />
          </View>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>Descripción</Text>
            <TextInput style={styles.input} value={txTitle} />
          </View>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>Celular del cliente</Text>
            <TextInput style={styles.input} value="+57 311 222 3333" />
          </View>
          <View style={{ marginTop: c.spacing.spacing_md }}>
            <Text style={txt.lg_strong}>Fecha Limite</Text>
            <Pressable>
              <View style={[styles.input, styles.dateInput]}>
                <Text>{txDate}</Text>
                <Icon name="calendar" size={24} color={c.textIcons.icon} />
              </View>
            </Pressable>
          </View>
          <Pressable onPress={close}>
            <View
              style={[
                styles.button,
                { backgroundColor: c.themeColors.primaryBase },
              ]}
            >
              <Text style={styles.buttonTitle}>Guardar cambios</Text>
            </View>
          </Pressable>
          <Pressable onPress={close}>
            <View style={[styles.button, styles.deleteButton]}>
              <Text style={[styles.buttonTitle, { color: c.error.errorBase }]}>
                Eliminar
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default EditTransaction;

const styles = StyleSheet.create({
  cardContainer: {
    width: "85%",
    borderRadius: 12,
    alignSelf: "center",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  backButton_icon: {
    flexDirection: "row",
    alignSelf: "flex-end",
    alignItems: "center",
    marginVertical: c.spacing.spacing_sm,
  },
  backButton_txt: {
    color: c.textIcons.icon,
    marginLeft: c.spacing.spacing_xxx_sm,
  },
  cardTitle: {
    fontSize: c.fontSize.fs_big,
    fontWeight: c.fontWeight.fw_medium,
    marginTop: c.spacing.spacing_md,
    textAlign: "center",
  },

  input: {
    marginTop: c.spacing.spacing_xx_sm,
    paddingVertical: c.spacing.spacing_sm,
    paddingHorizontal: c.spacing.spacing_md,
    borderWidth: 1,
    borderRadius: c.borderRadius.br_xs,
    borderColor: c.bordersDividers.border,
  },
  dateInput: {
    alignItems:"center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_sm,
  },
  buttonTitle: {
    color: c.themeColors.primaryText,
    fontSize: c.fontSize.fs_md,
    fontWeight: c.fontWeight.fw_medium,
  },
  deleteButton: {
    borderWidth: 1,
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
  },
});
