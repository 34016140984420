import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Keyboard, Linking } from "react-native";
import { checkProperty, parseJwt } from "../../helpers/src";
import { ContactInfoApi } from "../../api/src/contact-info";
import { BankAccountAPI } from "../../api/src/create-bank-account";
import { CashoutAPI } from "../../api/src/create-cash-out";
import { DashboardApi } from "../../api/src/dashboard";
import similarity from "similarity";
import axios from "axios";

const useRetire = ({ navigation }) => {
  const token = sessionStorage.getItem("_auth_code_");
  const decodeToken = token ? atob(token) : null;
  const parseJWTData = parseJwt(decodeToken);
  const hashId = _.get(parseJWTData, "data");
  const currencyFormat = Intl.NumberFormat("es-CO");

  const [info, setInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [urlMetamap, setUrlMetamap] = useState("");
  const [checkInfo, setCheckInfo] = useState(false);
  const [ascendance, setAscendance] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [merchantData, setMerchantData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [headerOption, setHeaderOption] = useState(null);
  const [accountModal, setAccountModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [newAccountInfo, setNewAccountInfo] = useState({});
  const [newAccountErrors, setNewAccountErrors] = useState({});
  const [selectBankAccount, setSelectBankAccount] = useState({});
  const [validationLoader, setValidationLoader] = useState(false);
  const [showRetiredModal, setShowRetiredModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleNewAccount = (field, value) =>
    setNewAccountInfo((prev) => ({ ...prev, [field]: value }));

  const [bankList, setBankList] = useState("");
  const getFinancialInstitution = async () => {
    const {
      data: { data: financiaInstitutions },
    } = await axios.get(
      "https://production.wompi.co/v1/pse/financial_institutions",
      {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer prv_prod_ruPLrCuxPJX8GPSV3NLUOFUpIiM0JTVT",
        },
      }
    );
    financiaInstitutions?.shift();
    setBankList(
      financiaInstitutions?.filter(
        (x) => x.financial_institution_code !== "1802"
      )
    );
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const addAccount = (account) => setAccounts([...accounts, account]);

  const headers = [
    { header: "Solicitante" },
    { header: "Valor", action: () => setSortValue(amount) },
    { header: "Cuenta destino", action: () => setSortValue("number") },
    { header: "Fecha", action: () => setSortValue("created_at") },
    { header: "Estado", action: () => setSortValue("status") },
  ];

  const sortingTable = (a, b) =>
    ascendance
      ? typeof a[sortValue] === "number"
        ? a[sortValue] - b[sortValue]
        : a[sortValue]?.localeCompare(b[sortValue])
      : typeof a[sortValue] === "number"
      ? b[sortValue] - a[sortValue]
      : b[sortValue]?.localeCompare(a[sortValue]);
  const validate = () => {
    Keyboard.dismiss();
    let valid = true;
    if (!info.value) {
      handleError("Este campo es requerido", "value");
      valid = false;
    } else if (info.value < 0) {
      handleError("Escribe un valor válido", "value");
      valid = false;
    }
    if (!selectBankAccount) {
      handleError("Por favor elige una cuenta", "bankAccount");
      valid = false;
    }

    valid && setShowRetiredModal(true);
  };

  useEffect(() => {
    ContactInfoApi.getInfo({ id: hashId }).then((x) => {
      setMerchantData({
        user_country_symbol: checkProperty(
          x?.contact?.attributes?.user_country_symbol
        ),
        role: checkProperty(x?.contact?.attributes?.role),
        phone: checkProperty(x?.contact?.attributes?.phone),
        name: checkProperty(x?.contact?.attributes?.first_name),
        idType: checkProperty(x?.contact?.attributes?.user_id_type),
        userId: checkProperty(x?.contact?.attributes?.user_id_number),
        total_amount: checkProperty(
          x?.contact?.attributes?.accounts?.data[0]?.attributes?.total_amount
        ),
      });
      setCurrentAmount(
        checkProperty(
          x?.contact?.attributes?.accounts?.data[0]?.attributes?.current_amount
        )
      );
    });
    getFinancialInstitution();
  }, []);

  useEffect(() => {
    if (merchantData?.phone) {
      BankAccountAPI.getBankAccount({ id: merchantData?.phone }).then((x) => {
        const accounts = x?.data?.data;

        setBankAccounts(accounts);
      });
    }
  }, [merchantData?.phone, bankAccounts]);
  useEffect(() => {
    DashboardApi.getMerchantFinancesData({
      hashId,
      from: "Mes",
      type: "Retiros",
    }).then((x) =>
      setTransactions(
        checkProperty(
          x?.movements?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
        )
      )
    );
  }, [transactions]);
  const generateRetire = () => {
    setLoading(true);
    setCurrentAmount(currentAmount - Number(info?.value));
    CashoutAPI.createCashout({
      amount: info?.value,
      status: "PENDING",
      type: "cashout",
      bank_account: selectBankAccount?.bankId,
      contact: merchantData?.phone,
    });

    setTimeout(() => {
      setLoading(false);
      setCheckInfo(true);
    }, 3000);
  };

  const validateNewBankAccountInfo = () => {
    Keyboard.dismiss();
    let valid = true;
    if (!newAccountInfo?.accountType) {
      handleAccountError("Por favor elige un tipo de cuenta", "accountType");
      valid = false;
    }
    if (!newAccountInfo?.bankName) {
      handleAccountError("Por favor elige un banco", "bankName");
      valid = false;
    }
    if (!newAccountInfo.accountNumber) {
      handleAccountError("Este campo es requerido", "accountNumber");
      valid = false;
    } else if (
      Number(newAccountInfo?.accountNumber) < 0 ||
      newAccountInfo?.accountNumber.length > 11
    ) {
      handleAccountError("Escribe un número válido", "accountNumber");
      valid = false;
    }
    valid && validateUserInfo();
  };

  const handleChange = (field, value) => {
    setInfo((prev) => ({ ...prev, [field]: value }));
  };
  const handleError = (message, field) => {
    setErrors((prev) => ({ ...prev, [field]: message }));
  };
  const handleAccountError = (message, field) => {
    setNewAccountErrors((prev) => ({ ...prev, [field]: message }));
  };

  const validateUserInfo = () => {
    setValidationLoader(true);
    BankAccountAPI.validateId({
      user_id_type: merchantData?.idType,
      phone: merchantData?.phone,
    }).then((x) => {
      window.open(x.url_validation);
      setShowSuccessModal(true);
      setAccountModal(false);
    });
    createBankAccount();
  };
  const createBankAccount = () => {
    BankAccountAPI.createBankAccount({
      name_bank: String(newAccountInfo?.bankName).toUpperCase(),
      number: newAccountInfo?.accountNumber,
      contact: merchantData?.phone,
      business: 48,
      validation_id: "",
      validation_provider: "",
      validation_date: "",
      validation_confidence: "",
    });
    addAccount(newAccountInfo);
  };

  return {
    info,
    errors,
    headers,
    loading,
    validate,
    bankList,
    checkInfo,
    ascendance,
    addAccount,
    headerOption,
    transactions,
    handleChange,
    showDropdown,
    merchantData,
    bankAccounts,
    accountModal,
    sortingTable,
    setAscendance,
    currentAmount,
    currencyFormat,
    toggleDropdown,
    generateRetire,
    setHeaderOption,
    setAccountModal,
    handleNewAccount,
    validationLoader,
    showRetiredModal,
    newAccountErrors,
    showSuccessModal,
    selectBankAccount,
    setShowRetiredModal,
    setShowSuccessModal,
    setSelectBankAccount,
    validateNewBankAccountInfo,
  };
};

export default useRetire;
