import { StyleSheet, Text, View } from 'react-native'
import React from 'react'

const Pagination = () => {
  return (
    <View>
      <Text>Pagination</Text>
    </View>
  )
}

export default Pagination

const styles = StyleSheet.create({})