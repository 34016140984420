import { getData, postData } from "../api-call";
import {
  BUSINESS_QUERY,
  BASE_URL,
  CREATE_BANK_ACCOUNT_QUERY,
  CHECK_BANK_ACCOUNT_QUERY,
  GET_BUSINESS_BY_PHONE_QUERY,
} from "../config";

const getBusiness = ({ id }: { id: string }) => {
  const requestUrl = `${BASE_URL}${BUSINESS_QUERY}/${id}`;
  return getData(requestUrl);
};
const getBusinessByPhone = ({ phone }: { phone: string }) => {
  const requestUrl = `${BASE_URL}${GET_BUSINESS_BY_PHONE_QUERY}/${phone}`;

  return getData(requestUrl);
};

const createBankAccount = async ({ name_bank, number, business, contact }) => {
  const data = { name_bank, number, business, contact };
  const requestUrl = `${BASE_URL}${BUSINESS_QUERY}${CREATE_BANK_ACCOUNT_QUERY}`;
  return postData(requestUrl, data);
};
const getBankAccount = ({ id }) => {
  const requestUrl = `${BASE_URL}${CHECK_BANK_ACCOUNT_QUERY}/${id}`;
  return getData(requestUrl);
};
export const BusinessInfoAPI = {
  getBusiness,
  getBankAccount,
  createBankAccount,
  getBusinessByPhone,
};
