import {
  StyleSheet,
  Text,
  View,
  Pressable,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import React, { useState } from "react";
import { c } from "../styles/constants";
import StatusBadge from "./StatusBadge";
import TransactionDetails from "./Modal/TransactionDetails";

const TableComponent = ({
  headers,
  transactions,
  currencyFormat,
  countrySymbol,
  sortValue,
  handleDownload,
}) => {
  const [selected, setSelected] = useState("");
  const [ascendance, setAscendance] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [headerOption, setHeaderOption] = useState(false);
  const activeModal = (id) => {
    setSelected(id);
    setShowModal(true);
  };
  const sortingTable = (a, b) =>
    ascendance
      ? typeof a[sortValue] === "number"
        ? a[sortValue] - b[sortValue]
        : a[sortValue]?.localeCompare(b[sortValue])
      : typeof a[sortValue] === "number"
      ? b[sortValue] - a[sortValue]
      : b[sortValue]?.localeCompare(a[sortValue]);

  const { width } = useWindowDimensions();
  return (
    <View style={{ marginTop: c.spacing.spacing_md }}>
      <View style={{ flexDirection: "row" }}>
        {headers.map((h, i) => (
          <Pressable
            key={i}
            onPress={() => {
              h.action();
              setHeaderOption(i);
              setAscendance(!ascendance);
            }}
          >
            <View
              style={[
                styles.cell,
                {
                  backgroundColor: c.backgrounds.bgLayout,
                },
                i === 0 && {
                  borderTopLeftRadius: c.borderRadius.br_base,
                  borderLeftColor: c.bordersDividers.border,
                },
                i === headers.length - 1 && {
                  borderTopRightRadius: c.borderRadius.br_base,
                  borderRightColor: c.bordersDividers.border,
                },

                h.header === "Valor" && { width: 120 },
                h.header === "Descripción" && { width: 210 },
                h.header === "Código" && { width: 90 },
                (h.header === "Estado" || h.header === "") && { width: 135 },
              ]}
            >
              <Text style={{ color: c.textIcons.icon }}>{h.header}</Text>
              {i === headerOption && (
                <View style={{ marginLeft: c.spacing.spacing_xx_sm }}>
                  <Icon
                    name={ascendance ? "chevron-down" : "chevron-up"}
                    color={c.textIcons.icon}
                    size={16}
                  />
                </View>
              )}
            </View>
          </Pressable>
        ))}
      </View>
      <View style={{ maxHeight: 450 }}>
        <ScrollView>
          {transactions
            ?.sort((a, b) => sortingTable(a, b))
            ?.map((tx, i) => (
              <>
                <View key={i} style={{ flexDirection: "row" }}>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderLeftColor: c.bordersDividers.border,
                      },
                    ]}
                  >
                    <Text>{tx.created_at}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { backgroundColor: c.backgrounds.bgContainer, width: 90 },
                    ]}
                  >
                    <Text>{tx.external_reference}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 135,
                      },
                    ]}
                  >
                    <StatusBadge txStatus={tx.status} />
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 120,
                      },
                    ]}
                  >
                    <Text>{`${countrySymbol} ${currencyFormat.format(
                      tx.amount
                    )}`}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        width: 210,
                      },
                    ]}
                  >
                    <Text numberOfLines={1}>{tx.description}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      { backgroundColor: c.backgrounds.bgContainer },
                    ]}
                  >
                    <Text numberOfLines={1}>{tx.full_name}</Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        backgroundColor: c.backgrounds.bgContainer,
                        borderRightColor: c.bordersDividers.border,
                        width: 135,
                      },
                    ]}
                  >
                    <Pressable
                      onPress={() => activeModal(tx.external_reference)}
                    >
                      <View style={styles.moreButton}>
                        <Text
                          style={{
                            color: c.backgrounds.bgContainer,
                            fontWeight: c.fontWeight.fw_medium,
                          }}
                        >
                          Ver más
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                </View>
                {selected === tx.external_reference && (
                  <TransactionDetails
                    isDesktop
                    visible={showModal}
                    txValue={tx.amount}
                    serviceFee={tx.fee}
                    txDate={tx.created_at}
                    txTitle={tx.description}
                    key={tx.external_reference}
                    txCode={tx.external_reference}
                    txClientPhone={tx.payer_phone}
                    countryCurrency={countrySymbol}
                    currencyFormat={currencyFormat}
                    txClientName={tx.payer_full_name}
                    paymentMethod={tx.payment_method}
                    close={() => setShowModal(false)}
                    handleDownload={handleDownload}
                    txStatus={
                      tx.status?.toLowerCase() === "success"
                        ? "Exitosa"
                        : tx.status?.toLowerCase() === "pending"
                        ? "Pendiente"
                        : tx.status?.toLowerCase() === "declined"
                        ? "Rechazada"
                        : tx.status?.toLowerCase() === "scheduled"
                        ? "Programada"
                        : "Vencida"
                    }
                  />
                )}
              </>
            ))}
        </ScrollView>
      </View>
    </View>
  );
};

export default TableComponent;

const styles = StyleSheet.create({
  cell: {
    height: 40,
    width: 175,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    paddingVertical: c.spacing.spacing_md,
    borderTopColor: c.bordersDividers.border,
    paddingHorizontal: c.spacing.spacing_md,
    borderBottomColor: c.bordersDividers.border,
  },
  moreButton: {
    width: 95,
    paddingHorizontal: 17,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_xxx_sm,
    backgroundColor: c.desktopColors.desktopBase,
    textAlign: "center",
  },
});
