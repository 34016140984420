import { ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import { c } from "../../styles/constants";
import Icon from "react-native-vector-icons/Ionicons";
import { Pressable } from "react-native";
const Learn = () => {
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown(!dropdown);
  return (
    <ScrollView>
      <View style={{ padding: c.spacing.spacing_md }}>
        <Pressable onPress={toggleDropdown}>
          <View
            style={
              dropdown ? styles.openedCardContainer : styles.closedCardContainer
            }
          >
            <View style={styles.spacedRow}>
              <Text>¿Cómo puedo cobrarle a un cliente?</Text>
              <Icon
                name={dropdown ? "chevron-up" : "chevron-down"}
                size={24}
                color={c.textIcons.icon}
              />
            </View>
          </View>
        </Pressable>
        {dropdown && (
          <View style={styles.cardContent}>
            <Text>Para cobrarle a un cliente debes hacer estos pasos:</Text>
            <Text>1. Escribe COBRAR en el chat</Text>
            <Text>2. Dinos el valor que deseas cobrar</Text>
            <Text>3. Describe lo que vas a cobrar</Text>
            <Text>4. Coloca el número del cliente</Text>
            <Text>5. ¡Y listo!</Text>
            <View style={{ marginTop: c.spacing.spacing_big }}>
              <Text>Te lo explicamos en menos de un minuto</Text>
              <View style={styles.cardVideo}>
                <Icon name="logo-youtube" size={48} />
              </View>
            </View>
          </View>
        )}
      </View>
    </ScrollView>
  );
};

export default Learn;

const styles = StyleSheet.create({
  closedCardContainer: {
    borderWidth: 1,
    borderRadius: c.borderRadius.br_lg,
    borderColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_big,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.themeColors.primaryBg,
  },
  spacedRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  openedCardContainer: {
    borderWidth: 1,
    borderBottomColor: "transparent",
    borderTopRightRadius: c.borderRadius.br_lg,
    borderTopLeftRadius: c.borderRadius.br_lg,
    borderTopColor: c.bordersDividers.border,
    borderLeftColor: c.bordersDividers.border,
    borderRightColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_big,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.themeColors.primaryBg,
  },
  cardContent: {
    borderWidth: 1,
    borderTopColor: "transparent",
    borderBottomRightRadius: c.borderRadius.br_lg,
    borderBottomLeftRadius: c.borderRadius.br_lg,
    borderBottomColor: c.bordersDividers.border,
    borderLeftColor: c.bordersDividers.border,
    borderRightColor: c.bordersDividers.border,
    paddingVertical: c.spacing.spacing_big,
    paddingHorizontal: c.spacing.spacing_md,
    backgroundColor: c.backgrounds.bgContainer,
  },
  cardVideo: {
    marginTop: c.spacing.spacing_xx_sm,
    backgroundColor: c.backgrounds.bgLayout,
    borderRadius: c.borderRadius.br_lg,
    height: 150,
    alignItems: "center",
    justifyContent: "center",
  },
});
