import { StyleSheet, Text, View, Pressable } from "react-native";
import React, { useState } from "react";
import { c } from "../styles/constants";
import { txt } from "../styles/typestyles";
const SegmentedButtons = ({ arr, isSelected, setIsSelected }) => {
  return (
    <View style={styles.buttonsContainer}>
      {arr.map((item, i) => (
        <View
          key={item}
          style={[
            styles.button,
            isSelected === item && {
              backgroundColor: c.themeColors.primaryBase,
            },
            i === 0 && {
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
            },
            i === arr.length - 1 && {
              borderTopRightRadius: 20,
              borderBottomRightRadius: 20,
            },
          ]}
        >
          <Pressable onPress={() => setIsSelected(item)}>
            <Text style={txt.lg_strong}>{item}</Text>
          </Pressable>
        </View>
      ))}
    </View>
  );
};

export default SegmentedButtons;

const styles = StyleSheet.create({
  buttonsContainer: {
    borderWidth: 1,
    borderRadius: 20,
    flexDirection: "row",
    marginVertical: c.spacing.spacing_md,
    borderColor: c.bordersDividers.border,
    backgroundColor: c.backgrounds.bgContainer,
  },
  button: {
    width: "33.3%",
    alignItems: "center",
    paddingVertical: c.spacing.spacing_xxx_sm,
    // paddingHorizontal: c.spacing.spacing_sm,
  },
});
