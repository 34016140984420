import React from "react";
import Icon from "react-native-vector-icons/Ionicons";
import MCI from "react-native-vector-icons/MaterialCommunityIcons";
import {
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";

import Divider from "../Divider";
import { c } from "../../styles/constants";
import { txt } from "../../styles/typestyles";
const ScheduledDetails = ({
  close,
  txCode,
  txDate,
  visible,
  txValue,
  txTitle,
  setShowEdit,
  txLimitDate,
  txClientName,
  txClientPhone,
  currencyFormat,
  countryCurrency,
}) => {
  const { height } = useWindowDimensions();

  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={close}
    >
      <View style={[{ backgroundColor: "rgba(0,0,0,0.25)" }, { height }]}>
        <View style={[styles.desktopContainer, { height }]}>
          <Pressable onPress={close}>
            <View style={styles.backButton_icon}>
              <Icon
                name="arrow-back-circle-outline"
                size={16}
                color={c.textIcons.icon}
              />
              <Text style={[txt.sm_normal, { color: c.textIcons.description }]}>
                Regresar
              </Text>
            </View>
          </Pressable>
          <View style={{ alignItems: "center" }}>
            <Icon name="time" size={48} color={c.warning.warningBase} />
            <Text style={[txt.xl_strong, { marginTop: c.spacing.spacing_sm }]}>
              ¡Cobro programado!
            </Text>

            <Text
              style={[
                txt.sm_normal,
                {
                  color: c.textIcons.icon,
                  marginTop: c.spacing.spacing_sm,
                },
              ]}
            >
              {txDate}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: c.spacing.spacing_sm,
              }}
            >
              <Text> Fecha límite: </Text>
              <Text style={[txt.sm_normal, { color: c.textIcons.description }]}>
                {txLimitDate}
              </Text>
            </View>
          </View>
          <Divider />
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Valor:</Text>
            <Text style={txt.lg_normal}>
              {`${countryCurrency} ${currencyFormat.format(txValue)}`}
            </Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Descripción:</Text>
            <Text style={[txt.lg_normal, { textAlign: "right" }]}>
              {txTitle}
            </Text>
          </View>

          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Cliente:</Text>
            <Text style={[txt.lg_normal, { textAlign: "right" }]}>
              {txClientName}
            </Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Telefono del cliente:</Text>
            <Text style={txt.lg_normal}>{txClientPhone}</Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={txt.lg_strong}>Código de transacción:</Text>
            <Text style={txt.lg_normal}>{txCode}</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: c.spacing.spacing_md,
            }}
          >
            <View style={{ width: "47%" }}>
              <Pressable>
                <View style={styles.deleteButton}>
                  <Text style={styles.deleteButton_title}>Eliminar cobro</Text>
                </View>
              </Pressable>
            </View>
            <View style={{ width: "47%" }}>
              <Pressable
                onPress={() => {
                  close();
                  setShowEdit(true);
                }}
              >
                <View style={styles.mainButton}>
                  <Text style={{ color: c.backgrounds.bgContainer }}>
                    Modificar información
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
          <View style={styles.problemButtonContainer}>
            <MCI
              name="message-question-outline"
              size={14}
              color={c.textIcons.icon}
            />
            <Text
              style={[
                txt.sm_normal,
                {
                  color: c.textIcons.icon,
                  marginLeft: c.spacing.spacing_sm,
                },
              ]}
            >
              Reportar un problema
            </Text>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ScheduledDetails;

const styles = StyleSheet.create({
  desktopContainer: {
    maxWidth: 500,
    width: "100%",
    borderRadius: 12,
    alignSelf: "flex-end",
    paddingBottom: c.spacing.spacing_md,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.backgrounds.bgContainer,
  },
  backButton_icon: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: c.spacing.spacing_sm,
  },
  mainButton: {
    alignItems: "center",
    borderRadius: c.borderRadius.br_lg,
    marginBottom: c.spacing.spacing_big,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_big,
    backgroundColor: c.desktopColors.desktopBase,
  },
  summaryRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: c.spacing.spacing_md,
  },
  problemButtonContainer: {
    marginVertical: c.spacing.spacing_sm,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    width: 50,
    height: 3,
    alignSelf: "center",
    borderRadius: c.borderRadius.br_xs,
    marginVertical: c.spacing.spacing_sm,
    backgroundColor: c.textIcons.disabled,
  },
  deleteButton: {
    borderWidth: 1,
    alignItems: "center",
    borderColor: c.error.errorBorder,
    backgroundColor: c.error.errorBg,
    borderRadius: c.borderRadius.br_base,
    paddingVertical: c.spacing.spacing_xx_sm,
    paddingHorizontal: c.spacing.spacing_big,
  },
  deleteButton_title: {
    fontWeight: c.fontWeight.fw_medium,
    color: c.error.errorText,
  },
});
